import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Button from '@material-ui/core/Button';
import { getLang, getData, postData, useIsMountedRef, responseCode } from '../../utils/helpers';
import { getCampaignID, setCampaignDetail, setSmsScenariosLink } from "../../redux/actions/userActions";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { injectIntl } from 'react-intl'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty } from 'lodash'
import CampaignDetail from './CampaignDetail';

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: "rgba(0, 0, 0, 0.87);",
                backgroundColor: "#FFF",
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 70%',
    },
}));

function SMSListCampaigns({ intl: { formatMessage } }) {
    const classes = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [lastCount, setLastCount] = useState(0);
    const [searchURL, setSearchURL] = useState("");
    const [lastPageVisited, setLastPageVisited] = useState(false);
    const [date, setDate] = useState(new Date());

    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    // const [campaignDetail, setCampaignDetail] = useState(false);

    const campaignDetail = useSelector(state => state.user.campaignDetail);

    // const classes2 = useToolbarStyles();

    const lang = getLang();

    let emptyRows = 0;
    const count = 100;

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    // const EnhancedTableToolbar = (props) => {

    //     return (
    //         <Toolbar
    //             className={clsx(classes2.root, [classes2.highlight])}>
    //             <Typography variant="h6" id="tableTitle" component="div" className={classes2.title}>
    //                 <FormattedMessage id="LIST" /></Typography>
    //         </Toolbar>
    //     );
    // };

    useEffect(() => {
        (async () => {
            const resData = await getData('https://apishoptet.axima-sms.cz/sms/bulk/filter/list?count=' + count);
            // const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');
            if (isMountedRef.current) {
                if (!isEmpty(resData.data)) {
                    // setOrderStatusData(statusData.data.status);
                    // const mergedData = mergeById(resData.data.sms, statusData.data.status);
                    setData(resData.data.bulk);
                    emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
                    setTotalCount(resData.data.count);
                    setLastCount(count);
                    setSearchURL(`https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=&to=&start=&count=${count}`)
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [isErred, isSaved]);

    const getDataWithCount = async (start) => {

        const { startDateMoment, endDateMoment } = momentDates();
        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=${startDateMoment}&to=${endDateMoment}&start=${start}&count=${count}`);
        // const resData = await getData(`https://apishoptet.axima-sms.cz/sms/list?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&start=${start}&count=${count}`);
        if (!isEmpty(resData.data)) {
            setData(data.concat(resData.data.bulk));
        } else {
            console.error(resData.error.error);
        }
    }

    const handleChangePage = (event, newPage) => {
        console.log({ newPage })
        console.log({ page })
        const pageCount = count / rowsPerPage;
        const totalPageCount = Math.floor(totalCount / rowsPerPage);
        switch (true) {
            case event.currentTarget.getAttribute("aria-label") === "next page":
                if (newPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((newPage * rowsPerPage) + 1) / count) * count);
                    (async () => { await getDataWithCount(lastCount); setPage(newPage); })();
                } else {
                    setPage(newPage);
                }
                if ((newPage === totalPageCount) && !lastPageVisited) {
                    setLastPageVisited(true);
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "last page":
                let ceilPage = Math.ceil((page + 1) / pageCount) * pageCount;

                if (ceilPage > totalPageCount && !lastPageVisited) {
                    ceilPage = totalPageCount;
                    setLastPageVisited(true);
                }
                if (ceilPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((ceilPage * rowsPerPage) + 1) / count) * count);
                    (async () => {
                        await getDataWithCount(lastCount); if (ceilPage > totalPageCount) {
                            setPage(totalPageCount);
                        } else {
                            setPage(ceilPage);
                        }
                    })();
                } else {
                    if (ceilPage >= totalPageCount || lastPageVisited) {
                        setPage(totalPageCount);
                    } else {
                        setPage((lastCount - count) / rowsPerPage);
                    }
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "previous page" || event.currentTarget.getAttribute("aria-label") === "first page":
                setPage(newPage);
            default:
                break;
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeValue = (event) => {
        setSearchText(event.target.value);
    };

    const momentDates = () => {
        const format = "YYYY.MM.DD HH:mm:ss";
        let startDateMoment = "";
        let endDateMoment = "";
        if (startDate !== null) {
            startDateMoment = moment(startDate).startOf("day").format(format);
        }
        if (endDate !== null) {
            endDateMoment = moment(endDate).endOf("day").format(format);
        }
        return { startDateMoment, endDateMoment }
    }

    const handleClick = async () => {

        const { startDateMoment, endDateMoment } = momentDates();
        const url = `https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}`;
        // const url = `https://apishoptet.axima-sms.cz/sms/list?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&start=&count=${count}`;
        if (url !== searchURL) {
            const resData = await getData(url);
            // const mergedData = mergeById(resData.data.sms, orderStatusData);
            if (!isEmpty(resData.data)) {
                setTotalCount(resData.data.count);
                setData(resData.data.bulk);
                setLastCount(count);
                setSearchURL(url);
                setPage(0);
            } else {
                console.error(resData.error.error);
            }
        }
    }
    const downloadClick = async () => {

        const { startDateMoment, endDateMoment } = momentDates();
        const exportBulkData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/export?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}&lang=${lang}`, 'text');
        // const exportSMSData = await getData(`https://apishoptet.axima-sms.cz/sms/export?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&lang=${lang}`, 'text');
        // setExportData(exportSMSData);
        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(exportBulkData));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
        //document.body.removeChild(anchor);       
    }

    const handlePauseClick = async (id) => {
        let newArr = [...data];
        const foundIndex = newArr.findIndex(x => x.id === id);
        // newArr[foundIndex].suspended = !newArr[foundIndex].suspended;
        // data[foundIndex].suspended = !data[foundIndex].suspended;

        const postSuspendData = await postData('https://apishoptet.axima-sms.cz/sms/bulk/edit', {
            'id': id,
            'suspended': !newArr[foundIndex].suspended
        });

        console.log(!newArr[foundIndex].suspended);

        if (postSuspendData.error.error === "OK") {
            newArr[foundIndex].suspended = !newArr[foundIndex].suspended;
            console.log(newArr[foundIndex].suspended);
            setData(newArr);
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />);
        } else {
            setErred(true);
            setResponseMessage(<FormattedMessage id={postSuspendData.error.error} />)
        }

        // responseCode(postSuspendData, setResponseMessage, setErred);
        // if (postSuspendData.error.error === "OK") {
        //     // setData(
        //     //     [
        //     //         ...data,
        //     //         {
        //     //             suspended: data[foundIndex].suspended
        //     //         }
        //     //     ]
        //     // );
        //     setData(newArr);
        //     setIsSaved(true);
        //     setResponseMessage("saved");
        // }
    }

    const handleExportClick = async (id) => {
        console.log(id);
        const exportBulk = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/sms/export?id=${id}&lang=${lang}`, 'text');
        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(exportBulk));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
        //document.body.removeChild(anchor);       
    }

    const handleCampaignDetailClick = (e, id) => {
        dispatch(getCampaignID(id));
        dispatch(setCampaignDetail(true));
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }

    const Template = () => {
        let template;
        if (campaignDetail) {
            template = <CampaignDetail />
        } else {
            template = <div className="col-lg-12">
                <div className="row mt-10 mb-10">
                    {/* <div className="col-lg-3"><h2><FormattedMessage id="SMS_CAMPAIGNS_LIST" /></h2></div> */}
                    {/* <div className="col-lg-9 text-right"> */}
                    <div className="col-lg-12 text-right">
                        {/* <div className="filter-input-wrapper mr-15">
                    <FormattedMessage id="SEARCH_BUTTON" defaultMessage="Search">
                        {placeholder =>
                            <input placeholder={placeholder} type="text" value={searchText} onChange={handleChangeValue} className="filter-input" />
                        }
                    </FormattedMessage>
                </div> */}
                        <DateRangePicker
                            startDate={startDate} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={endDate} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate) }} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                            isOutsideRange={() => false}
                            showClearDates={true}
                            reopenPickerOnClearDates={true}
                            startDatePlaceholderText={formatMessage({ id: 'DATEPICKER_START_DATE' })}
                            endDatePlaceholderText={formatMessage({ id: 'DATEPICKER_END_DATE' })}

                        />
                        <Button onClick={handleClick} variant="contained" color="primary" className="ml-10">
                            <FormattedMessage id="DISPLAY_BUTTON" />
                        </Button>
                    </div>
                </div>
                <div className="col-lg-12 px-4 pt-4 pb-0 filtered-table">
                    {/* <EnhancedTableToolbar /> */}
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_NAME" /></TableCell>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_START" /></TableCell>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_NMBRS" /></TableCell>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_SENT" /></TableCell>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_DELIVERED" /></TableCell>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_TEXT" /></TableCell>
                                    <TableCell><FormattedMessage id="SMS_CAMPAIGNS_LIST_STATUS" /></TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            {data.length === 0 ?
                                <TableBody>
                                    <TableRow style={{ height: 80 }}>
                                        <TableCell colSpan={8} className="font-weight-bold"><FormattedMessage id="NOTHING_TO_SHOW" /></TableCell>
                                    </TableRow>
                                </TableBody> :
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : data
                                    ).map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell><a className="order-code-link" onClick={(e) => handleCampaignDetailClick(e, row.id)}>{row.caption}</a></TableCell>
                                            <TableCell className="table-date">{row.send_date}</TableCell>
                                            <TableCell>{row.count}</TableCell>
                                            <TableCell>{row.count_sent}</TableCell>
                                            <TableCell>{row.count_delivered}</TableCell>
                                            <TableCell style={{ width: 250 }}>{row.text}</TableCell>
                                            <TableCell>
                                                {(row.suspended && <div className="campaign-list-status d-flex"><span><FormattedMessage id="SMS_CAMPAIGNS_LIST_STATUS_SUSPENDED" /></span> <div className="red ml-5"></div></div>) ||
                                                    (moment(date).format("YYYY-MM-DD HH:mm:ss") < row.send_date && <div className="campaign-list-status d-flex"><span><FormattedMessage id="SMS_CAMPAIGNS_LIST_STATUS_SCHEDULED" /></span> <div className="gray ml-5"></div></div>) ||
                                                    (row.count === row.count_sent + row.count_error && <div className="campaign-list-status d-flex"><span><FormattedMessage id="SMS_CAMPAIGNS_LIST_SENT" /></span> <div className="green ml-5"></div></div>) ||
                                                    (row.count > row.count_sent + row.count_error && <div className="campaign-list-status d-flex"><span><FormattedMessage id="SMS_CAMPAIGNS_LIST_STATUS_SENDING" /></span> <div className="blue ml-5"></div></div>)}
                                            </TableCell>
                                            <TableCell style={{ width: 20 }}>
                                                <Dropdown className="sms-campaign-list-dropdown">
                                                    <DropdownButton
                                                        title=""
                                                        drop="left"
                                                        variant="secondary">
                                                        <Dropdown.Item eventKey=""
                                                            disabled={(row.suspended) || (row.count > row.count_sent + row.count_error) || (moment(date).format("YYYY.MM.DD HH:mm:ss") < row.send_date) ? false : true}
                                                            onClick={() => handlePauseClick(row.id)}>{row.suspended ? <FormattedMessage id="SMS_CAMPAIGNS_UNPAUSE_BUTTON" /> : <FormattedMessage id="SMS_CAMPAIGNS_PAUSE_BUTTON" />}</Dropdown.Item>
                                                        <Dropdown.Item eventKey="" onClick={() => handleExportClick(row.id)}><FormattedMessage id="SMS_CAMPAIGNS_EXPORT_BUTTON" /></Dropdown.Item>
                                                    </DropdownButton>
                                                </Dropdown>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            }
                            <TableFooter>
                                <TableRow>
                                    <TableCell className="download-export-wrapper">
                                        <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                                </Tooltip>
                                            }
                                        >
                                            <IconButton aria-label="download" onClick={downloadClick}>
                                                <GetAppIcon color="secondary" />
                                            </IconButton>
                                        </OverlayTrigger>
                                    </TableCell>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20]}
                                        colSpan={9}
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
                {
                    (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar my-5">
                            <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{responseMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        return template;
    }

    return (
        <div className="col-lg-12 px-0 mt-10">{Template()}</div>
    );
}
export default injectIntl(SMSListCampaigns);