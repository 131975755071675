import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import ResultOK from './PaymentResults/ResultOK';
import ResultFailed from './PaymentResults/ResultFailed';
import ResultReversed from './PaymentResults/ResultReversed';
import ResultAutoReversed from './PaymentResults/ResultAutoReversed';
import ResultTimeout from './PaymentResults/ResultTimeout';
import ResultPending from './PaymentResults/ResultPending';
import ResultReturnFailUrl from './PaymentResults/ResultReturnFailUrl';
import ResultPendingFIO from './PaymentResults/ResultPendingFIO';
import ResultCanceled from './PaymentResults/ResultCanceled';
import { isEmpty } from 'lodash'
import { getData, postData, useIsMountedRef } from '../../utils/helpers';
import { seenPayment, updateUnseenPayment } from '../../redux/actions/userActions';

function PaymentResult() {

    const user = useSelector(state => state.user.user);
    const unseenPayment = useSelector(state => state.user.unseenPayment);

    const [unseenPaymentLast, setUnseenPaymentLast] = useState({});
    const [allPaymentsID, setAllPaymentsID] = useState([]);
    const [init, setInit] = useState(false);
    let newArr = [];

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isMountedRef.current) {
            if (!isEmpty(user)) {
                // setUnseenPaymentLast(user.unseen_payments[user.unseen_payments.length - 1]);
                setUnseenPaymentLast(unseenPayment[unseenPayment.length - 1]);
                dispatch(updateUnseenPayment(unseenPayment[unseenPayment.length - 1]));
                // console.log(user.unseen_payments);
                user.unseen_payments.map((item) => (
                    newArr.push(item.id),
                    // console.log({ newArr }),
                    setAllPaymentsID(newArr)
                ));
            }
            setInit(true);
        }
    }, [user]);

    useEffect(() => {
        (async () => {
            if (isMountedRef.current) {
                if (!isEmpty(user)) {
                    if (init) {
                        console.log({ allPaymentsID });
                        console.log({ init });

                        const paymentSeenData = await postData('https://apishoptet.axima-sms.cz/eshop/payment/mark/seen', {
                            "id": allPaymentsID
                        });
                        console.log({ paymentSeenData });

                        if (paymentSeenData.error.error === "OK") {
                            dispatch(seenPayment(true));
                        }
                    }
                }
            }
        })();
    }, [init]);


    const Template = () => {
        let template;
        if (!isEmpty(user) && unseenPaymentLast.result === "ok") {
            template = <ResultOK />
        } else if ((!isEmpty(user) && unseenPaymentLast.result === "error") || (!isEmpty(user) && unseenPaymentLast.result === "unknown")) {
            template = <ResultReturnFailUrl />
        } else if ((!isEmpty(user) && unseenPaymentLast.result === "failed") || (!isEmpty(user) && unseenPaymentLast.result === "declined")) {
            template = <ResultFailed />
        } else if (!isEmpty(user) && unseenPaymentLast.result === "reversed") {
            template = <ResultReversed />
        } else if (!isEmpty(user) && unseenPaymentLast.result === "autoreversed") {
            template = <ResultAutoReversed />
        } else if (!isEmpty(user) && unseenPaymentLast.result === "timeout") {
            template = <ResultTimeout />
        } else if (!isEmpty(user) && unseenPaymentLast.result === "pending_fio") {
            template = <ResultPendingFIO />
        } else if (!isEmpty(user) && unseenPaymentLast.result === "pending") {
            template = <ResultPending />
        } else if (!isEmpty(user) && unseenPaymentLast.result === "cancelled") {
            template = <ResultCanceled />
        }
        // console.log(unseenPaymentLast.result);
        // console.log({ unseenPaymentLast })
        return template;
    }

    return (
        <div className="col-lg-12 px-0 mt-15">{Template()}</div>
        // <div className="col-lg-12 px-0 mt-15">
        //     {/* <ResultAutoReversed /> */}
        //     {/* <ResultCanceled /> */}
        //     {/* <ResultFailed /> */}
        //     {/* <ResultOK /> */}
        //     {/* <ResultPending /> */}
        //     {/* <ResultPendingFIO /> */}
        //     {/* <ResultReturnFailUrl /> */}
        //     {/* <ResultReversed /> */}
        //     {/* <ResultTimeout /> */}
        // </div>
    );
}
export default PaymentResult;