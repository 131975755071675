/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import EmailActivation from './EmailActivation';
import { getLang, getData, postData, responseCode, useIsMountedRef } from '../../utils/helpers';
import validator from 'validator';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import 'react-phone-number-input/style.css'
import { isEmpty } from 'lodash'
import { FormattedMessage } from "react-intl";
import ErrorIcon from '@material-ui/icons/Error';
import { updateRegistrationData } from '../../redux/actions/userActions';

function Registration() {

    const [isLoading, setLoading] = useState(false);
    const [isErred, setErred] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const [emailMessage, setEmailMessage] = useState('');
    const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
    const [checkboxMessage, setCheckboxMessage] = useState('');
    const [isEmailError, setEmailError] = useState(false);
    const [isPhoneNumberError, setPhoneNumberError] = useState(false);
    const [isCheckboxError, setCheckboxError] = useState(false);
    const [euVatMessage, setEuVatMessage] = useState('');
    const [isEuVatError, setEuVatError] = useState(false);
    const [vatInfo, setVatInfo] = useState(null);
    const [euVatDisabled, setEuVatDisabled] = useState(true);
    const [vatId, setVatId] = useState('');

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isChecked, setIsChecked] = useState({
        checkedA: false,
        checkedB: false,
    });
    const emailRef = createRef();
    const phoneNumberRef = createRef();

    const userIsLoading = useSelector(state => state.user.isLoading);

    const user = useSelector(s => s.user.user);

    // const user = {}
    const lang = getLang();

    const dispatch = useDispatch();

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (isMountedRef.current) {
            if (isCheckboxError) {
                if (isChecked.checkedA && isChecked.checkedB) {
                    setCheckboxError(false);
                }
            }
        }
    }, [isCheckboxError, isChecked.checkedA, isChecked.checkedB, isMountedRef]);

    useEffect(() => {
        (async () => {
            if(!isEmpty(user.billing)){
                
                // setVatId(user.billing.vatId);
                
                const resData = await getData(`https://apishoptet.axima-sms.cz/vatno?vatno=${user.billing.vatId}&country=${user.billing.country.countryCode}`);

                if (!isEmpty(resData.data)){
                    setVatInfo(resData.data);
                    // setVatId(resData.data.vatno);
                    // console.log({resData});
                } else {
                    setVatInfo(null);
                    // console.log({resData});
                    setEuVatError(true);
                    setEuVatMessage(<FormattedMessage id="SNACKBAR_VAT_NUMBER_INVALID" />);
                    setEuVatDisabled(false);
                }
            }
        })();
    }, [user.billing]);

    useEffect(() => {
        if (vatId !== "") {
            let timeOut = setTimeout(() => {
                checkEuVat();
            }, 2000);
            return () => clearTimeout(timeOut);
        }
    }, [vatId])

    const handleClick = async () => {

        let error = false;

        if (emailRef.current.value === '') {
            setEmailError(true);
            setEmailMessage(<FormattedMessage id="SNACKBAR_NO_EMAIL" />)
            error = true;
        }

        // const phoneLib = parsePhoneNumberFromString(phoneNumberRef.current.value);
        // console.log({ phoneLib });
        // console.log({ phoneNumber });
        // console.log(phoneNumberRef.current.value);
        // const phoneValue = phoneNumberRef.current.value.length;
        // console.log({ phoneValue });
        // console.log(isPossiblePhoneNumber(phoneNumberRef.current.value));
        // console.log(isValidPhoneNumber(phoneNumberRef.current.value));

        if (!isPossiblePhoneNumber(phoneNumberRef.current.value) || !isValidPhoneNumber(phoneNumberRef.current.value)) {
            setPhoneNumberError(true);
            setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />)
            error = true;
        } else {
            setPhoneNumberError(false);
        }

        // if (phoneNumberRef.current.value === '+420') {
        //     setPhoneNumberError(true);
        //     setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
        //     error = true;
        // } else {
        //     if (phoneNumberRef.current.value.length < 16 && phoneNumberRef.current.value.length > 4) {
        //         setPhoneNumberError(true);
        //         setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
        //         error = true;
        //     }
        // }

        if (!(isChecked.checkedA && isChecked.checkedB)) {
            setCheckboxError(true);
            setCheckboxMessage(<FormattedMessage id="SNACKBAR_NO_CHECKBOXES" />);
            error = true;
        } else {
            setCheckboxError(false);
        }

        if(vatInfo === null){
            setEuVatError(true);
            setEuVatMessage(<FormattedMessage id="SNACKBAR_VAT_NUMBER_INVALID" />);
            error = true;
            setEuVatDisabled(false);
        } else {
            setEuVatError(false);
        }

        if (error) {
            return;
        }

        setErred(false);
        setLoading(true);

        const [emailData, phoneData, euVatData] = await Promise.all([
            postData('https://apishoptet.axima-sms.cz/eshop/email', {
                'email': email,
                'lang': lang
            }),
            postData('https://apishoptet.axima-sms.cz/eshop/phone', {
                'phone': phoneNumber,
                'lang': lang
            }),
            postData('https://apishoptet.axima-sms.cz/eshop/vatno', {
                'country': user.billing.country.countryCode,
                'vatno': vatId
            })
        ]);

        setLoading(false);

        // if (responseCode(emailData.error.error !== "OK")) {
        //     setEmailError(true);
        //     setEmailMessage(<FormattedMessage id={emailData.error.error} />)
        // } 
        // if (phoneData.error.error !== "OK") {
        //     setPhoneNumberError(true);
        //     setPhoneNumberMessage(<FormattedMessage id={phoneData.error.error} />)
        // } 
        // if (emailData.error.error === "OK" && phoneData.error.error === "OK") {
        //     dispatch(updateRegistrationData(email, phoneNumber));
        // }

        responseCode(emailData, setEmailMessage, setEmailError);
        responseCode(phoneData, setPhoneNumberMessage, setPhoneNumberError);
        responseCode(euVatData, setEuVatMessage, setEuVatError);

        if (emailData.error.error === "OK" && phoneData.error.error === "OK") {
            dispatch(updateRegistrationData(email, phoneNumber));
            const data = await postData('https://apishoptet.axima-sms.cz/eshop/email/resend', {
                'lang': lang
            });

            responseCode(data, setResponseMessage, setErred);
        }
    }

    // const _setErr = function(/*string*/ aError) {
    //     setEmailError(true);
    //     setEmailMessage(<FormattedMessage id={aError} />)        
    // }

    const handleEmail = (event) => {
        if (!validator.isEmail(event.target.value)) {
            setEmailError(true);
            setEmailMessage(<FormattedMessage id="SNACKBAR_EMAIL_WRONG" />)
            // _setErr(SNACKBAR_EMAIL_WRONG)
        } else {
            setEmailError(false);
        }
        if (event.target.value === '') {
            setEmailError(true);
            setEmailMessage(<FormattedMessage id="SNACKBAR_NO_EMAIL" />)
        }
        setEmail(event.target.value);
    }

    const handlePhone = (phone) => {
        if (phoneNumber !== phone) setPhoneNumber(phone);

        if (phone === '' || phone === undefined) {
            setPhoneNumberError(true);
            setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
            return;
        }
        // else {
        //     if (phone.length < 13 && phone.length > 4) {
        //         setPhoneNumberError(true);
        //         setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
        //         return;
        //     }
        // }

        const phoneLib = parsePhoneNumberFromString(phone);

        if (phoneLib) {
            if (!phoneLib.isPossible() || !phoneLib.isValid()) {
                setPhoneNumberError(true);
                setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />)
            } else {
                setPhoneNumberError(false);
            }
        }
    }

    const handleEuVat = (event) => {
        // console.log(event.target.value);
        setVatId(event.target.value);

    }

    const checkEuVat = async () => {
        const resData = await getData(`https://apishoptet.axima-sms.cz/vatno?vatno=${vatId}&country=${user.billing.country.countryCode}`);
        if (!isEmpty(resData.data)){
            setVatInfo(resData.data);
            setVatId(resData.data.vatno);
            setEuVatError(false);
            // console.log({resData});
        } else {
            setVatInfo(null);
            // console.log({resData});
            setEuVatError(true);
            setEuVatMessage(<FormattedMessage id="SNACKBAR_VAT_NUMBER_INVALID" />);
            setEuVatDisabled(false);
        }
    }

    const handleCheck = (event) => {
        setIsChecked({ ...isChecked, [event.target.name]: event.target.checked });
    };

    const handleSnackbarClose = () => {
        setErred(false);
    }

    const handleSnackbarCloseEmailError = () => {
        setEmailError(false);
    }

    const handleSnackbarClosePhoneNumberError = () => {
        setPhoneNumberError(false);
    }

    const handleSnackbarCloseChecboxError = () => {
        setCheckboxError(false);
    }

    const handleSnackbarCloseEuVatError = () => {
        setEuVatError(false);
    }

    const Template = () => {
        let template;

        if (userIsLoading) {
            template = <div className="col-lg-12 px-0">
                <div className="mt-15 mb-25">
                    <strong><FormattedMessage id="USERINFO_LOADING" /></strong>
                </div>
            </div>;
        } else {
            if (isEmpty(user)) {
                window.location.reload();
                template = <div className="col-lg-12 px-0">
                    <div className="mt-15 mb-25">
                        <div><strong><FormattedMessage id="USERINFO_NOTLOADED" /></strong></div>
                        <div>
                            <Button onClick={() => window.location.reload()} variant="contained" color="primary" className="mt-10">
                                <FormattedMessage id="RELOAD_PAGE_BUTTON" />
                            </Button>
                        </div>
                    </div>
                </div>;
            } else if (!isEmpty(user) && user.email !== "" && user.phone !== "") {
                template = <EmailActivation />;
            } else {
                template = <div className="col-lg-12 px-0">
                    <div className="registrate-breadcrumb">
                        <div className="registrate active"><FormattedMessage id="BREADCRUMBS_REGISTRATION" /></div>
                        <div className="registrate email-verification"><FormattedMessage id="BREADCRUMBS_EMAIL_VERIFICATION" /></div>
                        <div className="sms-verification"><FormattedMessage id="BREADCRUMBS_SMS_VERIFICATION" /></div>
                    </div>
                    <div className="mt-10 mb-10">
                        <FormattedMessage id="REGISTRATION_SENTENCE" /> <span className="font-weight-bold">ProfiSMS s.r.o.</span>
                    </div>
                    <div className="info-area mb-10">
                        <h3><FormattedMessage id="USER_AUTHENTICATED" /></h3>
                        <p><FormattedMessage id="USER_AUTHENTICATED_DATA_LOADED" /></p>
                    </div>
                    <Form className="row registration-form" onSubmit={e => { e.preventDefault(); }}>
                        <div className="col-lg-6">
                            <Form.Row>
                                <Form.Group controlId="formGridCompanyName" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="COMPANY_NAME" /></Form.Label>
                                    <Form.Control placeholder={user.billing.billingName} disabled />
                                </Form.Group>
                                <Form.Group controlId="formGridCompanyID" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="COMPANY_ID" /></Form.Label>
                                    <Form.Control placeholder={user.billing.companyId} disabled />
                                </Form.Group>

                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="formGridStreet" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="STREET" /></Form.Label>
                                    <Form.Control placeholder={user.billing.address.streetAddress} disabled />
                                </Form.Group>

                                <Form.Group controlId="formGridCity" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="CITY" /></Form.Label>
                                    <Form.Control placeholder={user.billing.address.city} disabled />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="formGridZipCode" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="ZIP_CODE" /></Form.Label>
                                    <Form.Control placeholder={user.billing.address.zip} disabled />
                                </Form.Group>
                                <Form.Group controlId="formGridCountry" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="COUNTRY" /></Form.Label>
                                    <Form.Control as="select" disabled>
                                        <option>{user.billing.country.countryName}</option>
                                        <option>...</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group controlId="formBasicChecbox" className="mt-5">
                                <FormCheck>
                                    <FormCheck.Input type="checkbox" checked={isChecked.checkedA} onChange={handleCheck} name="checkedA" />
                                    <FormCheck.Label className="ml-2"><FormattedMessage id="CHECKBOX_GDPR" />
                                        {
                                            (user.billing.country.countryCode === "CZ" &&
                                                <a href="https://www.axima-sms.cz/shoptet/memorandum.pdf" target="_blank"> <FormattedMessage id="CHECKBOX_GDPR2" /></a>) ||
                                            (user.billing.country.countryCode === "HU" &&
                                                <a href="https://www.axima-sms.cz/shoptet-hu/memorandum-hu.pdf" target="_blank"> <FormattedMessage id="CHECKBOX_GDPR2" /></a>) ||
                                            (user.billing.country.countryCode === "SK" &&
                                                <a href="https://www.axima-sms.cz/shoptet-sk/memorandum-sk.pdf" target="_blank"> <FormattedMessage id="CHECKBOX_GDPR2" /></a>)
                                        }

                                    </FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                            <Form.Group controlId="formBasicChecbox2">
                                <FormCheck>
                                    <FormCheck.Input type="checkbox" checked={isChecked.checkedB} onChange={handleCheck} name="checkedB" />
                                    <FormCheck.Label className="ml-2"><FormattedMessage id="CHECKBOX_TERMS" />
                                        {
                                            (user.billing.country.countryCode === "CZ" &&
                                                <a href="https://www.axima-sms.cz/shoptet/vop_shoptet.pdf" target="_blank"> <FormattedMessage id="CHECKBOX_TERMS2" /></a>) ||
                                            (user.billing.country.countryCode === "HU" &&
                                                <a href="https://www.axima-sms.cz/shoptet-hu/vop_shoptet-hu.pdf" target="_blank"> <FormattedMessage id="CHECKBOX_TERMS2" /></a>) ||
                                            (user.billing.country.countryCode === "SK" &&
                                                <a href="https://www.axima-sms.cz/shoptet-sk/vop_shoptet-sk.pdf" target="_blank"> <FormattedMessage id="CHECKBOX_TERMS2" /></a>)
                                        }
                                    </FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Row>
                                <Form.Group controlId="formGridVatNumber" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="COMPANY_VAT_ID" /></Form.Label>
                                    <Form.Control placeholder={user.billing.vatId} disabled={euVatDisabled} value={vatId === "" ? user.billing.vatId : vatId} onChange={(e) => handleEuVat(e)} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="form-email-phone px-5 py-8">
                                <p><FormattedMessage id="CONTACT_DETAIL_NOTE" /></p>
                                <Form.Group controlId="formGridEmail" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="EMAIL" /></Form.Label>
                                    <Form.Control ref={emailRef} className={isEmailError ? 'error' : ''} type="email" placeholder="E-MAIL" value={email} onChange={(e) => handleEmail(e)} />
                                </Form.Group>
                                <Form.Group controlId="formGridPhone" className="col-lg-6">
                                    <Form.Label><FormattedMessage id="MOBILE_NUMBER" /></Form.Label>
                                    <PhoneInput
                                        ref={phoneNumberRef}
                                        // defaultCountry="CZ"
                                        // country="CZ"
                                        defaultCountry={user.billing.country.countryCode}
                                        country={user.billing.country.countryCode}
                                        international
                                        value={phoneNumber}
                                        onChange={handlePhone}
                                        className={isPhoneNumberError ? 'error' : ''}
                                        limitMaxLength={true} />
                                </Form.Group>

                            </Form.Row>
                            <Button onClick={() => handleClick()} variant="contained" color="primary" className="float-right mt-5">
                                <FormattedMessage id="BUTTON_REGISTER" />
                            </Button>
                        </div>
                    </Form >
                    {

                        isErred && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar my-5">
                                <div className="snackbar-content">
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{responseMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isEmailError && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar">
                                <div className="snackbar-content">
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{emailMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarCloseEmailError}></a>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isPhoneNumberError && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar">
                                <div className="snackbar-content">
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{phoneNumberMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarClosePhoneNumberError}></a>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isCheckboxError && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar">
                                <div className="snackbar-content">
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{checkboxMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarCloseChecboxError}></a>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isEuVatError && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar">
                                <div className="snackbar-content">
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{euVatMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarCloseEuVatError}></a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        }

        return template;
    }
    return (
        <div>{Template()}</div>
    );
}

export default Registration;
