import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Button from '@material-ui/core/Button';
import SendSmsCampaignsFilter from './SendSmsCampaignsFilter';
import { postData } from '../../utils/helpers';

function SendSmsCampaigns() {

    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.user.currency);

    // const user = {
    //     order_downloaded: false,
    //     attribute: {
    //         bulk_allowed: false
    //     }
    // }

    const [isDownloaded, setIsDownloaded] = useState(false);
    const [isDownloadingError, setIsDownloadingError] = useState(false);

    useEffect(() => {
        if (user.attribute.bulk_allowed) {
            setIsDownloaded(true);
        }
    }, []);

    const handleClick = async () => {
        if (user.order_downloaded) {
            const data = await postData('https://apishoptet.axima-sms.cz/eshop/attribute', {
                "bulk_allowed": true
            });
            setIsDownloaded(true);
        } else {
            setIsDownloadingError(true);
        }
    }

    return (
        <div className="col-lg-12 px-0">
            { (isDownloaded || user.attribute.bulk_allowed) ? <SendSmsCampaignsFilter /> :
                <>
                    <div className="mt-15 font-weight-bold">
                        <p><FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO1" /> <br /><br />
                            {(currency === "CZK") ?
                                <>
                                    <FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO2_CZ_ONLY" />
                                    <a href="https://www.uoou.cz/assets/File.ashx?id_org=200144&id_dokumenty=32710" target="_blank"> <FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO2_CZ_ONLY2" /> </a>
                                    <i>"<FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO2_CZ_ONLY3" />"</i> </>
                                : <FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO2" />
                            }
                        </p>
                        <p className="my-10"><FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO3" /></p>
                        <p><FormattedMessage id="SMS_CAMPAIGNS_GENERAL_INFO4" /></p>
                        <div className="row m-0">
                            <Button onClick={handleClick} variant="contained" color="primary" className="mt-10 mr-10">
                                <FormattedMessage id="SMS_CAMPAIGNS_AGREE_BUTTON" />
                            </Button> {isDownloadingError ? <p className="font-weight-bold text-danger d-flex align-items-end"><FormattedMessage id="SMS_CAMPAIGNS_DOWNLOAD_UNCOMPLETED" /></p> : null}
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
export default SendSmsCampaigns;