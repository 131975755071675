import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Button from '@material-ui/core/Button';
import { getLang, getData, useIsMountedRef, mergeById } from '../../utils/helpers';
import { getOrderCode, setSmsScenariosLink } from "../../redux/actions/userActions";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: "rgba(0, 0, 0, 0.87);",
                backgroundColor: "#FFF",
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 70%',
    },
}));

function AutomatedSms({ intl: { formatMessage } }) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [lastCount, setLastCount] = React.useState(0);
    const [orderStatusData, setOrderStatusData] = React.useState([]);
    const [searchURL, setSearchURL] = React.useState("");
    const [lastPageVisited, setLastPageVisited] = React.useState(false);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [init, setInit] = React.useState(false);

    // const classes2 = useToolbarStyles();
    const lang = getLang();

    let emptyRows = 0;
    const count = 100;

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    // const EnhancedTableToolbar = (props) => {

    //     return (
    //         <Toolbar
    //             className={clsx(classes2.root, [classes2.highlight])}>
    //             <Typography variant="h6" id="tableTitle" component="div" className={classes2.title}>
    //                 <FormattedMessage id="LIST" /></Typography>
    //         </Toolbar>
    //     );
    // };

    useEffect(() => {
        setInit(true);
    }, [init]);

    useEffect(() => {
        if (init) {
            getSmsListData();
        }
    }, [init]);

    useEffect(() => {
        if (!isFiltered) {
            const intervalCounter = setInterval(() => {
                getSmsListData();
                console.log("interval useeffect sms list data");
            }, 100000);
            return () => clearInterval(intervalCounter);
        }
    }, [isFiltered]);

    const getSmsListData = async () => {
        const resData = await getData('https://apishoptet.axima-sms.cz/sms/list?count=' + count);
        const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');
        if (isMountedRef.current) {
            if (!isEmpty(statusData.data) && !isEmpty(resData.data)) {
                setOrderStatusData(statusData.data.status);
                const mergedData = mergeById(resData.data.sms, statusData.data.status);
                setData(mergedData);
                console.log({ mergedData })
                emptyRows = rowsPerPage - Math.min(rowsPerPage, mergedData.length - page * rowsPerPage);
                setTotalCount(resData.data.count);
                setLastCount(count);
                setSearchURL(`https://apishoptet.axima-sms.cz/sms/list?from=&to=&order_code=&msisdn=&search=&start=&count=${count}`)
            } else {
                console.error(resData.error.error);
            }
        }
    }



    const getDataWithCount = async (start) => {

        const { startDateMoment, endDateMoment } = momentDates();

        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/list?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&start=${start}&count=${count}`);
        if (!isEmpty(resData.data.sms)) {
            const mergedData = mergeById(resData.data.sms, orderStatusData);
            setData(data.concat(mergedData));
        } else {
            console.error(resData.error.error);
        }
    }

    const handleChangePage = (event, newPage) => {
        console.log({ newPage })
        console.log({ page })
        const pageCount = count / rowsPerPage;
        const totalPageCount = Math.floor(totalCount / rowsPerPage);
        switch (true) {
            case event.currentTarget.getAttribute("aria-label") === "next page":
                if (newPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((newPage * rowsPerPage) + 1) / count) * count);
                    (async () => { await getDataWithCount(lastCount); setPage(newPage); })();
                } else {
                    setPage(newPage);
                }
                if ((newPage === totalPageCount) && !lastPageVisited) {
                    setLastPageVisited(true);
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "last page":
                let ceilPage = Math.ceil((page + 1) / pageCount) * pageCount;

                if (ceilPage > totalPageCount && !lastPageVisited) {
                    ceilPage = totalPageCount;
                    setLastPageVisited(true);
                }
                if (ceilPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((ceilPage * rowsPerPage) + 1) / count) * count);
                    (async () => {
                        await getDataWithCount(lastCount); if (ceilPage > totalPageCount) {
                            setPage(totalPageCount);
                        } else {
                            setPage(ceilPage);
                        }
                    })();
                } else {
                    if (ceilPage >= totalPageCount || lastPageVisited) {
                        setPage(totalPageCount);
                    } else {
                        setPage((lastCount - count) / rowsPerPage);
                    }
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "previous page" || event.currentTarget.getAttribute("aria-label") === "first page":
                setPage(newPage);
            default:
                break;
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeValue = (event) => {
        setSearchText(event.target.value);
    };

    const momentDates = () => {
        const format = "YYYY.MM.DD HH:mm:ss";
        let startDateMoment = "";
        let endDateMoment = "";
        if (startDate !== null) {
            startDateMoment = moment(startDate).startOf("day").format(format);
        }
        if (endDate !== null) {
            endDateMoment = moment(endDate).endOf("day").format(format);
        }
        return { startDateMoment, endDateMoment }
    }

    const handleClick = async () => {

        const { startDateMoment, endDateMoment } = momentDates();

        if (searchText !== "" || startDateMoment !== "" || endDateMoment !== "") {
            setIsFiltered(true);
            // console.log("filtered")
        } else {
            setIsFiltered(false);
            // console.log("not filtered")
        }

        const url = `https://apishoptet.axima-sms.cz/sms/list?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&start=&count=${count}`;
        if (url !== searchURL) {
            const resData = await getData(url);
            if (!isEmpty(resData.data)) {
                const mergedData = mergeById(resData.data.sms, orderStatusData);
                setTotalCount(resData.data.count);
                setData(mergedData);
                setLastCount(count);
                setSearchURL(url);
                setPage(0);
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const handleKeyDown = async (event) => {
        // if (event.key === 'Enter') {
        if (event.keyCode === 13) {
            const { startDateMoment, endDateMoment } = momentDates();

            const url = `https://apishoptet.axima-sms.cz/sms/list?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&start=&count=${count}`;
            if (url !== searchURL) {
                const resData = await getData(url);
                if (!isEmpty(resData.data)) {
                    const mergedData = mergeById(resData.data.sms, orderStatusData);
                    setTotalCount(resData.data.count);
                    setData(mergedData);
                    setLastCount(count);
                    setSearchURL(url);
                    setPage(0);
                } else {
                    console.error(resData.error.error);
                }
            }
        }
    }

    const downloadClick = async () => {

        const { startDateMoment, endDateMoment } = momentDates();

        const exportSMSData = await getData(`https://apishoptet.axima-sms.cz/sms/export?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&lang=${lang}`, 'text');
        // setExportData(exportSMSData);
        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(exportSMSData));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
        //document.body.removeChild(anchor);       
    }

    const switchIcons = (row) => {
        switch (true) {
            case row.state === "inserted" || row.state === "queued" || row.state === "sending":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_PENDING" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-pending-icon"></div>
                    </OverlayTrigger>);
            case row.state === "error":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_ERROR" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-error-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && row.delivery_state === "nostate":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_SENT" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-sent-nostate-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && row.delivery_state === "delivered":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_DELIVERED" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-delivered-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && (row.delivery_state === "expired" || row.delivery_state === "deleted"
                || row.delivery_state === "undeliverable" || row.delivery_state === "accepted" || row.delivery_state === "unknown"
                || row.delivery_state === "rejected" || row.delivery_state === "toerror" || row.delivery_state === "fromerror"):
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_UNDELIVERED" />
                            </Tooltip>}><div className="sms-status-icon sms-undeliverable-icon"></div>
                    </OverlayTrigger>);
            default:
                break;
        }
    }


    return (
        <div className="col-lg-12 px-0 mt-10">
            <div className="row mt-10 mb-10">
                {/* <div className="col-lg-2"><h2><FormattedMessage id="SMS_OVERVIEW" /></h2></div> */}
                {/* <div className="col-lg-10 text-right"> */}
                <div className="col-lg-12 text-right">
                    <div className="filter-input-wrapper mr-15">
                        <FormattedMessage id="SEARCH_BUTTON" defaultMessage="Search">
                            {placeholder =>
                                <input placeholder={placeholder} type="text" value={searchText} onChange={handleChangeValue} onKeyDown={handleKeyDown} className="filter-input" />
                            }
                        </FormattedMessage>
                        {/* <input type="text" value={value} onChange={handleChangeValue} className="filter-input" placeholder={<FormattedMessage id="SEARCH_BUTTON" />} /> */}
                    </div>
                    <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate) }} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                        isOutsideRange={() => false}
                        showClearDates={true}
                        reopenPickerOnClearDates={true}
                        startDatePlaceholderText={formatMessage({ id: 'DATEPICKER_START_DATE' })}
                        endDatePlaceholderText={formatMessage({ id: 'DATEPICKER_END_DATE' })}

                    />
                    <Button onClick={handleClick} variant="contained" color="primary" className="ml-10">
                        <FormattedMessage id="DISPLAY_BUTTON" />
                    </Button>
                </div>
            </div>
            <div className="col-lg-12 px-4 pt-4 pb-0 filtered-table">
                {/* <EnhancedTableToolbar /> */}
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell><FormattedMessage id="ORDER_NUMBER" /></TableCell>
                                <TableCell><FormattedMessage id="SENT_WHEN_STATUS_CHANGES" /></TableCell>
                                <TableCell><FormattedMessage id="DATE_OF_INCLUSION" /></TableCell>
                                <TableCell><FormattedMessage id="DELIVERY_DATE" /></TableCell>
                                <TableCell><FormattedMessage id="DESTINATION" /></TableCell>
                                <TableCell><FormattedMessage id="AMOUNT_WITHOUT_VAT" /></TableCell>
                                <TableCell><FormattedMessage id="TEXT" /></TableCell>
                                <TableCell><FormattedMessage id="NUMBER_OF_SMS" /></TableCell>
                                <TableCell><FormattedMessage id="STATE2" /></TableCell>
                            </TableRow>
                        </TableHead>
                        {data.length === 0 ?
                            <TableBody>
                                <TableRow style={{ height: 80 }}>
                                    <TableCell colSpan={9} className="font-weight-bold"><FormattedMessage id="NOTHING_TO_SHOW" /></TableCell>
                                </TableRow>
                            </TableBody> :
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : data
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.order_code !== null ? <a className="order-code-link" onClick={() => dispatch(getOrderCode(row.order_code))}>{row.order_code}</a> : <FormattedMessage id="SIMPLE_SMS_LIST_WHEN_NA" />}</TableCell>
                                        <TableCell style={{ width: 170 }}><span className="status" style={{ background: row.orderStatus ? row.orderStatus.backgroundColor : null, color: row.orderStatus ? row.orderStatus.color : null }}>{row.orderStatus ? row.orderStatus.name : null}</span></TableCell>
                                        <TableCell className="table-date">{row.created}</TableCell>
                                        <TableCell className="table-date">{row.send_date}</TableCell>
                                        <TableCell>{row.to}</TableCell>
                                        <TableCell style={{ width: 150 }}>{row.price !== null ? (row.price.toFixed(2)).toString().replace('-', '') + " " + row.currency : null}</TableCell>
                                        <TableCell style={{ width: 250 }}>{row.text}</TableCell>
                                        <TableCell>{row.parts_count}</TableCell>
                                        <TableCell>
                                            {switchIcons(row)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={9} />
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                        <TableFooter>
                            <TableRow>
                                <TableCell className="download-export-wrapper">
                                    <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                            </Tooltip>
                                        }
                                    >
                                        <IconButton aria-label="download" onClick={downloadClick}>
                                            <GetAppIcon color="secondary" />
                                        </IconButton>
                                    </OverlayTrigger>
                                </TableCell>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    colSpan={9}
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
            {data.length === 0 ?
                <div className="col-lg-12 px-0 mt-5">
                    <p className="font-weight-bold"><FormattedMessage id="SMS_LIST_INFO" /> <a className="blue-link" onClick={() => dispatch(setSmsScenariosLink(true))}><FormattedMessage id="SUBMENU_SMS_SETTINGS_SMS_SCENARIOS" /></a></p>
                </div>
                : null
            }
        </div>
    );
}
export default injectIntl(AutomatedSms);