export const ComponentConst = {
    Notifications: {
        LowCreditEmail: "low_credit_email",
        LowCreditPhone: "low_credit_phone",
        LowCreditLimit: "low_credit_limit"
    },
    Language: {
    },
    TopupCredit: {
        accountNumberCZ: "2801863905/2010",
        ibanNumberCZ: "CZ5420100000002801863905",
        swiftNumberCZ: "FIOBCZPPXXX",
        accountNumberHU: "12011148-01744228-00100001",
        ibanNumberHU: "HU66120111480174422800100001",
        swiftNumberHU: "UBRTHUHB",
        ibanNumberEU: "CZ2620100000002101903582",
        swiftNumberEU: "FIOBCZPPXXX"
    },
    SendSmsCampaignsSendingSms: {
        apiNumberCZ: "+420790865035",
        apiNumberSK: "+421940682104",
        apiNumberHU: "+420790865035",
        apiNumberEN: "+420790865035"
    }
};