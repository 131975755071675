import * as actionTypes from "./actionTypes"
import { setLanguage } from "../../../src/_metronic/i18n";

export const getSuccess = (user = {}) => ({
    type: actionTypes.GET_USER_SUCCESS,
    payload: user,
});

export const getError = (error = {}) => ({
    type: actionTypes.GET_USER_FAILED,
    payload: error
});

export const updateLoading = () => ({
    type: actionTypes.UPDATE_LOADING
});

export const getUser = () => {
    return (dispatch) => {
        dispatch(updateLoading());
        const lang = (new URLSearchParams(window.location.search)).get('lang');
        let GUID = (new URLSearchParams(window.location.search)).get('eshop');
        try {
            fetch('https://apishoptet.axima-sms.cz/eshop', {
                'headers': {
                    'X-eshop': GUID,
                    'X-Lang': lang,
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw res.statusText
                    } else {
                        return res.json()
                    }
                })
                .then(({ data } = {}) => data)
                .then(({ eshop } = {}) => {
                    dispatch(getSuccess(eshop))
                })
                .catch((error) => {
                    dispatch(getError(error))
                })

        } catch (error) {
            dispatch(getError(error));
        }
    };
};
export const emailVerified = (emailVerification) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.IS_EMAIL_VERIFIED,
            payload: emailVerification
        });
    }
}

export const phoneVerified = (phoneVerification) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.IS_PHONE_VERIFIED,
            payload: phoneVerification
        });
    }
}
export const updateRegistrationData = (email, phone) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_REGISTRATION,
            payload: {
                email,
                phone
            }
        });
    }
}

export const updateGUID = (guid) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_GUID,
            payload: guid
        });
    }
}

export const getUserLang = (lang) => {
    const langKey = setLocalStorageLang(lang);
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_USER_LANG,
            payload: langKey
        });
    }
}

const setLocalStorageLang = (lang) => {
    // let langKey = localStorage.getItem('lang');
    let langKey = localStorage.getItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig");
    if (!langKey) {
        langKey = lang;
        // localStorage.setItem('lang', lang);
        // localStorage.setItem('lang', lang);
        localStorage.setItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig", JSON.stringify({ selectedLang: lang }));
    }
    return langKey;
}

export const updateUserLang = (lang) => {
    // localStorage.setItem('lang', lang);
    localStorage.setItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig", JSON.stringify({ selectedLang: lang }));
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_USER_LANG,
            payload: lang
        });
    }
}
export const getUserCredit = (credit) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_USER_CREDIT,
            payload: credit
        });
    }
}
export const updateUserCredit = (credit) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_USER_CREDIT,
            payload: credit
        });
    }
}
export const getUserCurrency = (currency) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_USER_CURRENCY,
            payload: currency
        });
    }
}

export const getUserAccountNumber = (number) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_USER_ACCOUNT_NUMBER,
            payload: number
        });
    }
}
export const updateNotificationLimit = (limit) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_NOTIFICATION_LIMIT,
            payload: limit
        });
    }
}
export const updateNotificationEmail = (sendToEmail) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_NOTIFICATION_EMAIL,
            payload: sendToEmail
        });
    }
}
export const updateNotificationSMS = (sendToSMS) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_NOTIFICATION_SMS,
            payload: sendToSMS
        });
    }
}

export const setSmsScenariosLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_SMS_SCENARIOS_LINK,
            payload: link
        });
    }
}

export const setTopupCreditLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_TOPUP_CREDIT_LINK,
            payload: link
        });
    }
}
export const setInvoicesLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_INVOICES_LINK,
            payload: link
        });
    }
}
export const setNotificationsLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_NOTIFICATIONS_LINK,
            payload: link
        });
    }
}

export const getFilteredPhoneNumber = (count) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_FILTERED_PHONE_NUMBERS,
            payload: count
        });
    }
}

export const getCampaignName = (campaignName) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_CAMPAIGN_NAME,
            payload: campaignName
        });
    }
}

export const getFilteredObject = (filteredObject) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_FILTERED_OBJECT,
            payload: filteredObject
        });
    }
}
export const getUnseenPayment = (unseenPayment) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_UNSEEN_PAYMENT,
            payload: unseenPayment
        });
    }
}
export const updateUnseenPayment = (unseenPayment) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_UNSEEN_PAYMENT,
            payload: unseenPayment
        });
    }
}
export const clearUnseenPayment = (unseenPayment) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_UNSEEN_PAYMENT,
            payload: unseenPayment
        });
    }
}
export const seenPayment = (seenPayment) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SEEN_PAYMENT,
            payload: seenPayment
        });
    }
}
export const getOrderCode = (orderCode) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_ORDER_CODE,
            payload: orderCode
        });
    }
}
export const smsActivationClicked = (IsSmsActivationClicked) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.IS_SMS_ACTIVATION_CLICKED,
            payload: IsSmsActivationClicked
        });
    }
}

export const setSendSmsCampaignLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_SEND_SMS_CAMPAIGN_LINK,
            payload: link
        });
    }
}
export const getCampaignID = (campaignID) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_CAMPAIGN_ID,
            payload: campaignID
        });
    }
}
export const setCampaignDetail = (campaingDetail) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_CAMPAIGN_DETAIL,
            payload: campaingDetail
        });
    }
}