// actions
export const IS_DISABLED = "IS_DISABLED"
export const UPDATE_LOADING = "UPDATE_LOADING"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILED = "GET_USER_FAILED"
export const IS_EMAIL_ACTIVATED = "IS_EMAIL_ACTIVATED"
export const IS_SMS_ACTIVATED = "IS_SMS_ACTIVATED"
export const IS_EMAIL_VERIFIED = "IS_EMAIL_VERIFIED"
export const IS_PHONE_VERIFIED = "IS_PHONE_VERIFIED"
export const UPDATE_GUID = "UPDATE_GUID"
export const GET_USER_LANG = "GET_USER_LANG"
export const UPDATE_USER_LANG = "UPDATE_USER_LANG"
export const GET_USER_CREDIT = "GET_USER_CREDIT"
export const UPDATE_USER_CREDIT = "UPDATE_USER_CREDIT"
export const GET_USER_CURRENCY = "GET_USER_CURRENCY"
export const UPDATE_REGISTRATION = "UPDATE_REGISTRATION"
export const GET_USER_ACCOUNT_NUMBER = "GET_USER_ACCOUNT_NUMBER"
export const UPDATE_NOTIFICATION_LIMIT = "UPDATE_NOTIFICATION_LIMIT"
export const UPDATE_NOTIFICATION_EMAIL = "UPDATE_NOTIFICATION_EMAIL"
export const UPDATE_NOTIFICATION_SMS = "UPDATE_NOTIFICATION_SMS"
export const SET_SMS_SCENARIOS_LINK = "SET_SMS_SCENARIOS_LINK"
export const SET_TOPUP_CREDIT_LINK = "SET_TOPUP_CREDIT_LINK"
export const SET_INVOICES_LINK = "SET_INVOICES_LINK"
export const GET_FILTERED_PHONE_NUMBERS = "GET_FILTERED_PHONE_NUMBERS"
export const GET_CAMPAIGN_NAME = "GET_CAMPAIGN_NAME"
export const GET_FILTERED_OBJECT = "GET_FILTERED_OBJECT"
export const SET_NOTIFICATIONS_LINK = "SET_NOTIFICATIONS_LINK"
export const GET_UNSEEN_PAYMENT = "GET_UNSEEN_PAYMENT"
export const UPDATE_UNSEEN_PAYMENT = "UPDATE_UNSEEN_PAYMENT"
export const CLEAR_UNSEEN_PAYMENT = "CLEAR_UNSEEN_PAYMENT"
export const GET_ORDER_CODE = "GET_ORDER_CODE"
export const SEEN_PAYMENT = "SEEN_PAYMENT"
export const IS_SMS_ACTIVATION_CLICKED = "IS_SMS_ACTIVATION_CLICKED"
export const SET_SEND_SMS_CAMPAIGN_LINK = "SET_SEND_SMS_CAMPAIGN_LINK"
export const GET_CAMPAIGN_ID = "GET_CAMPAIGN_ID"
export const SET_CAMPAIGN_DETAIL = "SET_CAMPAIGN_DETAIL"