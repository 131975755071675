import React, { useState, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";
import { injectIntl } from 'react-intl'
import Form from 'react-bootstrap/Form'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import validator from 'validator';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import 'react-phone-number-input/style.css'
import ErrorIcon from '@material-ui/icons/Error';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { isEmpty } from 'lodash'
import { getData, postData, responseCode, useIsMountedRef, sortDataByDate } from '../../utils/helpers';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@material-ui/icons/Close';

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: "rgba(0, 0, 0, 0.87);",
                backgroundColor: "#FFF",
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 70%',
    },
}));

function OptOut({ intl: { formatMessage } }) {

    const classes = useStyles2();
    // const classes2 = useToolbarStyles();

    const phoneNumberRef = createRef();
    const reasonTextRef = createRef();
    const reasonTextRefModal = createRef();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [reasonText, setReasonText] = useState('');
    const [isPhoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
    const [isReasonError, setReasonError] = useState(false);
    const [reasonMessage, setReasonErrorMessage] = useState('');
    const [isReasonModalError, setReasonModalError] = useState(false);
    const [reasonModalMessage, setReasonModalErrorMessage] = useState('');
    const [isErred, setErred] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [data, setData] = useState([]);
    const [newArr, setNewArr] = useState([]);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [lastCount, setLastCount] = useState(0);
    const [lastPageVisited, setLastPageVisited] = useState(false);
    const [searchURL, setSearchURL] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [toNumber, setToNumber] = useState("");

    const user = useSelector(state => state.user.user);

    let emptyRows = 0;
    const count = 100;
    let csvArr = [];

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (isPhoneNumberError || isReasonError || isReasonModalError || isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setPhoneNumberError(false);
                setReasonError(false);
                setReasonModalError(false);
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [isPhoneNumberError, isReasonError, isReasonModalError, isErred, isSaved]);

    useEffect(() => {
        getBlackListData();
    }, []);

    const getBlackListData = async () => {

        // const resData = await getData('https://apishoptet.axima-sms.cz/sms/list?count=' + count);
        const resData = await getData('https://apishoptet.axima-sms.cz/sms/blacklist/list?&count=' + count);
        const sortedData = sortDataByDate(resData.data.blacklist);
        if (isMountedRef.current) {
            if (!isEmpty(resData.data)) {
                // const sortedData = sortDataByDate(resData.data.blacklist);
                console.log({ sortedData });
                // setData(resData.data.blacklist);
                setData(sortedData);
                emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
                setTotalCount(resData.data.count);
                setLastCount(count);
                // setSearchURL(`https://apishoptet.axima-sms.cz/sms/list?from=&to=&order_code=&msisdn=&search=&start=&count=${count}`)
                setSearchURL(`https://apishoptet.axima-sms.cz/sms/blacklist/list?start=&count=${count}&search=`);
                console.log({ resData });
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const getDataWithCount = async (start) => {

        // const resData = await getData(`https://apishoptet.axima-sms.cz/sms/list?from=&to=&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&start=${start}&count=${count}`);
        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/blacklist/list?start=${start}&count=${count}&search=${searchText !== null ? searchText : ""}`);
        const sortedData = sortDataByDate(resData.data.blacklist);
        if (!isEmpty(resData.data.blacklist)) {
            // setData(data.concat(resData.data.blacklist));
            setData(data.concat(sortedData));
        } else {
            console.error(resData.error.error);
        }
    }

    const handleChangePage = (event, newPage) => {
        console.log({ newPage })
        console.log({ page })
        const pageCount = count / rowsPerPage;
        const totalPageCount = Math.floor(totalCount / rowsPerPage);
        switch (true) {
            case event.currentTarget.getAttribute("aria-label") === "next page":
                if (newPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((newPage * rowsPerPage) + 1) / count) * count);
                    (async () => { await getDataWithCount(lastCount); setPage(newPage); })();
                } else {
                    setPage(newPage);
                }
                if ((newPage === totalPageCount) && !lastPageVisited) {
                    setLastPageVisited(true);
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "last page":
                let ceilPage = Math.ceil((page + 1) / pageCount) * pageCount;

                if (ceilPage > totalPageCount && !lastPageVisited) {
                    ceilPage = totalPageCount;
                    setLastPageVisited(true);
                }
                if (ceilPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((ceilPage * rowsPerPage) + 1) / count) * count);
                    (async () => {
                        await getDataWithCount(lastCount); if (ceilPage > totalPageCount) {
                            setPage(totalPageCount);
                        } else {
                            setPage(ceilPage);
                        }
                    })();
                } else {
                    if (ceilPage >= totalPageCount || lastPageVisited) {
                        setPage(totalPageCount);
                    } else {
                        setPage((lastCount - count) / rowsPerPage);
                    }
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "previous page" || event.currentTarget.getAttribute("aria-label") === "first page":
                setPage(newPage);
            default:
                break;
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeValue = (event) => {
        setSearchText(event.target.value);
    };

    const handlePhone = (phone) => {
        if (phoneNumber !== phone) setPhoneNumber(phone);

        if (phone === '' || phone === undefined) {
            setPhoneNumberError(true);
            setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
            return;
        }
        // else {
        //     if (phone.length < 13 && phone.length > 4) {
        //         setPhoneNumberError(true);
        //         setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
        //         return;
        //     }
        // }

        const phoneLib = parsePhoneNumberFromString(phone);

        if (phoneLib) {
            // if (!phoneLib.isPossible() || !phoneLib.isValid()) {
            if (!phoneLib.isPossible()) {
                setPhoneNumberError(true);
                setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />)
            } else {
                setPhoneNumberError(false);
            }
        }
    }

    const handleReasonText = (event) => {
        setReasonText(event.target.value);
    };


    const handleClick = async () => {

        let error = false;
        const phoneLib = parsePhoneNumberFromString(phoneNumberRef.current.value);
        // const isPossiblePhoneNumber = isPossiblePhoneNumber(phoneNumberRef.current.value);
        console.log({ phoneLib });
        console.log({ phoneNumber });
        console.log(phoneNumberRef.current.value);
        const phoneValue = phoneNumberRef.current.value.length;
        console.log({ phoneValue });
        // console.log(parsePhoneNumberFromString(phoneNumberRef.current.value.length));
        // console.log(phoneValue.isPossible())
        console.log(isPossiblePhoneNumber(phoneNumberRef.current.value));

        if (!isPossiblePhoneNumber(phoneNumberRef.current.value)) {
            setPhoneNumberError(true);
            setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />)
            error = true;
        } else {
            setPhoneNumberError(false);
        }

        if (reasonTextRef.current.value === '') {
            console.log(reasonTextRef.current.value === '', "reason text alani bos");
            setReasonError(true);
            setReasonErrorMessage(<FormattedMessage id="SNACKBAR_EMPTY_REASON" />);
            error = true;
        }

        if (error) {
            return;
        }

        const data = await postData('https://apishoptet.axima-sms.cz/sms/blacklist/add', [{
            'to': phoneNumber,
            'reason': reasonTextRef.current.value
        }]);

        console.log({ data });

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK" && data.data.blacklist["0"].result === true) {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKNBAR_OPTOUT_NUMBER_ADDED_OK" />);
            getBlackListData();
            setPhoneNumber("");
            setReasonText("");
        } else if (data.data.blacklist["0"].result === false && data.data.blacklist["0"].msisdn !== null) {
            setErred(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_INVALID_ALREADY_BLACKLISTED" />);
        } else {
            setErred(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />);
        }

    }

    const handleKeyDown = async (event) => {
        // if (event.key === 'Enter') {
        if (event.keyCode === 13) {
            const url = `https://apishoptet.axima-sms.cz/sms/blacklist/list?search=${searchText !== null ? searchText : ""}`;
            if (url !== searchURL) {
                const resData = await getData(url);
                const sortedData = sortDataByDate(resData.data.blacklist);
                if (!isEmpty(resData.data)) {
                    setTotalCount(resData.data.count);
                    // setData(resData.data.blacklist);
                    setData(sortedData);
                    setLastCount(count);
                    setSearchURL(url);
                    setPage(0);
                } else {
                    console.error(resData.error.error);
                }
            }
        }
    }

    const filterClick = async () => {
        const url = `https://apishoptet.axima-sms.cz/sms/blacklist/list?search=${searchText !== null ? searchText : ""}`;
        if (url !== searchURL) {
            const resData = await getData(url);
            const sortedData = sortDataByDate(resData.data.blacklist);
            if (!isEmpty(resData.data)) {
                setTotalCount(resData.data.count);
                // setData(resData.data.blacklist);
                setData(sortedData);
                setLastCount(count);
                setSearchURL(url);
                setPage(0);
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const downloadClick = async () => {

        // const exportOptOutData = await getData('https://apishoptet.axima-sms.cz/sms/blacklist/list?&count=' + count, 'text');
        // setExportData(exportOptOutData);
        if (csvArr.length > 0) {
            csvArr = [];
        }
        csvArr = [...data];

        let formattedDateData = csvArr.map(function (obj) {
            return {
                to: obj.to,
                created: moment(obj.created).format("YYYY-MM-DD HH:mm:ss"),
                reason: obj.reason
            }
        });

        formattedDateData.unshift({ to: formatMessage({ id: 'OPTOUT_COLUMN1_NAME' }), created: formatMessage({ id: 'OPTOUT_COLUMN2_NAME' }), reason: formatMessage({ id: 'OPTOUT_COLUMN3_NAME' }) });
        let newData = formattedDateData.map(function (obj) {
            return {
                to: obj.to,
                created: obj.created,
                reason: obj.reason
            }
        });

        var csvContent = newData.map(function (d) {
            return JSON.stringify(Object.values(d));
        })
            .join('\n')
            .replace(/(^\[)|(\]$)/mg, '');

        console.log(csvContent);

        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
        //document.body.removeChild(anchor);       
    }

    const handleRemoveClick = async (to) => {
        let newArray = [...data];
        setNewArr(newArray);
        const foundNumber = newArray.findIndex(x => x.to === to);

        setToNumber(newArray[foundNumber].to);

        console.log(newArray[foundNumber].to);
        console.log(newArray[foundNumber].reason);
        console.log(newArray);

        setShowModal(true);
    }
    const handleModalClick = async () => {
        if (reasonTextRefModal.current.value === '') {
            console.log(reasonTextRefModal.current.value === '', "reason text alani bos");
            setReasonModalError(true);
            setReasonModalErrorMessage(<FormattedMessage id="SNACKBAR_EMPTY_REASON" />);
        } else {
            const postBlackListData = await postData('https://apishoptet.axima-sms.cz/sms/blacklist/remove', [{
                'to': toNumber,
                'reason': reasonTextRefModal.current.value
            }]);

            console.log({ postBlackListData });

            responseCode(postBlackListData, setResponseMessage, setErred);
            if (postBlackListData.error.error === "OK" && postBlackListData.data.blacklist["0"].result === true) {
                // newArr = newArr.filter(function (item) {
                //     return item !== newArr[foundNumber]
                // });
                setIsSaved(true);
                setResponseMessage(<FormattedMessage id="SNACKNBAR_OPTOUT_NUMBER_REMOVED_OK" />);
                setData(newArr);
                getBlackListData();
                console.log(newArr);
                setShowModal(false);
            }
        }
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
        setReasonModalError(false);
    }

    const handleSnackbarClosePhoneNumberError = () => {
        setPhoneNumberError(false);
    }

    const handleSnackbarCloseReasonError = () => {
        setReasonError(false);
    }


    return (
        <div className="col-lg-12 px-0 mt-10 opt-out-wrapper">
            <div className="col-lg-12 px-0 mt-10 mb-10">
                {/* <h2 className="mb-5"><FormattedMessage id="OPTOUT_NAME" /></h2> */}
                <p><FormattedMessage id="OPTOUT_INFO" /></p>
            </div>
            <div className="col-lg-12 px-0">
                <Form className="col-lg-12 px-0 opt-out-form">
                    <div className="row">
                        <div className="col-lg-2 col-md-3">
                            <Form.Group>
                                <FormControlLabel className="m-0 w-100"
                                    control={
                                        <PhoneInput
                                            ref={phoneNumberRef}
                                            // defaultCountry="CZ"
                                            // country="CZ"
                                            defaultCountry={user.billing.country.countryCode}
                                            country={user.billing.country.countryCode}
                                            international
                                            value={phoneNumber}
                                            onChange={handlePhone}
                                            className={isPhoneNumberError ? 'error' : ''}
                                            limitMaxLength={true} />}
                                    label={<FormattedMessage id="MOBILE_NUMBER" />}
                                    labelPlacement="top"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <Form.Group>
                                <FormControlLabel className="m-0 w-100"
                                    control={
                                        <Form.Control ref={reasonTextRef} value={reasonText} onChange={handleReasonText} />}
                                    label={<FormattedMessage id="OPTOUT_COLUMN3_NAME" />}
                                    labelPlacement="top"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <Form.Group className="mt-3">
                                <Button onClick={() => handleClick()} variant="contained" color="primary" className="float-right mt-5">
                                    <FormattedMessage id="OPTOUT_BUTTON_ADD" />
                                </Button>
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                {
                    (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar">
                            <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{responseMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                            </div>
                        </div>
                    </div>
                }
                {
                    isPhoneNumberError && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar">
                            <div className="snackbar-content">
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{phoneNumberMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClosePhoneNumberError}></a>
                            </div>
                        </div>
                    </div>
                }
                {
                    isReasonError && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar">
                            <div className="snackbar-content">
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{reasonMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarCloseReasonError}></a>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="col-lg-12 px-0 mt-10">
                <div className="row mt-10 mb-10">
                    {/* <div className="col-lg-4"><h2><FormattedMessage id="OPTOUT_TABLE_NAME" /></h2></div> */}
                    {/* <div className="col-lg-8 text-right"> */}
                    <div className="col-lg-12 text-right">
                        <div className="filter-input-wrapper">
                            <FormattedMessage id="SEARCH_BUTTON" defaultMessage="Search">
                                {placeholder =>
                                    <input placeholder={placeholder} type="text" value={searchText} onChange={handleChangeValue} onKeyDown={handleKeyDown} className="filter-input" />
                                }
                            </FormattedMessage>
                        </div>
                        <Button onClick={filterClick} variant="contained" color="primary" className="ml-10">
                            <FormattedMessage id="DISPLAY_BUTTON" />
                        </Button>
                    </div>
                </div>
                <div className="col-lg-12 px-4 pt-4 pb-0 filtered-table">
                    {/* <EnhancedTableToolbar /> */}
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><FormattedMessage id="OPTOUT_COLUMN1_NAME" /></TableCell>
                                    <TableCell><FormattedMessage id="OPTOUT_COLUMN2_NAME" /></TableCell>
                                    <TableCell><FormattedMessage id="OPTOUT_COLUMN3_NAME" /></TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            {data.length === 0 ?
                                <TableBody>
                                    <TableRow style={{ height: 80 }}>
                                        <TableCell colSpan={9} className="font-weight-bold"><FormattedMessage id="SIMPLE_SMS_LIST_IN_SENDING_EMPTY" /></TableCell>
                                    </TableRow>
                                </TableBody> :
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : data
                                    ).map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.to}</TableCell>
                                            <TableCell>{(moment(row.created).format("YYYY-MM-DD HH:mm:ss"))}</TableCell>
                                            <TableCell>{row.reason}</TableCell>
                                            <TableCell style={{ width: 20 }}>
                                                <Dropdown className="sms-campaign-list-dropdown">
                                                    <DropdownButton
                                                        title=""
                                                        drop="left"
                                                        variant="secondary">
                                                        <Dropdown.Item eventKey="" onClick={() => handleRemoveClick(row.to)}><FormattedMessage id="OPTOUT_THREEDOTS_REMOVE" /></Dropdown.Item>
                                                    </DropdownButton>
                                                </Dropdown>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={9} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            }
                            <TableFooter>
                                <TableRow>
                                    <TableCell className="download-export-wrapper">
                                        <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                                </Tooltip>
                                            }
                                        >
                                            <IconButton aria-label="download" onClick={downloadClick}>
                                                <GetAppIcon color="secondary" />
                                            </IconButton>
                                        </OverlayTrigger>
                                    </TableCell>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20]}
                                        colSpan={9}
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <Modal
                show={showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="md"
                centered
                className="form-modal">
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <FormattedMessage id="OPTOUT_POPUP_REMOVING_NUMBER1" /> <span className="text-danger">{toNumber}</span> <FormattedMessage id="OPTOUT_POPUP_REMOVING_NUMBER2" />
                        <a href="#!" onClick={() => setShowModal(false)} className="close-button"><CloseIcon className="icon text-danger" style={{ fontSize: 40 }} /></a>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControlLabel className="m-0 w-100 align-items-start"
                        control={
                            <Form.Control ref={reasonTextRefModal} />}
                        label={<FormattedMessage id="OPTOUT_COLUMN3_NAME" />}
                        labelPlacement="top"
                    />
                    {
                        isReasonModalError && <div className="snackbar my-5">
                            <div className="snackbar-content">
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{reasonModalMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)} variant="contained" color="primary" className="modal-cancel-button mr-5"><FormattedMessage id="OPTOUT_POPUP_REMOVING_NUMBER_BTN_BACK" /></Button>
                    <Button onClick={handleModalClick} variant="contained" color="primary" className="modal-confirm-button"><FormattedMessage id="OPTOUT_POPUP_REMOVING_NUMBER_BTN_CONFIRM" /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default injectIntl(OptOut);