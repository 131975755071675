import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from "react-redux";
import Switch from '@material-ui/core/Switch';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SaveIcon from '@material-ui/icons/Save';
import Dropdown from 'react-bootstrap/Dropdown'
import { postData, responseCode } from '../../utils/helpers';
import { FormattedMessage } from "react-intl";
import ErrorIcon from '@material-ui/icons/Error';
import SmsTextarea from './SmsTextarea';

function SmsTemplate(props) {

    const [switchState, setSwitchState] = useState(props.message.active);
    // const textareaRef = createRef();
    const [textValue, setTextValue] = useState(props.message.message);
    const [textValueLength, setTextValueLength] = useState(props.message.message.length);
    // const [selectedValue, setSelectedValue] = useState('');
    const [textChange, setTextChange] = useState(false);
    const [disabled, setDisabled] = useState(true);
    // const [smsCount, setSmsCount] = useState(1);
    // const [smsLength, setSmsLength] = useState(160);
    // const [maxLength, setMaxLength] = useState(765);
    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const childProps = {
        textValue,
        textValueLength,
        disabled: disabled,
        setTextValue,
        setTextValueLength,
        textChange,
        setTextChange,
        hasDropdown: true
    };

    // useEffect(() => {
    //     changeTextValueLength(textValue);
    //     smsCountControl(textValue);
    // }, []);

    useEffect(() => {
        if (isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [isErred, isSaved]);


    const handleSwitch = async (event) => {
        setSwitchState(event.target.checked);
        const data = await postData('https://apishoptet.axima-sms.cz/order/status', [{
            'id': props.id,
            'active': event.target.checked,
            'message': textValue
        }]);

        // if (responseCode(data.error.error !== "OK")) {
        //     setErred(true);
        //     setResponseMessage(<FormattedMessage id={data.error.error} />)
        // } else {
        //     setIsSaved(true);
        //     setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        // }

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        }
    };

    const handleEditClick = () => {
        setDisabled(false);
    }

    const handleSaveClick = async (id) => {
        if (textChange) {
            const data = await postData('https://apishoptet.axima-sms.cz/order/status', [{
                'id': id,
                'active': switchState,
                'message': textValue
            }]);

            if (data.error.error !== "OK") {
                setErred(true);
                setDisabled(false);
                setTextChange(true);
                setResponseMessage(<FormattedMessage id={data.error.error} />)
            } else {
                setIsSaved(true);
                setTextChange(false);
                setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
            }
        }
        setDisabled(true);
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }

    return (
        <>
            <div className="row position-relative " key={props.id} id={props.id}>
                <div className="col-lg-2 col-md-2 col-sm-2 align-self-center order-status"><span className="status" style={{ background: props.backgroundColor, color: props.color }}>{props.name}</span></div>
                <div className="col-lg-2 col-md-2 col-sm-2 align-self-center status-switch">
                    <Switch className="sms-template-switch"
                        checked={switchState}
                        name={"switch" + props.id}
                        onChange={handleSwitch}
                        value={switchState}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 sms-text">
                    <Form.Group as={Row} controlId={props.id}>
                        {/* <SmsTextarea textValue={textValue} textValueLength={textValueLength} disabled={disabled}
                            setTextValue={setTextValue} setTextValueLength={setTextValueLength}
                            textChange={textChange} setTextChange={setTextChange} /> */}
                        <SmsTextarea {...childProps} />
                        <div className="sms-buttons-wrapper">
                            {disabled ?
                                <IconButton aria-label="Edit" className="p-0 mt-8" onClick={handleEditClick}>
                                    <BorderColorIcon color="primary" />
                                </IconButton> :
                                <IconButton aria-label="Edit" className="p-0 mt-8" onClick={() => handleSaveClick(props.id)}>
                                    <SaveIcon className="save-icon" />
                                </IconButton>}
                        </div>
                    </Form.Group>
                </div>
                {
                    (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar my-5">
                            <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{responseMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <hr />
        </>
    );
}
export default SmsTemplate;