export const priceList = [
    {
        countryCode: 'AT',
        huPrice: 15,
        czPrice: '1,00',
        skPrice: '0,039'
    },
    {
        countryCode: 'BE',
        huPrice: 24,
        czPrice: '1,60',
        skPrice: '0,062'
    },
    {
        countryCode: 'BG',
        huPrice: 24,
        czPrice: '1,60',
        skPrice: '0,062'
    },
    {
        countryCode: 'HR',
        huPrice: 13,
        czPrice: '0,85',
        skPrice: '0,033'
    },
    {
        countryCode: 'CY',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'CZ',
        huPrice: 14,
        czPrice: '0,90',
        skPrice: '0,035'
    },
    {
        countryCode: 'DK',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'EE',
        huPrice: 15,
        czPrice: '1,00',
        skPrice: '0,039'
    },
    {
        countryCode: 'FI',
        huPrice: 17,
        czPrice: '1,13',
        skPrice: '0,044'
    },
    {
        countryCode: 'FR',
        huPrice: 16,
        czPrice: '1,05',
        skPrice: '0,041'
    },
    {
        countryCode: 'DE',
        huPrice: 24,
        czPrice: '1,60',
        skPrice: '0,062'
    },
    {
        countryCode: 'GR',
        huPrice: 16,
        czPrice: '1,05',
        skPrice: '0,041'
    },
    {
        countryCode: 'HU',
        huPrice: 15,
        czPrice: '1,00',
        skPrice: '0,039'
    },
    {
        countryCode: 'IE',
        huPrice: 16,
        czPrice: '1,05',
        skPrice: '0,041'
    },
    {
        countryCode: 'IT',
        huPrice: 13,
        czPrice: '0,82',
        skPrice: '0,032'
    },
    {
        countryCode: 'LV',
        huPrice: 12,
        czPrice: '0,77',
        skPrice: '0,030'
    },
    {
        countryCode: 'LT',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'LU',
        huPrice: 12,
        czPrice: '0,75',
        skPrice: '0,029'
    },
    {
        countryCode: 'MT',
        huPrice: 16,
        czPrice: '1,08',
        skPrice: '0,042'
    },
    {
        countryCode: 'NL',
        huPrice: 24,
        czPrice: '1,60',
        skPrice: '0,062'
    },
    {
        countryCode: 'PL',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'PT',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'RO',
        huPrice: 15,
        czPrice: '0,93',
        skPrice: '0,036'
    },
    {
        countryCode: 'SK',
        huPrice: 15,
        czPrice: '0,95',
        skPrice: '0,037'
    },
    {
        countryCode: 'SI',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'ES',
        huPrice: 13,
        czPrice: '0,85',
        skPrice: '0,033'
    },
    {
        countryCode: 'SE',
        huPrice: 13,
        czPrice: '0,85',
        skPrice: '0,033'
    },
    {
        countryCode: 'CH',
        huPrice: 14,
        czPrice: '0,90',
        skPrice: '0,035'
    },
    {
        countryCode: 'GB',
        huPrice: 11,
        czPrice: '0,70',
        skPrice: '0,027'
    },
    {
        countryCode: 'NO',
        huPrice: 14,
        czPrice: '0,90',
        skPrice: '0,035'
    },
    {
        countryCode: 'UA',
        huPrice: 24,
        czPrice: '1,60',
        skPrice: '0,062'
    }
]