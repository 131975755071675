import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEmpty } from 'lodash'

function Footer({ intl: { formatMessage } }) {

    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.user.currency);

    const [emailAddress, setEmailAddress] = useState('');

    useEffect(() => {
        if (!isEmpty(user)) {
            setEmailAddress(formatMessage({ id: 'FOOTER_SUPPORT_EMAIL' }).replaceAll("[country]", user.billing.country.countryCode).toLowerCase());
        }
    }, [user]);

    return (
        <footer className="col-lg-12 mt-10 mx-0 pl-15 pr-6 py-6">
            <div className="row pl-15 mx-0 position-relative">
                <div className="col-lg-5 col-md-5">
                    <span className="font-weight-bold">© {(new Date().getFullYear())} ProfiSMS s.r.o.</span> <br />
                    <span>Thámova 166/18, 186 00 Praha 8, Czech Republic</span>
                </div>
                <div className="col-lg-4 col-md-4">
                    {!isEmpty(user) ? (user.billing.country.countryCode === "CZ" &&
                        <><a href="https://www.axima-sms.cz/shoptet/memorandum.pdf" target="_blank"><FormattedMessage id="FOOTER_PRIVACY_POLICY" /></a><br />
                            <a href="https://www.axima-sms.cz/shoptet/vop_shoptet.pdf" target="_blank"><FormattedMessage id="FOOTER_TC" /></a></>)
                        || (user.billing.country.countryCode === "HU" &&
                            <><a href="https://www.axima-sms.cz/shoptet-hu/vop_shoptet-hu.pdf" target="_blank"><FormattedMessage id="FOOTER_PRIVACY_POLICY" /></a><br />
                                <a href="https://www.axima-sms.cz/shoptet-hu/memorandum-hu.pdf" target="_blank"><FormattedMessage id="FOOTER_TC" /></a></>)
                        || (user.billing.country.countryCode === "SK" &&
                            <><a href="https://www.axima-sms.cz/shoptet-sk/memorandum-sk.pdf" target="_blank"><FormattedMessage id="FOOTER_PRIVACY_POLICY" /></a><br />
                                <a href="https://www.axima-sms.cz/shoptet-sk/vop_shoptet-sk.pdf" target="_blank"><FormattedMessage id="FOOTER_TC" /></a></>) : null}
                </div>
                <div className="col-lg-3 col-md-3">
                    <span><FormattedMessage id="FOOTER_SUPPORT" /></span><br />
                    <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                </div>
            </div>
            {/* {(currency === "CZK") ? null :
                <div className="row mt-7 pl-15 py-2 footer-logos">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 px-0 d-flex align-items-center">
                        <FormattedMessage id="FOOTER_COMGATE_INFO1" /> <a href="https://www.comgate.cz/cz/platebni-brana" target="_blank"> <FormattedMessage id="FOOTER_COMGATE_LINK" /></a>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 px-0">
                        <img src="../media/logos/comgate.png" className="comgate-img" />
                        <img src="../media/logos/visa.png" className="visa-img" />
                        <img src="../media/logos/master.png" className="master-img" />
                    </div>
                </div>
            } */}
        </footer>
    )
}

export default injectIntl(Footer);