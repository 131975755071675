import React, { createContext, useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { injectIntl, defineMessages, useIntl, FormattedMessage, IntlProvider } from 'react-intl';
import { useLang } from "../../i18n/Metronici18n";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/cs";
import "@formatjs/intl-relativetimeformat/dist/locale-data/hr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/sk";

import enMessages from "../../i18n/messages/en";
import csMessages from "../../i18n/messages/cs";
import huMessages from "../../i18n/messages/hu";
import skMessages from "../../i18n/messages/sk";

const allMessages = {
  en: enMessages,
  cs: csMessages,
  hu: huMessages,
  sk: skMessages
};


const MetronicSplashScreenContext = createContext();

export function MetronicSplashScreenProvider({ children }) {
  const [count, setCount] = useState(0);
  let visible = count > 0;

  const locale = useLang();
  const messages = allMessages[locale];

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    const div = document.createElement('div');
    document.getElementById('loading-text').appendChild(div);
    ReactDOM.render(<div>
      <IntlProvider locale={locale} messages={messages}>
        <FormattedMessage id='LOADING_PAGE_INFO' />
      </IntlProvider>
    </div>, div);

    // Show SplashScreen
    if (splashScreen && visible) {
      splashScreen.classList.remove("hidden");

      return () => {
        splashScreen.classList.add("hidden");
      };
    }

    // Hide SplashScreen
    let timeout;
    if (splashScreen && !visible) {
      timeout = setTimeout(() => {
        splashScreen.classList.add("hidden");
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  return (
    <MetronicSplashScreenContext.Provider value={setCount}>
      {children}
    </MetronicSplashScreenContext.Provider>
  );
}

// injectIntl(MetronicSplashScreenProvider);

export function LayoutSplashScreen({ visible = true }) {
  // Everything are ready - remove splashscreen
  const setCount = useContext(MetronicSplashScreenContext);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setCount(prev => {
      return prev + 1;
    });

    return () => {
      setCount(prev => {
        return prev - 1;
      });
    };
  }, [setCount, visible]);

  return null;
}
