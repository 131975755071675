import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Switch from '@material-ui/core/Switch';
import Form from 'react-bootstrap/Form'
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import { FormattedMessage } from "react-intl";
import { postData, responseCode, useIsMountedRef } from '../../utils/helpers';
import ErrorIcon from '@material-ui/icons/Error';
import { updateNotificationLimit, updateNotificationEmail, updateNotificationSMS } from "../../redux/actions/userActions";
import { ComponentConst } from '../../utils/definitions';

function Notifications() {

    const user = useSelector(s => s.user.user);
    const credit = useSelector(state => state.user.credit);
    const currency = useSelector(state => state.user.currency);
    const limit = useSelector(state => state.user.limit);
    const sendToEmail = useSelector(state => state.user.sendToEmail);
    const sendToSMS = useSelector(state => state.user.sendToSMS);

    const [amount, setAmount] = React.useState(0);
    const [isSetAmount, setIsSetAmount] = React.useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [init, setInit] = useState(false);

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isMountedRef.current) {
            if ((sendToEmail === undefined && sendToSMS === undefined)) {
                for (let i = 0; i < user.value.length; i++) {
                    const element = user.value[i];
                    if (element.type === ComponentConst.Notifications.LowCreditEmail) {
                        dispatch(updateNotificationEmail(true));
                    } else if (element.type === ComponentConst.Notifications.LowCreditPhone) {
                        dispatch(updateNotificationSMS(true));
                    } else if (element.type === ComponentConst.Notifications.LowCreditLimit) {
                        setAmount(element.value);
                        // limitPostDispatch(element.value);
                    }
                }
            }

            setInit(true);
        }
    }, []);

    useEffect(() => {
        if (isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }

        if (init) {
            // if (limit) {
            //     console.log({ limit });
            //     setAmount(limit);
            // } else if (!limit || limit === 0 || limit === "0") {
            //     console.log("!limit" + !limit);
            //     console.log("limit = 0" + limit === 0);
            //     // if (user.billing.country.countryCode === "CZ") {
            //     if (currency === "CZK") {
            //         setAmount(10);
            //         limitPostDispatch(10);
            //         // } else if (user.billing.country.countryCode === "HU") {
            //     } else if (currency === "HUF") {
            //         setAmount(100);
            //         limitPostDispatch(100);
            //         // } else if (user.billing.country.countryCode === "SK") {
            //     } else if (currency === "EUR") {
            //         setAmount(0.2);
            //         limitPostDispatch(0.2);
            //     }
            // }
            if (limit && limit !== 0 && limit !== "0") {
                console.log({ limit });
                setAmount(limit);
            } else if (!limit || limit === 0 || limit === "0") {
                console.log("!limit" + !limit);
                console.log("limit = 0" + limit === 0);
                // if (user.billing.country.countryCode === "CZ") {
                if (currency === "CZK") {
                    setAmount(10);
                    limitPostDispatch(10);
                    // } else if (user.billing.country.countryCode === "HU") {
                } else if (currency === "HUF") {
                    setAmount(100);
                    limitPostDispatch(100);
                    // } else if (user.billing.country.countryCode === "SK") {
                } else if (currency === "EUR") {
                    setAmount(0.2);
                    limitPostDispatch(0.2);
                }
            }
        }
    }, [isErred, isSaved, init]);

    useEffect(() => {
        if (isSetAmount) {
            let timeOut = setTimeout(() => {
                (async () => {
                    dispatch(updateNotificationLimit(amount));
                    const data = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
                        'credit': amount
                    });

                    responseCode(data, setResponseMessage, setErred);
                    if (data.error.error === "OK") {
                        setIsSaved(true);
                        // setIsChanged(false);
                        setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
                    }

                    console.log({ data });
                })();
            }, 2000);
            return () => clearTimeout(timeOut);
        }
    }, [amount]);


    const limitPostDispatch = async (limitParameter) => {
        dispatch(updateNotificationLimit(limitParameter));
        const data = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
            'credit': limitParameter
        });
    }

    const handleChange = (e) => {
        setAmount(e.target.value);
        setIsSetAmount(true);
        // setIsChanged(true);
    }

    const handleBlur = async (e) => {
        // dispatch(updateNotificationLimit(amount));
        // const data = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
        //     'credit': amount
        // });

        // responseCode(data, setResponseMessage, setErred);
        // if (data.error.error === "OK") {
        //     setIsSaved(true);
        //     // setIsChanged(false);
        //     setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        // }

        // console.log({ data });
        setIsSetAmount(false);
    }

    // const handleClick = async () => {

    //     dispatch(updateNotificationLimit(amount));
    //     const data = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
    //         'credit': amount
    //     });

    //     // if (responseCode(data.error.error !== "OK")) {
    //     //     setErred(true);
    //     //     setResponseMessage(<FormattedMessage id={data.error.error} />)
    //     // } else {
    //     //     setIsSaved(true);
    //     //     setIsChanged(false);
    //     //     setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
    //     // }

    //     responseCode(data, setResponseMessage, setErred);
    //     if (data.error.error === "OK") {
    //         setIsSaved(true);
    //         setIsChanged(false);
    //         setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
    //     }

    //     console.log({ data });
    // }

    const handleSwitchEmail = async (event) => {
        dispatch(updateNotificationEmail(event.target.checked));
        const data = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/email', {
            'email': event.target.checked ? user.email : ""
        })

        console.log(data);

        // if (responseCode(data.error.error !== "OK")) {
        //     setErred(true);
        //     setResponseMessage(<FormattedMessage id={data.error.error} />)
        // } else {
        //     setIsSaved(true);
        //     setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        // }

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        }
    };

    const handleSwitchSMS = async (event) => {
        dispatch(updateNotificationSMS(event.target.checked));
        const data = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/phone', {
            'phone': event.target.checked ? user.phone : ""
        });

        // if (responseCode(data.error.error !== "OK")) {
        //     setErred(true);
        //     setResponseMessage(<FormattedMessage id={data.error.error} />)
        // } else {
        //     setIsSaved(true);
        //     setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        // }

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />)
        }
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }

    if (!init) return null;
    return (
        <div className="col-lg-12 px-0 mt-10 notifications">
            <Form className="col-lg-12 px-0" onSubmit={e => { e.preventDefault(); }}>
                <FormLabel className="amount-label d-flex align-items-center"><FormattedMessage id="REMAINING_CREDIT" /> <span className="amount ml-2">{credit} {currency}</span></FormLabel>
                <Form.Row className="m-0 mt-5">
                    <Form.Group className="mr-5">
                        <FormControlLabel className="m-0"
                            control={
                                // <Form.Control className="ml-5" type="number" onChange={(e) => { setAmount(e.target.value); setIsChanged(true); }} value={amount} />}
                                <Form.Control className="ml-5" type="number" onChange={(e) => handleChange(e)} onBlur={handleBlur} value={amount} />}
                            // <Form.Control className="ml-5" type="number" onChange={(e) => handleChange(e)} value={amount} />}
                            label={<FormattedMessage id="ALERT_INFO_IN_CASE" />}
                            labelPlacement="start"
                        />
                        <Form.Label className="ml-5">{currency}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mr-15">
                        <FormControlLabel
                            checked={sendToEmail ? sendToEmail : false}
                            value="email"
                            control={<Switch color="primary" />}
                            onChange={handleSwitchEmail}
                            label={<FormattedMessage id="SEND_TO_EMAIL" />}
                            labelPlacement="start"
                        />
                    </Form.Group>
                    <Form.Group>
                        <FormControlLabel
                            checked={sendToSMS ? sendToSMS : false}
                            value="sms"
                            control={<Switch color="primary" />}
                            onChange={handleSwitchSMS}
                            label={<FormattedMessage id="SEND_VIA_SMS" />}
                            labelPlacement="start"
                        />
                    </Form.Group>
                </Form.Row>
                <FormLabel><FormattedMessage id="ALERT_INFO" /></FormLabel>
                {isChanged ? <p className="float-left text-error w-100 mt-5 mb-10"><FormattedMessage id="NOT_SAVED" /></p> : null}
                <Form.Group className="mt-10">
                    {/* <Button variant="contained" color="primary" onClick={handleClick}>
                        <FormattedMessage id="SAVE_BUTTON" />
                    </Button> */}
                    {
                        (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar my-5">
                                <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{responseMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                                </div>
                            </div>
                        </div>
                    }
                </Form.Group>
            </Form>
        </div>
    );
}
export default Notifications;