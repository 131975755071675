import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Registration from './Registration';
import Account from './Account';
import SmsHistory from './SmsHistory';
import Settings from './Settings';
import {
    getUser, updateGUID, getUserLang, getUserCredit, getUserCurrency, getUserAccountNumber, setSmsScenariosLink, updateUserCredit, updateNotificationLimit,
    setInvoicesLink, setNotificationsLink, getUnseenPayment, getOrderCode, smsActivationClicked, setSendSmsCampaignLink, getCampaignID, setCampaignDetail
} from "../../redux/actions/userActions";
import { isEmpty } from 'lodash'
import { LanguageSelectorDropdown } from '../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import { FormattedMessage } from "react-intl";
import { getLang, getData, useIsMountedRef } from '../../utils/helpers';
import SendSms from './SendSms';
import { useLocation } from "react-router-dom";
import { ComponentConst } from '../../utils/definitions';
import { useLang, setLanguage, getConfig } from "../../../src/_metronic/i18n";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
function Main() {
    const [value, setValue] = React.useState(0);

    const isMountedRef = useIsMountedRef();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setSmsScenariosLink(false));
        dispatch(setInvoicesLink(false));
        dispatch(setNotificationsLink(false));
        dispatch(setSendSmsCampaignLink(false));
        dispatch(getOrderCode(""));
        dispatch(getCampaignID(""));
        dispatch(setCampaignDetail(false));
    };

    const user = useSelector(state => state.user.user);
    const lang = getLang();
    // const user = {}
    const limit = useSelector(state => state.user.limit);
    const credit = useSelector(state => state.user.credit);
    const currency = useSelector(state => state.user.currency);
    const smsScenariosLink = useSelector(state => state.user.smsScenariosLink);
    const topupCreditLink = useSelector(state => state.user.topupCreditLink);
    const notificationsLink = useSelector(state => state.user.notificationsLink);
    const orderCode = useSelector(state => state.user.orderCode);
    const sendSmsCampaignLink = useSelector(state => state.user.sendSmsCampaignLink);
    const IsSmsActivationClicked = useSelector(state => state.user.IsSmsActivationClicked);
    const [init, setInit] = useState(false);

    let helpPageUrl = "https://www.axima-sms.cz/";

    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        const GUID = new URLSearchParams(location.search).get('eshop');
        if (isMountedRef.current) {
            if (isEmpty(user)) {
                dispatch(getUser());
            }
            dispatch(updateGUID(GUID));
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(user)) {
            dispatch(getUserCredit(Number(user.account["0"].credit).toFixed(2)));
            dispatch(getUserCurrency(user.account["0"].currency));
            dispatch(getUserAccountNumber(user.account["0"].number));
            dispatch(getUnseenPayment(user.unseen_payments));
            dispatch(setCampaignDetail(false));
        }
    }, [user])

    useEffect(() => {
        if (isMountedRef.current) {
            if (user.phone_verified && IsSmsActivationClicked) {
                setValue(2);
            } else if (user.phone_verified && !IsSmsActivationClicked) {
                setValue(3);
            }
        }
    }, [user.phone_verified, IsSmsActivationClicked]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (smsScenariosLink) {
                setValue(2);
            }
        }
    }, [smsScenariosLink]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (topupCreditLink) {
                setValue(3);
            }
        }
    }, [topupCreditLink]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (notificationsLink) {
                setValue(2);
            }
        }
    }, [notificationsLink]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (orderCode) {
                setValue(1);
            }
        }
    }, [orderCode]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (sendSmsCampaignLink) {
                setValue(0);
            }
        }
    }, [sendSmsCampaignLink]);


    useEffect(() => {
        if (!isEmpty(user)) {
            if (init) {
                const intervalCounter = setInterval(async () => {
                    const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
                    if (!isEmpty(resData.data.eshop) && resData.data.eshop.account.length > 0) {
                        // if(resData.data.eshop) {
                        dispatch(updateUserCredit(Number(resData.data.eshop.account["0"].credit).toFixed(2)));
                        console.log("interval");
                        console.log(Number(resData.data.eshop.account["0"].credit).toFixed(2));
                    } else {
                        console.error(resData.error.error);
                    }

                }, 100000);
                return () => clearInterval(intervalCounter);
            }
        }
    }, [user, init]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (!isEmpty(user)) {
                for (let i = 0; i < user.value.length; i++) {
                    const element = user.value[i];
                    if (element.type === ComponentConst.Notifications.LowCreditLimit) {
                        dispatch(updateNotificationLimit(element.value));
                    }
                }
            }
        }
    }, [user]);


    // useEffect(() => {
    //     if (isMountedRef.current) {
    //         if (!isEmpty(user)) {
    //             if (user.unseen_payments.length === 0){
    //                 console.log("unseen payments 0");
    //                 console.log(user.unseen_payments.length);
    //             } else {
    //                 console.log("unseen payments array dolu");
    //                 console.log(user.unseen_payments.length);
    //             }
    //         }
    //     }
    // }, [user]);

    const Template = () => {
        let template;

        if (user.phone_verified) {
            template = <div className="col-lg-12 px-0">
                <AppBar position="static" color="primary">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                        <Tab label={<FormattedMessage id="MENU_SMS_LIST" />} />
                        <Tab label={<FormattedMessage id="MENU_SMS" />} />
                        <Tab label={<FormattedMessage id="MENU_SETTINGS" />} />
                        <Tab label={<FormattedMessage id="MENU_ACCOUNT" />} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <SmsHistory />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SendSms />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Settings />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Account />
                </TabPanel>

            </div>
        } else {
            template = <div className="col-lg-12 px-0">
                <AppBar position="static" color="primary">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                        <Tab label={<FormattedMessage id="MENU_REGISTRATION" />} />
                        <Tab label={<FormattedMessage id="MENU_SMS_LIST" />} disabled />
                        <Tab label={<FormattedMessage id="MENU_SMS" />} disabled />
                        <Tab label={<FormattedMessage id="MENU_SETTINGS" />} disabled />
                        <Tab label={<FormattedMessage id="MENU_ACCOUNT" />} disabled />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Registration />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FormattedMessage id="MENU_SMS_LIST" />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FormattedMessage id="MENU_SMS" />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <FormattedMessage id="MENU_SETTINGS" />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <FormattedMessage id="MENU_ACCOUNT" />
                </TabPanel>
            </div>
        }
        return template;
    }

    return (
        <div>
            <div className="row mb-5">
                <div className="col-lg-12">
                    <h3 className="title h1-font-size d-none"><FormattedMessage id="NAME_OF_APPLICATION" /></h3>
                </div>
            </div>
            <div className="row mb-3">
                {/* <div className="col-lg-5">
                    <div></div>
                </div> */}
                <div className="col-lg-12 text-right header-info">
                    <div className="d-flex justify-content-end align-items-center">
                        <div><FormattedMessage id="STATE" />:
                            {user.email_verified ? <span className="is-register" style={{ color: "green" }}> <FormattedMessage id="STATE_REGISTERED" /> / </span> : <span className="is-register" style={{ color: "red" }}> <FormattedMessage id="STATE_UNREGISTERED" /> / </span>}
                            {user.phone_verified ? <span className="is-active" style={{ color: "green" }}><FormattedMessage id="STATE_ACTIVE" /></span> : <span className="is-active" style={{ color: "red" }}><FormattedMessage id="STATE_INACTIVE" /></span>}
                            <span className={credit < limit ? "ml-10 header-credit font-weight-bold text-danger" : "ml-10 header-credit"}><FormattedMessage id="CREDIT" />:  {credit} {currency}</span></div>
                        <div className="d-flex language-wrapper">
                            <div className="d-flex ml-10 language align-items-center"><div className="mr-3"><FormattedMessage id="LANGUAGE" /></div><LanguageSelectorDropdown /></div>
                            <div className="ml-10 help-button"><a href={`${((lang === "cs" || lang === "sk") && helpPageUrl + 'shoptet/') ||
                                ((lang === "hu") && helpPageUrl + 'shoptet-hu/') || ((lang === "en") && helpPageUrl + 'shoptet-en/')}`}
                                // ${lang === "HU" && "shoptet-hu/"}
                                // ${lang === "EN" && "shoptet-en/"}`}
                                target="_blank" className="link-primary-button"><FormattedMessage id="HELP_BUTTON" /></a></div></div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">{Template()}</div>
            </div>
        </div>
    )
}

export default Main;