import React from "react";
import {
  Dashboard
} from "../../_metronic/_partials";
import Main from "./Main";
import Footer from "./Footer";

export function DashboardPage() {
  return (
    // <Dashboard />
    <div className="content">
      <Main />
      <Footer />
    </div>
  );
}
