import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Button from '@material-ui/core/Button';
import { getLang, getData, postData, useIsMountedRef, responseCode } from '../../utils/helpers';
import { setSmsScenariosLink } from "../../redux/actions/userActions";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { injectIntl } from 'react-intl'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty } from 'lodash'

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

function BillingHistory({ intl: { formatMessage } }) {

    const currency = useSelector(state => state.user.currency);

    const classes = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const [data, setData] = useState([]);
    const [productData, setProductData] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [lastCount, setLastCount] = useState(0);
    const [searchURL, setSearchURL] = useState("");
    const [lastPageVisited, setLastPageVisited] = useState(false);
    const [date, setDate] = useState(new Date());

    // const classes2 = useToolbarStyles();

    const lang = getLang();

    let emptyRows = 0;
    const count = 100;

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        (async () => {
            // const resData = await getData('https://apishoptet.axima-sms.cz/sms/bulk/filter/list?count=' + count);
            const { startDateMoment, endDateMoment } = momentDates();
            // const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=${startDateMoment}&to=${endDateMoment}&count=${count}`);
            const resData = await getData(`https://apishoptet.axima-sms.cz/eshop/account/billing/list?from=${startDateMoment}&to=${endDateMoment}&count=${count}`);
            // const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');
            if (isMountedRef.current) {
                if (!isEmpty(resData.data)) {
                    // setData(resData.data.bulk);
                    setData(resData.data.billing);
                    setProductData(resData.data.product);
                    console.log({ resData });
                    emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
                    // setTotalCount(resData.data.count);
                    setTotalCount(resData.data.total_count);
                    setLastCount(count);
                    // setSearchURL(`https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=&to=&start=&count=${count}`)
                    // setSearchURL(`https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}`);
                    setSearchURL(`https://apishoptet.axima-sms.cz/eshop/account/billing/list?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}`);
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    const getDataWithCount = async (start) => {

        const { startDateMoment, endDateMoment } = momentDates();
        // const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=${startDateMoment}&to=${endDateMoment}&start=${start}&count=${count}`);
        const resData = await getData(`https://apishoptet.axima-sms.cz/eshop/account/billing/list?from=${startDateMoment}&to=${endDateMoment}&start=${start}&count=${count}`);
        if (!isEmpty(resData.data)) {
            // setData(data.concat(resData.data.bulk));
            setData(data.concat(resData.data.billing));
            // setProductData(data.concat(resData.data.product));
        } else {
            console.error(resData.error.error);
        }
    }

    const handleChangePage = (event, newPage) => {
        console.log({ newPage })
        console.log({ page })
        const pageCount = count / rowsPerPage;
        const totalPageCount = Math.floor(totalCount / rowsPerPage);
        switch (true) {
            case event.currentTarget.getAttribute("aria-label") === "next page":
                if (newPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((newPage * rowsPerPage) + 1) / count) * count);
                    (async () => { await getDataWithCount(lastCount); setPage(newPage); })();
                } else {
                    setPage(newPage);
                }
                if ((newPage === totalPageCount) && !lastPageVisited) {
                    setLastPageVisited(true);
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "last page":
                let ceilPage = Math.ceil((page + 1) / pageCount) * pageCount;

                if (ceilPage > totalPageCount && !lastPageVisited) {
                    ceilPage = totalPageCount;
                    setLastPageVisited(true);
                }
                if (ceilPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((ceilPage * rowsPerPage) + 1) / count) * count);
                    (async () => {
                        await getDataWithCount(lastCount); if (ceilPage > totalPageCount) {
                            setPage(totalPageCount);
                        } else {
                            setPage(ceilPage);
                        }
                    })();
                } else {
                    if (ceilPage >= totalPageCount || lastPageVisited) {
                        setPage(totalPageCount);
                    } else {
                        setPage((lastCount - count) / rowsPerPage);
                    }
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "previous page" || event.currentTarget.getAttribute("aria-label") === "first page":
                setPage(newPage);
            default:
                break;
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const momentDates = () => {
        const format = "YYYY-MM-DD HH:mm:ss";
        let startDateMoment = "";
        let endDateMoment = "";
        if (startDate !== null) {
            startDateMoment = moment(startDate).startOf("day").format(format);
        }
        if (endDate !== null) {
            endDateMoment = moment(endDate).endOf("day").format(format);
        }
        return { startDateMoment, endDateMoment }
    }

    const handleClick = async () => {

        const { startDateMoment, endDateMoment } = momentDates();
        // const url = `https://apishoptet.axima-sms.cz/sms/bulk/filter/list?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}`;
        const url = `https://apishoptet.axima-sms.cz/eshop/account/billing/list?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}`;
        if (url !== searchURL) {
            const resData = await getData(url);
            // const mergedData = mergeById(resData.data.sms, orderStatusData);
            if (!isEmpty(resData.data)) {
                // setTotalCount(resData.data.count);
                // setData(resData.data.bulk);
                setTotalCount(resData.data.total_count);
                setData(resData.data.billing);
                setProductData(resData.data.product);
                console.log("filter click");
                console.log({ resData });
                setLastCount(count);
                setSearchURL(url);
                setPage(0);
            } else {
                console.error(resData.error.error);
            }
        }
    }
    const downloadClick = async () => {

        const { startDateMoment, endDateMoment } = momentDates();
        const exportBulkData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/export?from=${startDateMoment}&to=${endDateMoment}&start=&count=${count}&lang=${lang}`, 'text');
        // const exportSMSData = await getData(`https://apishoptet.axima-sms.cz/sms/export?from=${startDateMoment}&to=${endDateMoment}&order_code=&msisdn=&search=${searchText !== null ? searchText : ""}&lang=${lang}`, 'text');
        // setExportData(exportSMSData);
        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(exportBulkData));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
        //document.body.removeChild(anchor);       
    }

    return (
        <div className="col-lg-12 px-0 mt-10">
            <div className="row mt-10 mb-10">
                <div className="col-lg-12 text-right">
                    <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate) }} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                        isOutsideRange={() => false}
                        showClearDates={true}
                        reopenPickerOnClearDates={true}
                        startDatePlaceholderText={formatMessage({ id: 'DATEPICKER_START_DATE' })}
                        endDatePlaceholderText={formatMessage({ id: 'DATEPICKER_END_DATE' })}

                    />
                    <Button onClick={handleClick} variant="contained" color="primary" className="ml-10">
                        <FormattedMessage id="DISPLAY_BUTTON" />
                    </Button>
                </div>
            </div>
            <div className="col-lg-12 px-0 mt-5 mb-10">
                <p><span className="font-weight-bold"><FormattedMessage id="BILLING_HISTORY_NOTICE" /></span> <FormattedMessage id="BILLING_HISTORY_NOTICE2" /></p>
            </div>
            <div className="col-lg-12 px-4 pt-4 pb-0 filtered-table">
                {/* <EnhancedTableToolbar /> */}
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell><FormattedMessage id="BILLING_HISTORY_POSTED_ITEM" /></TableCell>
                                <TableCell><FormattedMessage id="BILLING_HISTORY_POSTING_DATE" /></TableCell>
                                <TableCell><FormattedMessage id="BILLING_HISTORY_COUNT" /></TableCell>
                                <TableCell className="text-right"><FormattedMessage id="BILLING_HISTORY_UPWV" /></TableCell>
                                <TableCell className="text-right"><FormattedMessage id="BILLING_HISTORY_TPWV" /></TableCell>
                            </TableRow>
                        </TableHead>
                        {data.length === 0 ?
                            <TableBody>
                                <TableRow style={{ height: 80 }}>
                                    <TableCell colSpan={8} className="font-weight-bold"><FormattedMessage id="NOTHING_TO_SHOW" /></TableCell>
                                </TableRow>
                            </TableBody> :
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : data
                                ).map((row) => {
                                    console.log({ data });
                                    console.log({ productData });
                                    let filtered = Object.keys(productData).filter(item => item === row.id_product_rewrite).reduce((obj, item) => {
                                        obj[item] = productData[item];
                                        if (Object.keys(obj[item].name).filter(k => k === lang).length > 0) {
                                            const objl = Object.keys(obj[item].name).filter(k => k === lang).reduce((objL, ks) => {
                                                return obj[item].name[ks];
                                            }, {});
                                            return objl;
                                        } else {
                                            return obj[item].name["en"];
                                        }
                                    }, {});

                                    if (typeof filtered === 'object' || typeof filtered === 'undefined') {
                                        filtered = '';
                                    }

                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                {row.caption !== "" ? row.caption : filtered}</TableCell>
                                            <TableCell className="table-date">{(moment(row.datetime).format("YYYY-MM-DD HH:mm:ss"))}</TableCell>
                                            <TableCell>{row.count}</TableCell>
                                            <TableCell className="text-right">{row.unit_price !== null ?
                                                (row.unit_price.toString().indexOf("-") > -1 ? (<> {row.unit_price.toFixed(2) + " " + currency} </>) : (<>{"+" + row.unit_price.toFixed(2) + " " + currency}</>)) :
                                                null}</TableCell>
                                            <TableCell className="text-right">{(row.unit_price !== null && row.count !== null) ?
                                                (row.unit_price.toString().indexOf("-") > -1 ? (<>{(row.unit_price * row.count).toFixed(2) + " " + currency}</>) : (<>{"+" + (row.unit_price * row.count).toFixed(2) + " " + currency}</>)) :
                                                null}</TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={8} />
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                        <TableFooter>
                            <TableRow>
                                <TableCell className="download-export-wrapper">
                                    <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                            </Tooltip>
                                        }
                                    >
                                        <IconButton aria-label="download" onClick={downloadClick}>
                                            <GetAppIcon color="secondary" />
                                        </IconButton>
                                    </OverlayTrigger>
                                </TableCell>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    colSpan={9}
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default injectIntl(BillingHistory);