import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { emailVerified, phoneVerified, smsActivationClicked } from "../../redux/actions/userActions";
import { getLang, getData, postData, responseCode } from '../../utils/helpers';
import { FormattedMessage } from "react-intl";
import ErrorIcon from '@material-ui/icons/Error';
import { updateRegistrationData, updateUserCredit } from '../../redux/actions/userActions';
import { isEmpty } from 'lodash'

function SmsActivation() {

    const user = useSelector(s => s.user.user);
    const currency = useSelector(state => state.user.currency);
    const lang = getLang();

    const [isLoading, setLoading] = useState(false);
    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [counter, setCounter] = useState(10);
    const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        if (!counter) return;

        const intervalCounter = setInterval(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => clearInterval(intervalCounter);
    }, [counter]);

    useEffect(() => {
        if (isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [isErred, isSaved]);

    const handleReSendClick = async () => {
        setCounter(10);
        const data = await postData('https://apishoptet.axima-sms.cz/eshop/phone/resend', {
            'lang': lang
        });

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_RESEND_VER_SMS_SENT" />);
        }
    }

    const handleClick = async () => {
        setErred(false);
        setLoading(true);

        const data = await postData('https://apishoptet.axima-sms.cz/eshop/phone/verify', {
            'code': smsCode
        });

        setLoading(false);

        // if (data.error.error !== "OK") {

        // if (data.error.error === "PHONE_CODE_INVALID") {
        //     setErred(true);
        //     setResponseMessage("Phone validation code is not valid");
        // } else if (data.error.error === "OK") {
        //     setShowModal(true);
        // }

        // if (responseCode(data.error.error !== "OK")) {
        //     setErred(true);
        //     setResponseMessage(<FormattedMessage id={data.error.error} />)
        // } else {
        //     setShowModal(true);
        // }

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            if (currency === "CZK") {
                const creditLimit = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
                    'credit': 10
                });
                console.log("credit limit error" + creditLimit.error.error);
            } else if (currency === "HUF") {
                const creditLimit = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
                    'credit': 100
                });
                console.log("credit limit error" + creditLimit.error.error);
            } else if (currency === "EUR") {
                const creditLimit = await postData('https://apishoptet.axima-sms.cz/eshop/settings/lowcredit/credit', {
                    'credit': 0.2
                });
                console.log("credit limit error" + creditLimit.error.error);
            }
            setShowModal(true);
        }
    }
    const smsActivationHandleClick = () => {
        dispatch(phoneVerified(true));
        dispatch(smsActivationClicked(true));
    }

    const handleReRegister = () => {
        dispatch(updateRegistrationData("", ""));
        dispatch(emailVerified(false));
        dispatch(phoneVerified(false));
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }

    return (
        <div className="col-lg-12 px-0">
            <div className="registrate-breadcrumb">
                <div className="registrate"><FormattedMessage id="BREADCRUMBS_REGISTRATION" /></div>
                <div className="registrate email-verification "><FormattedMessage id="BREADCRUMBS_EMAIL_VERIFICATION" /></div>
                <div className="sms-verification active"><FormattedMessage id="BREADCRUMBS_SMS_VERIFICATION" /></div>
            </div>
            <div className="mt-10 mb-10">
                <FormattedMessage id="VERIFICAITON_INFO_SMS" />
            </div>

            <Form onSubmit={e => { e.preventDefault(); }}>
                <Form.Row>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <Form.Group controlId="formGridSmsCode">
                            <Form.Label><FormattedMessage id="SMS_CODE" /></Form.Label>
                            <FormattedMessage id="SMS_CODE" defaultMessage="Verification code">
                                {placeholder =>
                                    <Form.Control placeholder={placeholder} value={smsCode} onChange={e => { setSmsCode(e.target.value) }} />
                                }
                            </FormattedMessage>
                            {/* <Form.Control placeholder={<FormattedMessage id="SMS_CODE" />} value={smsCode} onChange={e => { setSmsCode(e.target.value) }} /> */}
                            {
                                counter ?
                                    <Form.Text className="text-muted text-center mt-5">
                                        <p><FormattedMessage id="RESEND_SMS" /> ( {counter} )</p>
                                    </Form.Text> :
                                    <Form.Text className="text-center mt-5">
                                        <a href="#!" onClick={handleReSendClick}>
                                            <FormattedMessage id="RESEND_SMS" /> ( {counter} )
                                    </a>
                                    </Form.Text>
                            }
                        </Form.Group>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <Button disabled={isLoading || (smsCode === '')} onClick={() => handleClick()} variant="contained" color="primary" className="mt-7">
                            <FormattedMessage id="BUTTON_ACTIVATE" />
                        </Button>
                    </div>
                </Form.Row>
                <Form.Row className="d-block mt-10">
                    <p><FormattedMessage id="BACK_BUTTON_SMS_SENTCENCE1" /> <span className="green-text font-weight-bold">{user.phone}</span></p>
                    <p><FormattedMessage id="BACK_BUTTON_SMS_SENTCENCE2" />
                        <a className="blue-link" onClick={() => handleReRegister()}> <FormattedMessage id="BACK_BUTTON_HYPERTEXT" /></a></p>
                </Form.Row>
            </Form>
            {
                (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                    <div className="snackbar">
                        <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                            {/* <ErrorIcon className="icon" /> */}
                            <span>{responseMessage}</span>
                            <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                        </div>
                    </div>
                </div>
            }
            <Modal
                show={showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="md"
                centered>
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" className="mx-auto">
                        <FormattedMessage id="POPUP_CONFIRMATION_CONGRATS" />
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <p>
                        <FormattedMessage id="POPUP_CONFIRMATION_TEXT" />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => smsActivationHandleClick()} variant="contained" color="primary"><FormattedMessage id="POPUP_CONFIRMATION_BUTTON" /></Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}
export default SmsActivation;