import * as actionTypes from "../actions/actionTypes"

const initialState = {
    isLoading: true,
    error: null,
    guid: '',
    user: {
    }
}

const userListReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LOADING:
            return {
                ...state,
                error: null,
                isLoading: true,
                user: {}
            }
        case actionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                user: action.payload
            }
        case actionTypes.GET_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case actionTypes.IS_EMAIL_VERIFIED:
            return {
                ...state,
                user: {
                    ...state.user,
                    ["email_verified"]: action.payload
                }
            }
        case actionTypes.IS_PHONE_VERIFIED:
            return {
                ...state,
                user: {
                    ...state.user,
                    ["phone_verified"]: action.payload
                }
            }
        case actionTypes.UPDATE_REGISTRATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    ["email"]: action.payload.email,
                    ["phone"]: action.payload.phone
                }
            }
        case actionTypes.UPDATE_GUID:
            return {
                ...state,
                guid: action.payload
            }
        case actionTypes.GET_USER_LANG:
            return {
                ...state,
                lang: action.payload
            }
        case actionTypes.UPDATE_USER_LANG:
            return {
                ...state,
                lang: action.payload
            }
        case actionTypes.GET_USER_CREDIT:
            return {
                ...state,
                credit: action.payload
            }
        case actionTypes.UPDATE_USER_CREDIT:
            return {
                ...state,
                credit: action.payload
            }
        case actionTypes.GET_USER_CURRENCY:
            return {
                ...state,
                currency: action.payload
            }
        case actionTypes.GET_USER_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumber: action.payload
            }
        case actionTypes.UPDATE_NOTIFICATION_LIMIT:
            return {
                ...state,
                limit: action.payload
            }
        case actionTypes.UPDATE_NOTIFICATION_EMAIL:
            return {
                ...state,
                sendToEmail: action.payload
            }
        case actionTypes.UPDATE_NOTIFICATION_SMS:
            return {
                ...state,
                sendToSMS: action.payload
            }
        case actionTypes.SET_SMS_SCENARIOS_LINK:
            return {
                ...state,
                smsScenariosLink: action.payload
            }
        case actionTypes.SET_TOPUP_CREDIT_LINK:
            return {
                ...state,
                topupCreditLink: action.payload
            }
        case actionTypes.SET_INVOICES_LINK:
            return {
                ...state,
                smsInvoicesLink: action.payload
            }
        case actionTypes.SET_NOTIFICATIONS_LINK:
            return {
                ...state,
                notificationsLink: action.payload
            }
        case actionTypes.GET_FILTERED_PHONE_NUMBERS:
            return {
                ...state,
                filteredPhoneNumbers: action.payload
            }
        case actionTypes.GET_CAMPAIGN_NAME:
            return {
                ...state,
                campaignName: action.payload
            }
        case actionTypes.GET_FILTERED_OBJECT:
            return {
                ...state,
                filteredObject: action.payload
            }
        case actionTypes.GET_UNSEEN_PAYMENT:
            return {
                ...state,
                unseenPayment: action.payload
            }
        case actionTypes.UPDATE_UNSEEN_PAYMENT:
            return {
                ...state,
                unseenPayment: action.payload
            }
        case actionTypes.CLEAR_UNSEEN_PAYMENT:
            return {
                ...state,
                unseenPayment: []
            }
        case actionTypes.SEEN_PAYMENT:
            return {
                ...state,
                seenPayment: action.payload
            }
        case actionTypes.GET_ORDER_CODE:
            return {
                ...state,
                orderCode: action.payload
            }
        case actionTypes.IS_SMS_ACTIVATION_CLICKED:
            return {
                ...state,
                IsSmsActivationClicked: action.payload
            }
        case actionTypes.SET_SEND_SMS_CAMPAIGN_LINK:
            return {
                ...state,
                sendSmsCampaignLink: action.payload
            }
        case actionTypes.GET_CAMPAIGN_ID:
            return {
                ...state,
                campaignID: action.payload
            }
        case actionTypes.SET_CAMPAIGN_DETAIL:
            return {
                ...state,
                campaignDetail: action.payload
            }
        default:
            return state;
    }
}

export default userListReducer;