import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from "react-intl";
import { getUserLang, updateUserLang } from '../redux/actions/userActions';

const GUID = (new URLSearchParams(window.location.search)).get('eshop');
/*
lang is not a function, it's constant that is 
evaluated using anonymous IIFE
*/
export function getLang() {
  let l = null;
  const ls = localStorage.getItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig");
  if (ls) {
    try {
      l = JSON.parse(ls).selectedLang;
    } catch (er) {
    }
  }
  return l ? l : (new URLSearchParams(window.location.search)).get('lang');
};
// const lang = (() => {
//   let l = null;
//   const ls = localStorage.getItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig");
//   if (ls) {
//     try {
//       l = JSON.parse(ls).selectedLang;
//     } catch (er) {
//     }
//   }
//   return l ? l : (new URLSearchParams(window.location.search)).get('lang');
// })();

export async function getData(path = '', contentType = '') {
  const res = await fetch(path, {
    'headers': {
      'X-eshop': GUID,
      'X-Lang': getLang(),
      'Content-Type': 'application/json'
    }
  })
  if (contentType === "") {
    return res.json();
  } else if (contentType === 'text') {
    return res.text();
  }
}

// export async function getData(url = '', guid, lang, contentType = '') {
//   const res = await fetch(url, {
//     'headers': {
//       'X-eshop': guid,
//       'X-Lang': lang,
//       'Content-Type': 'application/json'
//     }
//   })
//   if (contentType === "") {
//     return res.json();
//   } else if (contentType === 'text') {
//     return res.text();
//   }
// }

export async function postData(url = '', data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'X-eshop': GUID,
      'X-Lang': getLang(),
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  // console.log(data)
  return response.json(); // parses JSON response into native JavaScript objects
}

// export async function postData(url = '', data, guid, lang) {
//   // Default options are marked with *
//   const response = await fetch(url, {
//     method: 'POST', // *GET, POST, PUT, DELETE, etc.
//     mode: 'cors', // no-cors, *cors, same-origin
//     headers: {
//       'X-eshop': guid,
//       'X-Lang': lang,
//       'Content-Type': 'application/json'
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: JSON.stringify(data) // body data type must match "Content-Type" header
//   });
//   // console.log(data)
//   return response.json(); // parses JSON response into native JavaScript objects
// }


export function useIsMountedRef() {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => isMountedRef.current = false;
  });
  return isMountedRef;
}

export function mergeById(data, statusData) {
  return data.map(itm => ({
    ["orderStatus"]: statusData.find((item) => (item.id === itm.order_id_status)),
    ...itm
  }))
};

export function sortDataByDate(data) {
  data.sort(function (a, b) {
    return new Date(b.created) - new Date(a.created);
  });
  return data;
}

// export function responseCode(error) {
//   let result = error === "OK";
//   if (!result) {
//     // return error;
//     result = error;
//   }
//   return result;
// }

export const responseCode = (data, fn1, fn2 = () => { }) => {
  if (data.error.error !== "OK") {
    fn2(true);
    fn1(<FormattedMessage id={data.error.error} />);
  }
};