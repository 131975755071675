import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CreditTopup from './CreditTopup';
import Invoices from './Invoices';
import PriceList from './PriceList';
import { FormattedMessage } from "react-intl";
import { useIsMountedRef } from '../../utils/helpers';
import { setInvoicesLink, setNotificationsLink } from "../../redux/actions/userActions";
import BillingHistory from './BillingHistory';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function Account() {

    const [value, setValue] = React.useState(0);

    const smsInvoicesLink = useSelector(state => state.user.smsInvoicesLink);

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setInvoicesLink(false));
        dispatch(setNotificationsLink(false));
    };

    useEffect(() => {
        if (isMountedRef.current) {
            if (smsInvoicesLink) {
                setValue(1);
            }
        }
    }, [smsInvoicesLink]);

    return (
        <div className="row px-0">
            {/* <div className="col-lg-2"></div>
            <div className="col-lg-10"> */}
            <div className="col-lg-12">
                <AppBar position="static" color="primary" className="sub-tab account-sub-tab">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                        <Tab label={<FormattedMessage id="SUBMENU_SMS_ACCOUNT_TOPUP_CREDIT" />} />
                        <Tab label={<FormattedMessage id="SUBMENU_BILLING_HISTORY" />} />
                        <Tab label={<FormattedMessage id="SUBMENU_SMS_ACCOUNT_INVOICES" />} />
                        <Tab label={<FormattedMessage id="SUBMENU_SMS_ACCOUNT_PRICELIST" />} />
                    </Tabs>
                </AppBar>
            </div>
            <div className="col-lg-12">
                <TabPanel value={value} index={0}>
                    <CreditTopup />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <BillingHistory />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Invoices />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <PriceList />
                </TabPanel>
            </div>
        </div>
    );
}
export default Account;