import React, { useState, useEffect, createRef, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import clsx from 'clsx';
import Form from 'react-bootstrap/Form'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@material-ui/core/Button';
import SmsTextarea from './SmsTextarea';
import { getLang, getData, postData, responseCode, useIsMountedRef, mergeById } from '../../utils/helpers';
import { updateUserCredit, setTopupCreditLink, getOrderCode } from "../../redux/actions/userActions";
import { isEmpty } from 'lodash'
import ErrorIcon from '@material-ui/icons/Error';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { injectIntl } from 'react-intl'

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: "rgba(0, 0, 0, 0.87);",
                backgroundColor: "#FFF",
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 70%',
    },
}));

function SendSmsAutomatedSms({ intl: { formatMessage } }) {

    const classes = useStyles2();
    // const classes2 = useToolbarStyles();

    const user = useSelector(state => state.user.user);

    // const user = {
    //     billing: {
    //         country: {
    //             countryCode: "CZ"
    //         }
    //     }
    // }

    // const smsPriceData = {
    //     cz: 40.06,
    //     hu: 75,
    //     sk: 500
    // }

    // const senderResData = {
    //     data: {},
    //     error: {
    //         error: "error var"
    //     }
    // }

    const lang = getLang();
    const credit = useSelector(s => s.user.credit);
    const orderCode = useSelector(s => s.user.orderCode);

    let emptyRows = 0;
    const count = 100;

    // const credit = 3;

    const dispatch = useDispatch();

    const orderRef = createRef();
    const orderFilterRef = useRef(orderCode);
    orderFilterRef.current = orderCode

    const isMountedRef = useIsMountedRef();

    const [textValue, setTextValue] = useState('');
    const [textValueLength, setTextValueLength] = useState('');
    const [smsCount, setSmsCount] = useState(1);
    const [textChange, setTextChange] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [data, setData] = React.useState({});
    const [senderData, setSenderData] = React.useState([]);
    const [orderStatusData, setOrderStatusData] = React.useState([]);
    const [smsInfoData, setSmsInfoData] = React.useState({});
    const [smsPriceData, setSmsPriceData] = useState({});
    const [filterText, setFilterText] = React.useState("");
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [isOrderError, setOrderError] = useState(false);
    const [orderErrorMessage, setOrderErrorMessage] = useState('');
    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    // table
    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [totalCount, setTotalCount] = React.useState(0);
    const [lastCount, setLastCount] = React.useState(0);
    const [lastPageVisited, setLastPageVisited] = React.useState(false);

    // const EnhancedTableToolbar = (props) => {

    //     return (
    //         <Toolbar
    //             className={clsx(classes2.root, [classes2.highlight])}>
    //             <Typography variant="h6" id="tableTitle" component="div" className={classes2.title}>
    //                 <FormattedMessage id="SIMPLE_SMS_TABLE_FILTERED_NUMBERS_NAME" /></Typography>

    //         </Toolbar>
    //     );
    // };

    const childProps = {
        textValue,
        textValueLength,
        disabled: false,
        setTextValue,
        setTextValueLength,
        sendSms: true,
        textChange,
        setTextChange,
        smsCount,
        setSmsCount,
        hasDropdown: true,
        onChange: (e) => onChangeSmsTextArea(e)
    };

    useEffect(() => {
        if (isOrderError || isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setOrderError(false);
                setErred(false);
                setIsSaved(false);
            }, 10000);
            return () => clearTimeout(timeOut);
        }
    }, [isOrderError, isErred, isSaved]);

    useEffect(() => {
        // if (textValue) {
        //     setButtonDisabled(false);
        // } else {
        //     setButtonDisabled(true);
        // }
        // smsTextInfo();
        if (textValue !== "") {
            setButtonDisabled(false);
            let timeOut = setTimeout(() => {
                smsTextInfo();
            }, 2000);
            return () => clearTimeout(timeOut);
        } else {
            setSmsCount(1);
            setButtonDisabled(true);
            smsTextInfo();
        }
    }, [textValue]);

    useEffect(() => {
        if (textValue !== "") {
            if ((smsPriceData.cz || smsPriceData.hu || smsPriceData.sk) > credit) {
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        }
    }, [smsPriceData]);

    useEffect(() => {
        (async () => {
            const senderResData = await getData('https://apishoptet.axima-sms.cz/eshop/settings/sender/list');
            if (isMountedRef.current) {
                if (!isEmpty(senderResData.data)) {
                    const defaultSenders = senderResData.data.default;
                    const registeredSenders = senderResData.data.registered;
                    const mergedSenders = defaultSenders.concat(registeredSenders)
                    setSenderData(mergedSenders);
                    const selectedSender = senderResData.data.selected;
                    if (selectedSender && selectedSender !== "") {
                        setSelectedValue(selectedSender);
                    } else {
                        setSelectedValue(mergedSenders[2]);
                    }
                } else {
                    console.error(senderResData.error.error);
                }
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (orderCode) {
                const resData = await getData(`https://apishoptet.axima-sms.cz/order/${orderCode}`);
                const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');
                if (!isEmpty(statusData.data.status) && !isEmpty(resData.data.order)) {
                    setOrderStatusData(statusData.data.status);
                    setData(resData.data.order);
                    setFilterText(orderCode);
                    setIsFiltered(true);
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    const handleChangeValue = (event) => {
        setFilterText(event.target.value);
        dispatch(getOrderCode(event.target.value));
    };

    const handleFilter = async () => {
        if (orderRef.current.value === '') {
            setOrderError(true);
            setOrderErrorMessage(<FormattedMessage id="FILL_NUMBER_OF_ORDER" />);
        } else {
            const resData = await getData(`https://apishoptet.axima-sms.cz/order/${filterText}`);
            const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');
            if (!isEmpty(statusData.data.status) && !isEmpty(resData.data.order)) {
                setOrderStatusData(statusData.data.status);
                setData(resData.data.order);
                setIsFiltered(true);
                getSmsListData(filterText);
                console.log("filter");
                console.log({ resData });
                console.log({ orderCode });
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const onChangeSmsTextArea = (e) => {
        setTextValue(e.target.value);
    }

    const smsTextInfo = async () => {
        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/textinfo?text=${(encodeURIComponent(textValue))}&code=${filterText}`);
        if (!isEmpty(resData.data)) {
            setSmsInfoData(resData.data.textinfo);
            setSmsPriceData(resData.data.price);
            setSmsCount(resData.data.textinfo.parts.length);
            setTextValue(resData.data.textinfo.new_text);
            console.log(resData.data.price);
        } else {
            console.error(resData.error.error);
        }
    }

    const handleClick = async () => {
        const data = await postData(`https://apishoptet.axima-sms.cz/order/${filterText}/sms`, {
            'text': textValue,
            'sender': selectedValue
        });

        if (data.error.error !== "OK") {
            setErred(true);
            setResponseMessage(<FormattedMessage id={data.error.error} />)
        } else {
            setIsSaved(true);
            setTextChange(false);
            setTextValue('');
            setButtonDisabled(true);
            setResponseMessage(<FormattedMessage id="SIMPLESMS_SMS_SEND_OK" />);
            getSmsListData(orderCode);
            const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
            if (!isEmpty(resData.data.eshop)) {
                dispatch(updateUserCredit(Number(resData.data.eshop.account["0"].credit).toFixed(2)));
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const handleChange = async (e) => {
        setSelectedValue(e);
        console.log({ e });
        const resData = await postData('https://apishoptet.axima-sms.cz/eshop/settings/sender/select', {
            'sender': e
        });

        responseCode(resData, setResponseMessage, setErred);
        if (resData.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />);
        }
        console.log({ resData });
    }

    // table

    const getSmsListData = async (orderCodeParam) => {
        console.log("get sms list data");
        console.log({ orderCodeParam });
        console.log({ filterText });
        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/list?order_code=${orderCodeParam !== "" ? orderCodeParam : filterText}&count=${count}`);
        const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');
        if (isMountedRef.current) {
            if (!isEmpty(statusData.data) && !isEmpty(resData.data)) {
                setOrderStatusData(statusData.data.status);
                const mergedData = mergeById(resData.data.sms, statusData.data.status);
                setTableData(mergedData);
                console.log({ mergedData })
                emptyRows = rowsPerPage - Math.min(rowsPerPage, mergedData.length - page * rowsPerPage);
                setTotalCount(resData.data.count);
                setLastCount(count);
            } else {
                console.error(resData.error.error);
            }
        }
    }

    useEffect(() => {
        getSmsListData(orderCode);
    }, []);

    useEffect(() => {
        if (isFiltered) {
            const intervalCounter = setInterval(() => {
                console.log({ orderCode });
                console.log({ filterText });
                console.log("interval useeffect send sms list data");
                getSmsListData(orderFilterRef.current);
            }, 10000);
            return () => clearInterval(intervalCounter);
        }
    }, [isFiltered]);

    const downloadClick = async () => {
        const exportSMSData = await getData(`https://apishoptet.axima-sms.cz/sms/export?from=&to=&order_code=${orderCode !== "" ? orderCode : filterText}&msisdn=&search=&lang=${lang}`, 'text');
        // setExportData(exportSMSData);
        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(exportSMSData));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
        //document.body.removeChild(anchor);       
    }

    const switchIcons = (row) => {
        switch (true) {
            case row.state === "inserted" || row.state === "queued" || row.state === "sending":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_PENDING" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-pending-icon"></div>
                    </OverlayTrigger>);
            case row.state === "error":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_ERROR" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-error-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && row.delivery_state === "nostate":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_SENT" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-sent-nostate-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && row.delivery_state === "delivered":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_DELIVERED" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-delivered-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && (row.delivery_state === "expired" || row.delivery_state === "deleted"
                || row.delivery_state === "undeliverable" || row.delivery_state === "accepted" || row.delivery_state === "unknown"
                || row.delivery_state === "rejected" || row.delivery_state === "toerror" || row.delivery_state === "fromerror"):
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_UNDELIVERED" />
                            </Tooltip>}><div className="sms-status-icon sms-undeliverable-icon"></div>
                    </OverlayTrigger>);
            default:
                break;
        }
    }

    const handleChangePage = (event, newPage) => {
        console.log({ newPage })
        console.log({ page })
        const pageCount = count / rowsPerPage;
        const totalPageCount = Math.floor(totalCount / rowsPerPage);
        switch (true) {
            case event.currentTarget.getAttribute("aria-label") === "next page":
                if (newPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((newPage * rowsPerPage) + 1) / count) * count);
                    (async () => { await getDataWithCount(lastCount); setPage(newPage); })();
                } else {
                    setPage(newPage);
                }
                if ((newPage === totalPageCount) && !lastPageVisited) {
                    setLastPageVisited(true);
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "last page":
                let ceilPage = Math.ceil((page + 1) / pageCount) * pageCount;

                if (ceilPage > totalPageCount && !lastPageVisited) {
                    ceilPage = totalPageCount;
                    setLastPageVisited(true);
                }
                if (ceilPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((ceilPage * rowsPerPage) + 1) / count) * count);
                    (async () => {
                        await getDataWithCount(lastCount); if (ceilPage > totalPageCount) {
                            setPage(totalPageCount);
                        } else {
                            setPage(ceilPage);
                        }
                    })();
                } else {
                    if (ceilPage >= totalPageCount || lastPageVisited) {
                        setPage(totalPageCount);
                    } else {
                        setPage((lastCount - count) / rowsPerPage);
                    }
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "previous page" || event.currentTarget.getAttribute("aria-label") === "first page":
                setPage(newPage);
            default:
                break;
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getDataWithCount = async (start) => {

        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/list?from=&to=&order_code=${orderCode !== "" ? orderCode : filterText}&msisdn=&search=&start=${start}&count=${count}`);
        if (!isEmpty(resData.data.sms)) {
            const mergedData = mergeById(resData.data.sms, orderStatusData);
            setTableData(tableData.concat(mergedData));
        } else {
            console.error(resData.error.error);
        }
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }
    
    return (
        <div className="col-lg-12 px-0 mt-10 send-automated-sms">
            <div className="row mt-10 mb-0">
                <div className="col-lg-12 font-weight-bold">
                    <FormattedMessage id="SIMPLESMS_TEXT" /><br /><br />
                    <FormattedMessage id="SIMPLESMS_TEXT2" /> <a href={`${user.url}admin/orders-listing/`} className="blue-link" target="_blank"><FormattedMessage id="SIMPLESMS_TEXT2_LINK" /></a>
                </div>
                <div className="col-lg-12 mt-10">
                    <h4 className="gray-text"><FormattedMessage id="SIMPLESMS_HEADER" /></h4>
                </div>
                <Form className="col-lg-12 send-sms-forms" onSubmit={e => { e.preventDefault(); }}>
                    <Form.Row className="m-0 mt-5">
                        <Form.Group className="mr-5">
                            <FormControlLabel className="m-0"
                                control={
                                    <Form.Control ref={orderRef} className="ml-5" type="number" onChange={(e) => handleChangeValue(e)} value={filterText} />}
                                label={<FormattedMessage id="SIMPLESMS_TEXT3" />}
                                labelPlacement="start"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button onClick={handleFilter} variant="contained" color="primary">
                                <FormattedMessage id="SIMPLESMS_BUTTON" />
                            </Button>
                            <div className="d-flex justify-content-end">
                                {
                                    isOrderError && <div className="snackbar my-5 ml-5">
                                        <div className="snackbar-content">
                                            <ErrorIcon className="icon" />
                                            <span>{orderErrorMessage}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Form.Group>
                    </Form.Row>
                    <div className="col-lg-12 px-0 mt-5">
                        {isFiltered ?
                            ((isEmpty(data)) ? <p className="font-weight-bold text-danger"><FormattedMessage id="SIMPLESMS_TEXT_FAIL" /></p>
                                :
                                <>
                                    <div className="col-lg-12 px-0">
                                        <h4 className="green-text"><FormattedMessage id="SIMPLESMS_GREEN_ORDER_FOUND" /></h4>
                                    </div>
                                    <div className="row mx-0 mt-5">
                                        <div className="col-lg-3 col-md-3 px-0">
                                            <div className="order-info">
                                                <div className="row m-0 mb-10"><span className="mr-5"><FormattedMessage id="SIMPLESMS_GREEN_ORDER_NUMBER" /></span> <span className="green-text">{data.code}</span></div>
                                                {orderStatusData.filter(i => i.id === data.id_status).map((orderStatus) => (
                                                    <div key={orderStatus.id} className="row m-0 mb-10"><span className="mr-5"><FormattedMessage id="SIMPLESMS_GREEN_ORDER_STATUS" /></span> <div className="align-self-center order-status"><span className="status" style={{ background: orderStatus.backgroundColor, color: orderStatus.color }}>{orderStatus.name}</span></div></div>
                                                ))}
                                                <div className="row m-0"><span className="mr-5 align-self-start"><FormattedMessage id="SIMPLESMS_GREEN_CUSTOMER" /></span> <span className="person-info">{data.customer.email} <br /> {data.customer.msisdn}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-5 text-message-wrapper">
                                            <SmsTextarea {...childProps} />
                                            {
                                                (!isEmpty(smsPriceData)) &&
                                                    (smsPriceData.cz || smsPriceData.hu || smsPriceData.sk) > credit ?
                                                    <span className="text-danger"><FormattedMessage id="SIMPLESMS__LOW_CREDIT_INFO" /> <a className="blue-link" onClick={() => dispatch(setTopupCreditLink(true))}><FormattedMessage id="SIMPLESMS__LOW_CREDIT_INFO_LINK" /></a></span>
                                                    : null
                                            }
                                        </div>
                                        <div className="col-lg-4 col-md-4 preview-message-wrapper">
                                            {/* <SmsTextarea textValue={textValue} textValueLength={textValueLength} disabled={false}
                                                setTextValue={setTextValue} setTextValueLength={setTextValueLength} sendSms={true}
                                                textChange={textChange} setTextChange={setTextChange}
                                                smsCount={smsCount} setSmsCount={setSmsCount} onChange={(e) => onChangeSmsTextArea(e)} /> */}
                                            <div className="gray-text mb-5"><FormattedMessage id="SIMPLESMS_PREVIEW_TEXT" /></div>
                                            <div className="message-preview">{textValue}</div>

                                            {
                                                (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                                                    <div className="snackbar my-5">
                                                        <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                                            {/* <ErrorIcon className="icon" /> */}
                                                            <span>{responseMessage}</span>
                                                            <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (user.billing.country.countryCode === "HU") ? null :
                                                    <Form.Row className="m-0 mt-5 justify-content-end">
                                                        <Form.Group>
                                                            <Form.Label className="mr-5"><FormattedMessage id="SIMPLESMS_CHOOSING_ID" /></Form.Label>
                                                            <Dropdown className="" value={selectedValue} onSelect={(e) => handleChange(e)}>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {selectedValue}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {
                                                                        (senderData
                                                                        ).map((row, index) => (
                                                                            <Dropdown.Item key={index} eventKey={row}>{row}</Dropdown.Item>
                                                                        ))
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Form.Group>
                                                    </Form.Row>
                                            }
                                            <Form.Row className="m-0 mt-5 justify-content-end">
                                                <Form.Group>
                                                    <Button onClick={handleClick} variant="contained" color="primary" disabled={buttonDisabled}>
                                                        <FormattedMessage id="SMS_SIMPLE_SMS_SEND" />
                                                    </Button>
                                                </Form.Group>
                                            </Form.Row>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 px-4 pt-4 pb-0 filtered-table">
                                        {/* <EnhancedTableToolbar /> */}
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><FormattedMessage id="ORDER_NUMBER" /></TableCell>
                                                        <TableCell><FormattedMessage id="SENT_WHEN_STATUS_CHANGES" /></TableCell>
                                                        <TableCell><FormattedMessage id="DATE_OF_INCLUSION" /></TableCell>
                                                        <TableCell><FormattedMessage id="DELIVERY_DATE" /></TableCell>
                                                        <TableCell><FormattedMessage id="DESTINATION" /></TableCell>
                                                        <TableCell><FormattedMessage id="TEXT" /></TableCell>
                                                        <TableCell><FormattedMessage id="NUMBER_OF_SMS" /></TableCell>
                                                        <TableCell><FormattedMessage id="STATE2" /></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {tableData.length === 0 ?
                                                    <TableBody>
                                                        <TableRow style={{ height: 80 }}>
                                                            <TableCell colSpan={9} className="font-weight-bold"><FormattedMessage id="NOTHING_TO_SHOW" /></TableCell>
                                                        </TableRow>
                                                    </TableBody> :
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : tableData
                                                        ).map((row) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell>{row.order_code}</TableCell>
                                                                <TableCell><span className="status" style={{ background: row.orderStatus ? row.orderStatus.backgroundColor : null, color: row.orderStatus ? row.orderStatus.color : null }}>{row.orderStatus ? row.orderStatus.name : null}</span></TableCell>
                                                                <TableCell className="table-date">{row.created}</TableCell>
                                                                <TableCell className="table-date">{row.send_date}</TableCell>
                                                                <TableCell>{row.to}</TableCell>
                                                                <TableCell style={{ width: 250 }}>{row.text}</TableCell>
                                                                <TableCell>{row.parts_count}</TableCell>
                                                                <TableCell>
                                                                    {switchIcons(row)}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={9} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                }
                                                <TableFooter>
                                                    <TableRow>
                                                        <TableCell className="download-export-wrapper">
                                                            <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                                            <OverlayTrigger
                                                                key="top"
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <IconButton aria-label="download" onClick={downloadClick}>
                                                                    <GetAppIcon color="secondary" />
                                                                </IconButton>
                                                            </OverlayTrigger>
                                                        </TableCell>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                            colSpan={9}
                                                            count={totalCount}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: { 'aria-label': 'rows per page' },
                                                                native: true,
                                                            }}
                                                            onChangePage={handleChangePage}
                                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                            labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </>) : null
                        }
                    </div>
                </Form>
            </div>
        </div>
    );
}
export default injectIntl(SendSmsAutomatedSms);