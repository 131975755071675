import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import { emailVerified, phoneVerified } from "../../redux/actions/userActions";
import { getLang, postData, responseCode } from '../../utils/helpers';
import SmsActivation from './SmsActivation';
import { FormattedMessage } from "react-intl";
import ErrorIcon from '@material-ui/icons/Error';
import { updateRegistrationData } from '../../redux/actions/userActions';

function EmailActivation() {

    const user = useSelector(s => s.user.user);
    const lang = getLang();

    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [counter, setCounter] = useState(10);

    useEffect(() => {
        if (!counter) return;

        const intervalCounter = setInterval(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => clearInterval(intervalCounter);
    }, [counter]);

    useEffect(() => {
        if (isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [isErred, isSaved]);

    const handleReSendClick = async () => {
        setCounter(10);
        const data = await postData('https://apishoptet.axima-sms.cz/eshop/email/resend', {
            'lang': lang
        });

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_RESEND_VER_EMAIL_SENT" />);
        }
    }

    const handleClick = async () => {

        setErred(false);
        setLoading(true);

        const data = await postData('https://apishoptet.axima-sms.cz/eshop/email/verify', {
            'code': emailCode
        });

        setLoading(false);

        console.log(data.error.error)

        // if (data.error.error !== "OK") {
        // if (data.error.error === "EMAIL_CODE_INVALID") {
        //     setErred(true);
        //     setResponseMessage("Email validation code not valid");
        // } else if (data.error.error === "OK") {
        //     dispatch(emailVerified(true));
        // }

        // if (responseCode(data.error.error !== "OK")) {
        //     setErred(true);
        //     setResponseMessage(<FormattedMessage id={data.error.error} />)
        // } else {
        //     dispatch(emailVerified(true));
        // }

        responseCode(data, setResponseMessage, setErred);
        if (data.error.error === "OK") {
            dispatch(emailVerified(true));
            const data = await postData('https://apishoptet.axima-sms.cz/eshop/phone/resend', {
                'lang': lang
            });

            responseCode(data, setResponseMessage, setErred);
        }
    }

    const handleReRegister = () => {
        dispatch(updateRegistrationData("", ""));
        dispatch(emailVerified(false));
        dispatch(phoneVerified(false));
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }

    const Template = () => {
        let template;
        if (user && !user.email_verified) {
            template = <div className="col-lg-12 px-0">
                <div className="registrate-breadcrumb">
                    <div className="registrate"><FormattedMessage id="BREADCRUMBS_REGISTRATION" /></div>
                    <div className="registrate email-verification active"><FormattedMessage id="BREADCRUMBS_EMAIL_VERIFICATION" /></div>
                    <div className="sms-verification"><FormattedMessage id="BREADCRUMBS_SMS_VERIFICATION" /></div>
                </div>
                <div className="mt-10 mb-10">
                    <FormattedMessage id="VERIFICATITON_INFO_EMAIL" />
                </div>

                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Row>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <Form.Group controlId="formGridSmsCode">
                                <Form.Label><FormattedMessage id="EMAIL_CODE" /></Form.Label>
                                <FormattedMessage id="EMAIL_CODE" defaultMessage="Verification e-mail code">
                                    {placeholder =>
                                        <Form.Control placeholder={placeholder} value={emailCode} onChange={e => { setEmailCode(e.target.value) }} />
                                    }
                                </FormattedMessage>
                                {/* <Form.Control placeholder={<FormattedMessage id="EMAIL_CODE" />} value={emailCode} onChange={e => { setEmailCode(e.target.value) }} /> */}
                                {
                                    counter ?
                                        <Form.Text className="text-muted text-center mt-5">
                                            <p><FormattedMessage id="RESEND_ACTIVATION_EMAIL" /> ( {counter} )</p>
                                        </Form.Text> :
                                        <Form.Text className="text-center mt-5">
                                            <a href="#!" onClick={handleReSendClick}>
                                                <FormattedMessage id="RESEND_ACTIVATION_EMAIL" /> ( {counter} )
                                    </a>
                                        </Form.Text>
                                }
                            </Form.Group>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8">
                            <Button disabled={isLoading || (emailCode === '')} onClick={() => handleClick()} variant="contained" color="primary" className="mt-7">
                                <FormattedMessage id="BUTTON_CONFIRM_EMAIL" />
                            </Button>
                        </div>
                    </Form.Row>
                    <Form.Row className="d-block mt-10">
                        <p><FormattedMessage id="BACK_BUTTON_EMAIL_SENTCENCE1" /> <span className="green-text font-weight-bold">{user.email}</span></p>
                        <p><FormattedMessage id="BACK_BUTTON_EMAIL_SENTCENCE2" />
                            <a className="blue-link" onClick={() => handleReRegister()}> <FormattedMessage id="BACK_BUTTON_HYPERTEXT" /></a></p>
                    </Form.Row>
                </Form>
                {
                    (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar">
                            <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{responseMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        } else {
            template = <SmsActivation />
        }
        return template;
    }

    return (
        <div className="col-lg-12">{Template()}</div>
    );
}
export default EmailActivation;