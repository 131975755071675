import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import { FormattedMessage } from "react-intl";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { injectIntl } from 'react-intl'
import { getData, useIsMountedRef } from '../../utils/helpers';
import { isEmpty } from 'lodash'

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

function Invoices({ intl: { formatMessage } }) {

    const currency = useSelector(state => state.user.currency);
    const [data, setData] = React.useState([]);

    const isMountedRef = useIsMountedRef();

    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        (async () => {
            const resData = await getData('https://apishoptet.axima-sms.cz/eshop/account/invoice/list');
            if (isMountedRef.current) {
                if (!isEmpty(resData.data.invoice)) {
                    setData(resData.data.invoice);
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    const downloadClick = (e, number) => {
        let url = e.currentTarget.getAttribute("url");

        let oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
            // Once the file is downloaded, open a new window with the PDF
            // Remember to allow the POP-UPS in your browser
            let file = new Blob([oReq.response], {
                type: "application/pdf",
            });
            let anchor = document.createElement('a');
            // anchor.setAttribute("download", "export.pdf");
            anchor.setAttribute("download", `${number}.pdf`);
            const dataURL = window.URL.createObjectURL(file);
            anchor.setAttribute("href", dataURL);
            anchor.click();
            // Generate file download directly in the browser !
            // saveAs(file, "export.pdf");
        };

        oReq.send();
    }

    // const downloadClick = (e) => {
    //     let url = e.currentTarget.getAttribute("url");
    //     let url = "https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf";
    //     let anchor = document.createElement('a');
    //     anchor.setAttribute('href', url);
    //     anchor.setAttribute('download', 'export.pdf');
    //     anchor.click();
    //     console.log({ url })
    // }

    // const downloadClick = (e) => {
    // 	e.preventDefault();
    // 	let anchor = document.createElement("a");


    // 	let oReq = new XMLHttpRequest();
    // 	// The Endpoint of your server
    //     // let url = e.currentTarget.getAttribute("url");
    //     let url = "https://apishoptet.axima-sms.cz/files/invoice/5a0f7d8cdac98456a548cd4591e746314c23a11f5fca3459.pdf";

    // 	// Configure XMLHttpRequest
    //     oReq.open("GET", url, true);

    // 	// Important to use the blob response type
    //     oReq.responseType = "blob";

    // 	// When the file request finishes
    // 	// Is up to you, the configuration for error events etc.
    // 	oReq.onload = function () {
    // 		// Once the file is downloaded, open a new window with the PDF
    // 		// Remember to allow the POP-UPS in your browser
    // 		let file = new Blob([oReq.response], {
    // 			type: "application/pdf",
    // 		});
    // 		anchor.setAttribute("download", "export.pdf");
    // 		const dataURL = window.URL.createObjectURL(file);
    //         anchor.setAttribute("href", dataURL);
    // 		anchor.click();
    // 		// Generate file download directly in the browser !
    // 		// saveAs(file, "export.pdf");
    // 	};

    // 	oReq.send();
    // };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="col-lg-12 px-0 mt-10">
            {/* <h2 className="mb-5"><FormattedMessage id="LIST_INVOICES" /></h2> */}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell><FormattedMessage id="INVOICE_NUMBER" /></TableCell>
                            <TableCell><FormattedMessage id="DATE" /></TableCell>
                            <TableCell><FormattedMessage id="AMOUNT_WITHOUT_VAT" /></TableCell>
                            <TableCell><FormattedMessage id="AMOUNT_WITH_VAT" /></TableCell>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data
                        ).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.number}
                                </TableCell>
                                <TableCell className="table-date">
                                    {row.date}
                                </TableCell>
                                <TableCell>{row.price.toFixed(2) + " " + currency}</TableCell>
                                <TableCell>{row.price_vat.toFixed(2) + " " + currency}</TableCell>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="TOOLTIP_SHOW_INVOICE" />
                                            </Tooltip>
                                        }
                                    >
                                        <a aria-label="view" href={row.url} target="_blank">
                                            <VisibilityIcon />
                                        </a>
                                    </OverlayTrigger>
                                </TableCell>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="TOOLTIP_DOWNLOAD_INVOICE" />
                                            </Tooltip>
                                        }
                                    >
                                        <a aria-label="view" url={row.url} className="download-link" onClick={(e) => downloadClick(e, row.number)}>
                                            <GetAppIcon />
                                        </a>
                                    </OverlayTrigger>
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={8} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                colSpan={8}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}
export default injectIntl(Invoices);