import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../utils/tablePagination';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Button from '@material-ui/core/Button';
import { getLang, getData, postData, useIsMountedRef, responseCode } from '../../utils/helpers';
import { FormattedMessage } from "react-intl";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'
import { setCampaignDetail } from "../../redux/actions/userActions";

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

function CampaignDetail({ intl: { formatMessage } }) {
    const classes = useStyles2();

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    const lang = getLang();
    const currency = useSelector(state => state.user.currency);

    let emptyRows = 0;
    const count = 100;

    const campaignID = useSelector(state => state.user.campaignID);
    const [detailData, setDetailData] = useState({});
    const [detailBulkInfoData, setDetailBulkInfoData] = useState({});
    const [searchText, setSearchText] = useState("");
    const [searchURL, setSearchURL] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [lastCount, setLastCount] = useState(0);
    const [lastPageVisited, setLastPageVisited] = useState(false);


    useEffect(() => {
        (async () => {
            const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/sms/info?id=${campaignID}`);
            if (isMountedRef.current) {
                if (!isEmpty(resData.data)) {
                    setDetailData(resData.data.bulk);
                    setDetailBulkInfoData(resData.data.bulk_info);
                    console.log(resData.data.bulk);
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/sms/list?id=${campaignID}&count=${count}`);
            if (isMountedRef.current) {
                if (!isEmpty(resData.data)) {
                    setData(resData.data.sms);
                    console.log(resData.data.sms);
                    emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
                    setTotalCount(resData.data.total_count);
                    setLastCount(count);
                    setSearchURL(`https://apishoptet.axima-sms.cz/sms/bulk/sms/list?id=${campaignID}&search=&start=&count=${count}`)
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    const getDataWithCount = async (start) => {

        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/sms/list?id=${campaignID}&search=${searchText !== null ? searchText : ""}&start=${start}&count=${count}`);
        if (!isEmpty(resData.data)) {
            setData(data.concat(resData.data.sms));
        } else {
            console.error(resData.error.error);
        }
    }

    const handleChangePage = (event, newPage) => {
        console.log({ newPage })
        console.log({ page })
        const pageCount = count / rowsPerPage;
        const totalPageCount = Math.floor(totalCount / rowsPerPage);
        switch (true) {
            case event.currentTarget.getAttribute("aria-label") === "next page":
                if (newPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((newPage * rowsPerPage) + 1) / count) * count);
                    (async () => { await getDataWithCount(lastCount); setPage(newPage); })();
                } else {
                    setPage(newPage);
                }
                if ((newPage === totalPageCount) && !lastPageVisited) {
                    setLastPageVisited(true);
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "last page":
                let ceilPage = Math.ceil((page + 1) / pageCount) * pageCount;

                if (ceilPage > totalPageCount && !lastPageVisited) {
                    ceilPage = totalPageCount;
                    setLastPageVisited(true);
                }
                if (ceilPage * rowsPerPage >= lastCount) {
                    setLastCount(Math.ceil(((ceilPage * rowsPerPage) + 1) / count) * count);
                    (async () => {
                        await getDataWithCount(lastCount); if (ceilPage > totalPageCount) {
                            setPage(totalPageCount);
                        } else {
                            setPage(ceilPage);
                        }
                    })();
                } else {
                    if (ceilPage >= totalPageCount || lastPageVisited) {
                        setPage(totalPageCount);
                    } else {
                        setPage((lastCount - count) / rowsPerPage);
                    }
                }
                break;
            case event.currentTarget.getAttribute("aria-label") === "previous page" || event.currentTarget.getAttribute("aria-label") === "first page":
                setPage(newPage);
            default:
                break;
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeValue = (event) => {
        setSearchText(event.target.value);
    };

    const handleKeyDown = async (event) => {
        // if (event.key === 'Enter') {
        if (event.keyCode === 13) {
            const url = `https://apishoptet.axima-sms.cz/sms/bulk/sms/list?id=${campaignID}&search=${searchText !== null ? searchText : ""}&start=&count=${count}`;
            if (url !== searchURL) {
                const resData = await getData(url);
                if (!isEmpty(resData.data)) {
                    setTotalCount(resData.data.total_count);
                    setData(resData.data.sms);
                    setLastCount(count);
                    setSearchURL(url);
                    setPage(0);
                } else {
                    console.error(resData.error.error);
                }
            }
        }
    }

    const handleClick = async () => {

        const url = `https://apishoptet.axima-sms.cz/sms/bulk/sms/list?id=${campaignID}&search=${searchText !== null ? searchText : ""}&start=&count=${count}`;
        if (url !== searchURL) {
            const resData = await getData(url);
            if (!isEmpty(resData.data)) {
                setTotalCount(resData.data.total_count);
                setData(resData.data.sms);
                setLastCount(count);
                setSearchURL(url);
                setPage(0);
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const handleClose = () => {
        dispatch(setCampaignDetail(false));
    }

    const downloadClick = async () => {

        const exportBulk = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/sms/export?id=${campaignID}&lang=${lang}`, 'text');
        let anchor = document.createElement('a');
        anchor.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(exportBulk));
        anchor.setAttribute('download', 'export.csv');
        //document.body.appendChild(anchor); 
        anchor.click();
    }

    const switchIcons = (row) => {
        switch (true) {
            case row.state === "inserted" || row.state === "queued" || row.state === "sending":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_PENDING" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-pending-icon"></div>
                    </OverlayTrigger>);
            case row.state === "error":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_ERROR" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-error-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && row.delivery_state === "nostate":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_SENT" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-sent-nostate-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && row.delivery_state === "delivered":
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_DELIVERED" />
                            </Tooltip>
                        }><div className="sms-status-icon sms-delivered-icon"></div>
                    </OverlayTrigger>);
            case row.state === "sent" && (row.delivery_state === "expired" || row.delivery_state === "deleted"
                || row.delivery_state === "undeliverable" || row.delivery_state === "accepted" || row.delivery_state === "unknown"
                || row.delivery_state === "rejected" || row.delivery_state === "toerror" || row.delivery_state === "fromerror"):
                return (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="SMS_UNDELIVERED" />
                            </Tooltip>}><div className="sms-status-icon sms-undeliverable-icon"></div>
                    </OverlayTrigger>);
            default:
                break;
        }
    }

    return (
        <div className="col-lg-12 px-0 mt-10">
            <div className="row">
                <div className="col-lg-6">
                    <p className="mb-1"><FormattedMessage id="CAMPAIGN_DETAIL_CAMPAIGN_NAME" /> {detailData.caption}</p>
                    <p className="mb-1"><FormattedMessage id="CAMPAIGN_DETAIL_SMS_PARTS" /> {detailBulkInfoData.sms_count}</p>
                    <p className="mb-1"><FormattedMessage id="CAMPAIGN_DETAIL_SMS_MESSAGES" /> {detailData.count}</p>
                    <p className="mb-1"><FormattedMessage id="CAMPAIGN_DETAIL_SMS_IDENTIFIER" /> {detailData.from}</p>
                    <p className="mb-1"><FormattedMessage id="CAMPAIGN_DETAIL_CAMPAIGN_PRICE" /> {detailBulkInfoData.price !== null ? (Number(detailBulkInfoData.price).toFixed(2)) + " " + currency : null}</p>
                </div>
                <div className="col-lg-6">
                    <div className="col-lg-12 text-right">
                        <Button variant="contained" color="primary" className="close-button" onClick={handleClose}><FormattedMessage id="CAMPAIGN_DETAIL_CLOSE_BUTTON" /></Button>
                    </div>
                    <div className="col-lg-12 mt-10 mb-10 text-right">
                        <div className="filter-input-wrapper">
                            <FormattedMessage id="SEARCH_BUTTON" defaultMessage="Search">
                                {placeholder =>
                                    <input placeholder={placeholder} type="text" value={searchText} onChange={handleChangeValue} onKeyDown={handleKeyDown} className="filter-input" />
                                }
                            </FormattedMessage>
                        </div>
                        <Button onClick={handleClick} variant="contained" color="primary" className="ml-10">
                            <FormattedMessage id="DISPLAY_BUTTON" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 px-4 pt-4 pb-0 filtered-table">
                {/* <EnhancedTableToolbar /> */}
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell><FormattedMessage id="DESTINATION" /></TableCell>
                                <TableCell><FormattedMessage id="DATE_OF_INCLUSION" /></TableCell>
                                <TableCell><FormattedMessage id="DELIVERY_DATE" /></TableCell>
                                <TableCell><FormattedMessage id="TEXT" /></TableCell>
                                <TableCell><FormattedMessage id="NUMBER_OF_SMS" /></TableCell>
                                <TableCell><FormattedMessage id="STATE2" /></TableCell>
                            </TableRow>
                        </TableHead>
                        {data.length === 0 ?
                            <TableBody>
                                <TableRow style={{ height: 80 }}>
                                    <TableCell colSpan={6} className="font-weight-bold"><FormattedMessage id="NOTHING_TO_SHOW" /></TableCell>
                                </TableRow>
                            </TableBody> :
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : data
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.to}</TableCell>
                                        <TableCell className="table-date">{row.created}</TableCell>
                                        <TableCell className="table-date">{row.delivered}</TableCell>
                                        <TableCell style={{ width: 600 }}>{row.text}</TableCell>
                                        <TableCell>{row.parts_count}</TableCell>
                                        <TableCell>
                                            {switchIcons(row)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                        <TableFooter>
                            <TableRow>
                                <TableCell className="download-export-wrapper">
                                    <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="TOOLTIP_DOWNLOAD_EXPORT" />
                                            </Tooltip>
                                        }
                                    >
                                        <IconButton aria-label="download" onClick={downloadClick}>
                                            <GetAppIcon color="secondary" />
                                        </IconButton>
                                    </OverlayTrigger>
                                </TableCell>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    colSpan={9}
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    labelRowsPerPage={formatMessage({ id: 'ROWS_ON_PAGE' })}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default injectIntl(CampaignDetail);