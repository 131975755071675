import React, { useEffect, useState, createRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getLang, useIsMountedRef } from '../../utils/helpers';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from 'react-bootstrap/Alert'
import Button from '@material-ui/core/Button'
import { FormattedMessage, injectIntl } from "react-intl";
import { setInvoicesLink } from "../../redux/actions/userActions";
import { ComponentConst } from '../../utils/definitions';
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty } from 'lodash'
import PaymentResult from './PaymentResult';
import Fade from '@material-ui/core/Fade';
import { CSSTransition } from 'react-transition-group';

function CreditTopup({ intl: { formatMessage } }) {

    const user = useSelector(state => state.user.user);
    const guid = useSelector(s => s.user.guid);
    const lang = getLang();

    // const user = {
    //     billing: {
    //         country: {
    //             countryCode: "HU"
    //         }
    //     },
    //     unseen_payments: []
    // }

    const currency = useSelector(state => state.user.currency);
    const accountNumber = useSelector(state => state.user.accountNumber);
    const unseenPayment = useSelector(state => state.user.unseenPayment);

    const paymentAmountRef = createRef();

    const [amount, setAmount] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [imgURL, setImgURL] = useState('');

    const [paymentAmountError, setPaymentAmountError] = useState(false);
    const [paymentAmountErrorMessage, setPaymentAmountErrorMessage] = useState("");

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    const queryQRCode = (amount) => {
        let query = new URLSearchParams();
        if (document.getElementById("iban-hu") !== null || document.getElementById("iban-cz") !== null || document.getElementById("iban-sk") !== null) {
            // query.append('iban', document.getElementById((user.billing.country.countryCode === "HU" && "iban-hu") || (user.billing.country.countryCode === "CZ" && "iban-cz")
            //     || (user.billing.country.countryCode === "SK" && "iban-sk")).innerText);
            query.append('iban', document.getElementById((currency === "HUF" && "iban-hu") || (currency === "CZK" && "iban-cz")
                || (currency === "EUR" && "iban-sk")).innerText);
            query.append('currency', currency);
            query.append('msg', '');
            query.append('amount', amount);
            query.append('vs', accountNumber);
            // query.append('includevs', document.querySelector("#includevs").checked + 0);
            // query.append('includevs', user.billing.country.countryCode === "CZ" ? 1 : 0);
            query.append('includevs', currency === "CZK" ? 1 : 0);
            setImgURL("https://apishoptet.axima-sms.cz/qr?" + query.toString());
        }
        // console.log(window.parent === window ? "web" : "admin");
        // console.log(window.location.host === 'shoptet.axima-sms.cz' ? 'release' : 'dev');
    }

    useEffect(() => {
        if (isMountedRef.current) {
            // if (user.billing.country.countryCode === "CZ") {
            if (currency === "CZK") {
                setAmount(1000);
                queryQRCode(1000);
                setPaymentAmount(200);
                // } else if (user.billing.country.countryCode === "HU") {
            } else if (currency === "HUF") {
                setAmount(14000);
                queryQRCode(14000);
                setPaymentAmount(2500);
                // } else if (user.billing.country.countryCode === "SK") {
            } else if (currency === "EUR") {
                setAmount(40);
                queryQRCode(40);
                setPaymentAmount(5);
            }
        }
    }, []);

    useEffect(() => {
        if (paymentAmountError) {
            let timeOut = setTimeout(() => {
                setPaymentAmountError(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [paymentAmountError]);

    useEffect(() => {
        if (paymentAmount) {
            let timeOut = setTimeout(() => {
                checkpaymentAmount();
            }, 1000);
            return () => clearTimeout(timeOut);
        }
    }, [paymentAmount]);

    useEffect(() => {
        let timeOut = setTimeout(() => {
            queryQRCode(amount);
        }, 2000);
        return () => clearTimeout(timeOut);
    }, [amount]);

    const changeAmount = (e) => {
        setAmount(e.target.value);
    }

    const handleBlur = (e) => {
        queryQRCode(amount);
    }

    const changePaymentAmount = (e) => {
        setPaymentAmount(e.target.value);
    }

    const checkpaymentAmount = () => {
        // let currentAmount = paymentAmountRef.current.value;
        let currentAmount = paymentAmount;
        let countryCode = user.billing.country.countryCode;
        let errorMessage = formatMessage({ id: 'SNACKBAR_NO_MIN_TOPUP' }).replace("[currency]", currency);
        const czMinAmount = 200;
        const huMinAmount = 2500;
        const skMinAmount = 5;

        // if (countryCode === "CZ" && (currentAmount === '' || paymentAmount < czMinAmount)) {
        if (currency === "CZK" && (currentAmount === '' || paymentAmount < czMinAmount)) {
            setPaymentAmountError(true);
            errorMessage = errorMessage.replace("[minAmount]", czMinAmount);
            setPaymentAmountErrorMessage(errorMessage);
            return false;
            // } else if (countryCode === "HU" && (currentAmount === '' || paymentAmount < huMinAmount)) {
        } else if (currency === "HUF" && (currentAmount === '' || paymentAmount < huMinAmount)) {
            setPaymentAmountError(true);
            errorMessage = errorMessage.replace("[minAmount]", huMinAmount);
            setPaymentAmountErrorMessage(errorMessage);
            return false;
            // } else if (countryCode === "SK" && (currentAmount === '' || paymentAmount < skMinAmount)) {
        } else if (currency === "EUR" && (currentAmount === '' || paymentAmount < skMinAmount)) {
            setPaymentAmountError(true);
            errorMessage = errorMessage.replace("[minAmount]", skMinAmount);
            setPaymentAmountErrorMessage(errorMessage);
            return false;
        }
        return true;
    }

    const handlePaymentClick = async (e) => {

        if (checkpaymentAmount()) {
            let url = new URLSearchParams();
            url.append('currency', currency);
            url.append('language', lang);
            url.append('eshop', guid);
            url.append('amount', paymentAmount);
            url.append('return', window.parent === window ? "web" : "admin");
            url.append('source', window.location.host === 'shoptet.axima-sms.cz' ? 'release' : 'dev');

            let host = 'https://apishoptet.axima-sms.cz/eshop/payment/make/card?';
            if(window.location.host !== 'shoptet.axima-sms.cz') host = 'https://apishoptetdev.axima-sms.cz/eshop/payment/make/card?';
            e.target.href = host + url.toString();
        }

    }

    const handleSnackbarClose = () => {
        setPaymentAmountError(false);
    }

    const Template = () => {
        let template;
        // if (!isEmpty(user) && (unseenPayment.length === 0)) {
        if (!isEmpty(user) && isEmpty(unseenPayment)) {
            template = <div className="col-lg-12 px-0 mt-10">
                {/* <h3 className="mb-5"><FormattedMessage id="TOP_UP_CREDIT" /></h3> */}
                <h5><FormattedMessage id="SELECT_PAYMENT_METHOD" /></h5>
                <div className="topup-tab mt-10">
                    {/* <Tab.Container id="controlled-tab-example" defaultActiveKey="visa"> */}
                    <Tab.Container id="controlled-tab-example" defaultActiveKey={currency === "CZK" ? "visa" : "bank-transfer"}>
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="visa">
                                    <div className="nav-link-item">
                                        <img src="../media/logos/visa-master.svg" />
                                        <p className="mt-3 mb-0"><FormattedMessage id="TOPUP_WITH_CREDIT_CARD" /></p>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="bank-transfer">
                                    <div className="nav-link-item">
                                        <img src="../media/logos/bank-transfer.svg" />
                                        <p className="mt-3 mb-0"><FormattedMessage id="TOP_UP_BY_BANK_TRANSFER" /></p>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="visa">
                                {/* <Alert variant="success"> */}
                                <Alert variant="warning">
                                    <Alert.Heading><FormattedMessage id="INSTANT_PAYMENT" /></Alert.Heading>
                                    <p className="mb-0">
                                        <FormattedMessage id="INSTANT_PAYMENT_TEXT" /></p>
                                </Alert>
                                <div className="row">
                                    <Form className="col-lg-12" onSubmit={e => { e.preventDefault(); }}>
                                        <h5 className="mt-5 mb-5"><FormattedMessage id="SELECT_THE_AMOUNT_TO_TOP_UP" /></h5>
                                        <Form.Row className="m-0">
                                            <div className="col-lg-4 col-md-5 col-sm-6 p-0">
                                                <Form.Group controlId="visaAmount" className="mr-5">
                                                    <FormControlLabel className="m-0 w-100"
                                                        control={
                                                            <Form.Control className="visa-amount-form-group" ref={paymentAmountRef} type="number" onChange={changePaymentAmount} value={paymentAmount} />}
                                                        label={<span className="font-weight-bold ml-5">{currency}</span>}
                                                        labelPlacement="end"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-8 col-md-7 col-sm-6 px-0">
                                                <Form.Group>
                                                    {(currency === "CZK") ?
                                                        <>
                                                            <a target="_top" className="link-primary-button" onClick={(e) => handlePaymentClick(e)}><FormattedMessage id="TOP_UP_CREDIT_BUTTON" /></a>
                                                        </> :
                                                        <p className="mt-2 text-danger font-weight-bold"><FormattedMessage id="TEMPORARY_CREDIT_CARD_CLOSED" /></p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            {/* <CSSTransition
                                                in={paymentAmountError}
                                                timeout={300}
                                                classNames="alert"
                                                unmountOnExit
                                            > */}
                                            <div className="d-flex justify-content-end w-100">
                                                {
                                                    paymentAmountError && <div className="snackbar my-5">
                                                        <div className="snackbar-content">
                                                            {/* <ErrorIcon className="icon" /> */}
                                                            <span>{paymentAmountErrorMessage}</span>
                                                            <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {/* </CSSTransition> */}
                                        </Form.Row>
                                        <Form.Row className="d-block">
                                            {/* {(user.billing.country.countryCode === "CZ") ? */}
                                            {/* {(currency === "CZK") ?
                                                <>
                                                    <img src="../media/logos/visa.png" className="visa-img" />
                                                    <img src="../media/logos/master.png" className="master-img" />
                                                </> : 
                                                null
                                            } */}
                                        </Form.Row>
                                    </Form>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="bank-transfer">
                                {/* <Alert variant="success"> */}
                                <Alert variant="warning">
                                    <Alert.Heading><FormattedMessage id="LONG_PAYMENT" /></Alert.Heading>
                                    <p className="mb-0">
                                        <FormattedMessage id="LONG_PAYMENT_TEXT" /></p>
                                </Alert>
                                <div className="row">
                                    <Form className="col-lg-12" onSubmit={e => { e.preventDefault(); }}>
                                        <h5 className="mt-5 mb-5"><FormattedMessage id="SELECT_THE_AMOUNT_TO_TOP_UP" /></h5>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <Form.Group controlId="bankTransferAmount">
                                                    <FormControlLabel className="m-0 w-100"
                                                        control={
                                                            <Form.Control className="qr-amount-form-group" type="number" onChange={changeAmount} onBlur={handleBlur} value={amount} />}
                                                        label={<span className="font-weight-bold ml-5">{currency}</span>}
                                                        labelPlacement="end"
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 qr-img pr-0">
                                                <img id="result" src={imgURL} width="200" height="200" />
                                            </div>
                                            <div className="col-lg-6 col-md-5">
                                                <p className="mt-2"><FormattedMessage id="QR_INFO" /> {(currency === "HUF" && <span className="font-weight-bold">Raiffeisen Bank</span>) ||
                                                    (currency === "CZK" && <span className="font-weight-bold">Fio banka</span>) ||
                                                    (currency === "EUR" && <span className="font-weight-bold">Fio Banka</span>)}</p>
                                                <div className="col-lg-12 px-0"><FormattedMessage id="TOPUP_CREDIT_BANK_PAYMENT_INFO" /> <a className="blue-link" onClick={() => dispatch(setInvoicesLink(true))}><FormattedMessage id="TOPUP_CREDIT_BANK_PAYMENT_INFO_links" /></a></div>
                                                <div className="row mt-5">
                                                    <div className="col-lg-12">
                                                        {(currency === "HUF" &&
                                                            <div className="font-weight-bold"><FormattedMessage id="ACCOUNT_NUMBER" />: {ComponentConst.TopupCredit.accountNumberHU} <br />
                                                                <FormattedMessage id="ESHOP_NUMBER" />: <span className="text-error">{accountNumber}</span></div>)
                                                            || (currency === "EUR" && <div className="font-weight-bold"><FormattedMessage id="ACCOUNT_NUMBER" />: <span className="text-error">{accountNumber}</span> <br /></div>)}
                                                        {currency === "CZK" &&
                                                            <div className="font-weight-bold"><FormattedMessage id="ACCOUNT_NUMBER" />: {ComponentConst.TopupCredit.accountNumberCZ} <br />
                                                                <FormattedMessage id="VAR_SYMBOL" />: <span className="text-error">{accountNumber}</span> <br /></div>}
                                                        {(currency === "HUF" &&
                                                            <div className="mt-5 font-weight-bold">
                                                                IBAN: <span id="iban-hu">{ComponentConst.TopupCredit.ibanNumberHU}</span> <br />
                                                                SWIFT: {ComponentConst.TopupCredit.swiftNumberHU}
                                                            </div>) || (currency === "CZK" && <div className="mt-5 font-weight-bold">
                                                                IBAN: <span id="iban-cz">{ComponentConst.TopupCredit.ibanNumberCZ}</span> <br />
                                                                SWIFT: {ComponentConst.TopupCredit.swiftNumberCZ}
                                                            </div>)
                                                            || (currency === "EUR" && <div className="mt-5 font-weight-bold">
                                                                IBAN: <span id="iban-sk">{ComponentConst.TopupCredit.ibanNumberEU}</span> <br />
                                                                SWIFT: {ComponentConst.TopupCredit.swiftNumberEU}
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                {(currency === "HUF" && <img src="../media/logos/raiffeisen-bank.png" className="bank-img" />) ||
                                                    (currency === "CZK" && <img src="../media/logos/fio-bank.png" className="bank-img" />) ||
                                                    (currency === "EUR" && <img src="../media/logos/fio-bank.png" className="bank-img" />)}
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-lg-12">
                                                {currency === "CZK" ?
                                                    <div>
                                                        <span className="font-weight-bold text-error"><FormattedMessage id="ATTENTION" />: </span>
                                                        <FormattedMessage id="ATTENTION_TEXT_CZ" />
                                                        <span className="font-weight-bold text-error"> <FormattedMessage id="ATTENTION_TEXT_CZ_VARIABLE_SYMBOL" /> </span>
                                                        <FormattedMessage id="ATTENTION_TEXT_CZ_2" />
                                                    </div> :
                                                    <div>
                                                        <span className="font-weight-bold text-error"><FormattedMessage id="ATTENTION" />: </span>
                                                        <FormattedMessage id="ATTENTION_TEXT" />
                                                        <span className="font-weight-bold text-error"> /{accountNumber}/ </span>
                                                        <FormattedMessage id="ATTENTION_TEXT_2" />
                                                        <span className="font-weight-bold text-error"> / </span> <FormattedMessage id="ATTENTION_TEXT_3" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div >
        } else {
            template = <PaymentResult />
        }
        return template;
    }

    return (
        <div className="col-lg-12 px-0">{Template()}</div>
    );
}
export default injectIntl(CreditTopup);