import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@material-ui/core/Button';
import { getData, postData, responseCode, useIsMountedRef } from '../../utils/helpers';
import { updateUserCredit } from "../../redux/actions/userActions";
import { isEmpty } from 'lodash'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorIcon from '@material-ui/icons/Error';
import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@material-ui/icons/Close';

function SenderID({ intl: { formatMessage } }) {

    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.user.currency);
    const credit = useSelector(state => state.user.credit);

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    const [selectedValue, setSelectedValue] = useState("");
    const [senderData, setSenderData] = React.useState([]);
    const [senderDataMerged, setSenderDataMerged] = React.useState([]);
    const [senderID, setSenderID] = useState("");
    const [requestedSender, setRequestedSender] = React.useState([]);
    const [registeredSender, setRegisteredSender] = React.useState([]);
    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [senderPriceText, setSenderPriceText] = useState('');
    const [senderModalPriceText, setSenderModalPriceText] = useState('');
    const [senderPriceText2, setSenderPriceText2] = useState('');
    const [senderPriceText3, setSenderPriceText3] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const czPrice1 = 500;
    const skPrice1 = 20;
    const czPrice2 = 1000;
    const skPrice2 = 40;

    useEffect(() => {
        (async () => {
            const senderResData = await getData('https://apishoptet.axima-sms.cz/eshop/settings/sender/list');
            if (isMountedRef.current) {
                if (!isEmpty(senderResData.data)) {
                    const defaultSenders = senderResData.data.default;
                    const registeredSenders = senderResData.data.registered;
                    const mergedSenders = defaultSenders.concat(registeredSenders);
                    const requestedSender = senderResData.data.requested;
                    setSenderData(mergedSenders);
                    setRequestedSender(requestedSender);
                    setRegisteredSender(registeredSenders);
                    setSenderDataMerged(mergedSenders.concat(requestedSender));
                    const selectedSender = senderResData.data.selected;
                    if (selectedSender && selectedSender !== "") {
                        setSelectedValue(selectedSender);
                    } else {
                        setSelectedValue(mergedSenders[2]);
                    }
                } else {
                    console.error(senderResData.error.error);
                }
            }
            setSenderPriceText(formatMessage({ id: 'SENDER_OWN_SENDER_RED_SENTENCE' }).replaceAll("[price1]", (currency === "CZK" && czPrice1) || (currency === "EUR" && skPrice1)).replaceAll("[currency]", currency));
            setSenderModalPriceText(formatMessage({ id: 'SENDER_OWN_SENDER_REGISTRATION_POPUP_SENTENCE2' }).replaceAll("[price1]", (currency === "CZK" && czPrice1) || (currency === "EUR" && skPrice1)).replaceAll("[currency]", currency))
            setSenderPriceText2(formatMessage({ id: 'SENDER_OWN_SENDER_INFO1' }).replaceAll("[price2]", (currency === "CZK" && czPrice2) || (currency === "EUR" && skPrice2)).replaceAll("[currency]", currency));
            setSenderPriceText3(formatMessage({ id: 'SENDER_OWN_SENDER_INFO3' }).replaceAll("[price1]", (currency === "CZK" && czPrice1) || (currency === "EUR" && skPrice1)).replaceAll("[currency]", currency));
            setEmailAddress(formatMessage({ id: 'SENDER_OWN_SENDER_REQUEST_EMAIL' }).replaceAll("[country]", user.billing.country.countryCode).toLowerCase());
        })();
    }, []);

    useEffect(() => {
        if (isErred || isSaved) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [isErred, isSaved]);

    const handleChange = async (e) => {
        setSelectedValue(e);
        console.log({ e });
        const resData = await postData('https://apishoptet.axima-sms.cz/eshop/settings/sender/select', {
            'sender': e
        });

        responseCode(resData, setResponseMessage, setErred);
        if (resData.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />);
        }
        console.log({ resData });
    }


    const handleKeyDown = (e) => {
        const value = e.key;
        const allowed = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz.- ";
        for (let i = 0; i < value.length; i++) {
            if (allowed.indexOf(value[i]) === -1) {
                e.preventDefault();
                return;
            }
        }
    }

    const handleChangeText = (e) => {
        setSenderID(e.target.value.trim());
    }

    const handleClick = async () => {
        const senderDataLowerCase = senderDataMerged.map(d => d.toLowerCase());
        console.log({ senderDataLowerCase });
        const includesSenderID = senderDataLowerCase.indexOf(senderID.toLowerCase()) > -1;
        console.log(senderID.toLowerCase());
        console.log({ includesSenderID });
        if (senderID === '') {
            setErred(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_EMPTY_SENDER" />);
            return;
        }
        if ((currency === "CZK" && credit < czPrice1) || (currency === "EUR" && credit < skPrice1)) {
            setErred(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_NOTENOUGH_CREDIT_FOR_SENDER" />);
        } else {
            const resData = await postData('https://apishoptet.axima-sms.cz/eshop/settings/sender/can_request2', senderID);
            responseCode(resData, setResponseMessage, setErred);
            if (includesSenderID || resData.error.error === "SENDER_ALREADY_REGISTERED" || resData.error.error === "SENDER_ALREADY_REQUESTED") {
                setErred(true);
                setResponseMessage(<FormattedMessage id="SNACKBAR_SENDER_ALREADY_USED" />);
            } else {
                setShowModal(true);
            }
        }
    }

    const handleModalClick = async () => {

        const resData = await postData('https://apishoptet.axima-sms.cz/eshop/settings/sender/request2', senderID);

        responseCode(resData, setResponseMessage, setErred);
        if (resData.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />);
            const requestedResData = await getData('https://apishoptet.axima-sms.cz/eshop/settings/sender/list');
            const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
            if (isMountedRef.current) {
                if (!isEmpty(requestedResData.data) && !isEmpty(resData.data.eshop)) {
                    const requestedSender = requestedResData.data.requested;
                    setRequestedSender(requestedSender);
                    dispatch(updateUserCredit(Number(resData.data.eshop.account["0"].credit).toFixed(2)));
                } else {
                    console.error(requestedResData.error.error);
                    console.error(resData.error.error);
                }
                console.log({ requestedResData });
            }
            setShowModal(false);
        }
        console.log({ resData });
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
    }

    return (
        <div className="col-lg-12 px-0 mt-10 sender-id">
            <div>
                <span className="font-weight-bold mb-10"><FormattedMessage id="SENDER_NAME" /></span>
                <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                        <Tooltip>
                            <FormattedMessage id="SENDER_OWN_SENDER_REGISTRATION_TOOLTIP" />
                        </Tooltip>
                    }
                >
                    <a className="help-icon mx-3"><HelpIcon /></a>
                </OverlayTrigger>
            </div>
            <p className="gray-text mt-3 mb-0"><FormattedMessage id="SENDER_SUBNAME" /></p>
            <Form className="col-lg-12 px-0 senderid-forms" onSubmit={e => { e.preventDefault(); }}>
                <Form.Row className="m-0 mt-5 justify-content-start">
                    <Form.Group className="d-flex senderid-dropdown">
                        <Dropdown className="" value={selectedValue} onSelect={(e) => handleChange(e)}>
                            <Dropdown.Toggle id="dropdown-basic">
                                {selectedValue}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    (senderData
                                    ).map((row, index) => (
                                        <Dropdown.Item key={index} eventKey={row}>{row}</Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Label className="gray-text ml-5"><FormattedMessage id="SENDER_SENTENCE_AFTER_ROLLDOWN" /></Form.Label>
                    </Form.Group>
                </Form.Row>
                <p className="font-weight-bold mt-5 mb-0"><FormattedMessage id="SENDER_OWN_NAME" /></p>
                <p className="font-weight-bold text-danger mt-3 mb-0">{senderPriceText}</p>
                <p className="font-weight-bold gray-text mt-8 mb-0">
                    {senderPriceText2}<br />
                    <FormattedMessage id="SENDER_OWN_SENDER_INFO2" /><br />
                    {senderPriceText3}
                </p>
                <p className="font-weight-bold gray-text mt-8 mb-0">
                    <FormattedMessage id="SENDER_OWN_SENDER_INFO4" /><br />
                    <FormattedMessage id="SENDER_OWN_SENDER_INFO5" /><br />
                    <FormattedMessage id="SENDER_OWN_SENDER_INFO6" />
                </p>
                {
                    (isEmpty(requestedSender) && isEmpty(registeredSender)) &&
                    <>
                        <p className="font-weight-bold mt-8 mb-5"><FormattedMessage id="SENDER_OWN_SENDER_REGISTRATION" /></p>
                        <Form.Row className="m-0 mt-5 justify-content-start">
                            <Form.Group className="mr-10">
                                <FormControlLabel className="m-0 w-100"
                                    control={
                                        <FormattedMessage id="SENDER_OWN_SENDER_ROLLDOWN_PLACEHOLDER" defaultMessage="Search">
                                            {placeholder =>
                                                <Form.Control placeholder={placeholder} onChange={handleChangeText} onKeyDown={handleKeyDown} maxLength="11" />}
                                        </FormattedMessage>}
                                    label={<FormattedMessage id="SENDER_OWN_SENDER_ROLLDOWN_NAME" />}
                                    labelPlacement="top"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Button className="mt-6" variant="contained" color="primary" onClick={handleClick}>
                                    <FormattedMessage id="SENDER_OWN_SENDER_REQUEST_BUTTON" />
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </>
                }
                {
                    (!isEmpty(requestedSender) && isEmpty(registeredSender)) &&
                    <div>
                        <p className="font-weight-bold mt-8 mb-5"><FormattedMessage id="SENDER_OWN_SENDER_REGISTERED" /> <span className="green-text">{requestedSender[requestedSender.length - 1]}</span>  - <span className="text-danger"><FormattedMessage id="SENDER_OWN_SENDER_REGISTRATION_IN_PROGRESS" /></span></p>
                    </div>
                }
                {
                    (isEmpty(requestedSender) && !isEmpty(registeredSender)) &&
                    <div>
                        <p className="font-weight-bold mt-8 mb-5"><FormattedMessage id="SENDER_OWN_SENDER_REGISTERED" /> <span className="green-text">{registeredSender[registeredSender.length - 1]}</span></p>
                    </div>
                }
                {(isEmpty(requestedSender) && isEmpty(registeredSender)) &&
                    <p className="font-weight-bold gray-text mt-3 mb-0"><FormattedMessage id="SENDER_OWN_SENDER_REQUEST_INFO1" /></p>
                }
                <p className="font-weight-bold gray-text mt-6 mb-0"><FormattedMessage id="SENDER_OWN_SENDER_REQUEST_INFO2" /> <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
            </Form>
            <div className="d-flex justify-content-end">
                {
                    (isErred || isSaved) && <div className="d-flex justify-content-end w-100">
                        <div className="snackbar my-5">
                            <div className={`snackbar-content ${isSaved ? "success" : null}`}>
                                {/* <ErrorIcon className="icon" /> */}
                                <span>{responseMessage}</span>
                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal
                show={showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
                centered
                className="form-modal">
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" className="mx-auto">
                        <FormattedMessage id="SENDER_OWN_SENDER_REGISTRATION_POPUP_SENTENCE1" />
                        <a href="#!" onClick={() => setShowModal(false)} className="close-button"><CloseIcon className="icon text-danger" style={{ fontSize: 40 }} /></a>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="green-text text-center mb-7">{senderID}</h3>
                    <p className="text-center mb-0">
                        {senderModalPriceText}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)} variant="contained" color="primary" className="modal-cancel-button mr-5"><FormattedMessage id="SENDER_OWN_SENDER_REGISTRATION_POPUP_BUTTON_BACK" /></Button>
                    <Button onClick={handleModalClick} variant="contained" color="primary" className="modal-confirm-button"><FormattedMessage id="SENDER_OWN_SENDER_REGISTRATION_POPUP_BUTTON_OK" /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default injectIntl(SenderID);
