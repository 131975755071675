import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SmsScenarios from './SmsScenarios';
import { FormattedMessage } from "react-intl";
import Notifications from './Notifications';
import SenderID from './SenderID';
import { isEmpty } from 'lodash'
import { useIsMountedRef } from '../../utils/helpers';
import { setInvoicesLink, setNotificationsLink } from "../../redux/actions/userActions";
import OptOut from './OptOut';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function Settings() {

    const [value, setValue] = React.useState(0);

    const user = useSelector(state => state.user.user);
    const notificationsLink = useSelector(state => state.user.notificationsLink);

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setInvoicesLink(false));
        dispatch(setNotificationsLink(false));
    };

    useEffect(() => {
        if (isMountedRef.current) {
            if (notificationsLink) {
                setValue(2);
            }
        }
    }, [notificationsLink]);


    return (
        !isEmpty(user) &&
        <div className="row px-0">
            {/* <div className="col-lg-2">
            </div>
            <div className="col-lg-10"> */}
            <div className="col-lg-12">
                <AppBar position="static" color="primary" className={`sub-tab ${user.billing.country.countryCode === "HU" ? null : "settings-sub-tab"}`}>
                    {
                        (user.billing.country.countryCode === "HU") ?
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                                <Tab label={<FormattedMessage id="SUBMENU_SMS_SETTINGS_SMS_SCENARIOS" />} />
                                <Tab label={<FormattedMessage id="SUBMENU_SMS_SETTINGS_SMS_NOTIFICATIONS" />} />
                                <Tab label={<FormattedMessage id="SUBMENU_SETTINGS_OPTOUT" />} />
                            </Tabs> :
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                                <Tab label={<FormattedMessage id="SUBMENU_SMS_SETTINGS_SMS_SCENARIOS" />} />
                                <Tab label={<FormattedMessage id="SUBMENU_SMS_SETTINGS_SMS_SENDER" />} />
                                <Tab label={<FormattedMessage id="SUBMENU_SMS_SETTINGS_SMS_NOTIFICATIONS" />} />
                                <Tab label={<FormattedMessage id="SUBMENU_SETTINGS_OPTOUT" />} />
                            </Tabs>
                    }
                </AppBar>
            </div>
            <div className="col-lg-12">
                <TabPanel value={value} index={0}>
                    <SmsScenarios />
                </TabPanel>
                {
                    (user.billing.country.countryCode === "HU") ?
                        <>
                            <TabPanel value={value} index={1}>
                                <Notifications />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <OptOut />
                            </TabPanel>
                        </> :
                        <>
                            <TabPanel value={value} index={1}>
                                <SenderID />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Notifications />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <OptOut />
                            </TabPanel></>
                }
            </div>
        </div >
    );
}
export default Settings;