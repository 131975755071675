import React from "react";
import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/cs";
import "@formatjs/intl-relativetimeformat/dist/locale-data/hr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/sk";

import enMessages from "./messages/en";
import csMessages from "./messages/cs";
import huMessages from "./messages/hu";
import skMessages from "./messages/sk";

const allMessages = {
  en: enMessages,
  cs: csMessages,
  hu: huMessages,
  sk: skMessages
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
