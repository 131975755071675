import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { getData, useIsMountedRef } from '../../utils/helpers';
import SmsTemplate from './SmsTemplate';
import { FormattedMessage } from "react-intl";
import { isEmpty } from 'lodash'

function SmsScenarios() {

    const [data, setData] = React.useState([]);

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        (async () => {
            const resData = await getData('https://apishoptet.axima-sms.cz/order/status');
            if (isMountedRef.current) {
                if (!isEmpty(resData.data.status)) {
                    setData(resData.data.status);
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    return (
        <div className="col-lg-12 px-0 mt-10 sms-scenarios">
            <p className="font-weight-bold mb-10"><FormattedMessage id="ORDER_STATUS_CHANGE_INFO" /></p>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 order-status-title">
                    <p><FormattedMessage id="ORDER_STATUS_CHANGE" /></p>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 text-center status-switch-title">
                    <p><FormattedMessage id="SEND_AUTOMATICALLY_WHEN_STATUS_CHANGES" /></p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 sms-text-title">
                    <p><FormattedMessage id="TEXT_BEING_SENT_INFO" /></p>
                </div>
            </div>

            {data.map((row) => (
                <SmsTemplate key={row.id} {...row} />
            ))}
        </div>
    );
}
export default SmsScenarios;