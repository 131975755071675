import React from 'react';
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Table from 'react-bootstrap/Table'
import { isEmpty } from 'lodash'
import { priceList } from '../../utils/priceList';

function PriceList() {

    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.user.currency);

    // const user = {
    //     billing: {
    //         country: {
    //             countryCode: "CZ"
    //         }
    //     }
    // }

    return (
        !isEmpty(user) &&
        <div className="col-lg-12 px-0 mt-10 price-list">
            {/* <h2 className="mb-5"><FormattedMessage id="PRICELIST_HEADER" /></h2> */}
            {/* <p className="mb-5"><label className="amount-label d-flex align-items-center"><FormattedMessage id="PRICE_PER_SMS" />
                {(currency === "HUF" && <span className="amount ml-2">17 HUF</span>) ||
                    (currency === "CZK" && <span className="amount ml-2">0,90 CZK</span>) ||
                    (currency === "EUR" && <span className="amount ml-2">0,95 EUR</span>)}
            </label></p> */}
            <h6 className="ml-3 mb-5"><FormattedMessage id="PRICELIST_EU" /></h6>
            <div className="row mb-5">
                <div className="col-lg-4 col-md-4">
                    <Table striped>
                        <tbody>
                            {
                                priceList.slice(0, 9).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.countryCode}</td>
                                        <td><FormattedMessage id={"PRICELIST_COUNTRY_NAME_" + data.countryCode} /></td>
                                        {/* {(user.billing.country.countryCode === "HU" && <td>{data.huPrice} HUF</td>) ||
                                            (user.billing.country.countryCode === "CZ" && <td>{data.czPrice} CZK</td>) ||
                                            (user.billing.country.countryCode === "SK" && <td>{data.skPrice} EUR</td>)} */}
                                        {(currency === "HUF" && <td>{data.huPrice} HUF</td>) ||
                                            (currency === "CZK" && <td>{data.czPrice} CZK</td>) ||
                                            (currency === "EUR" && <td>{data.skPrice} EUR</td>)}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="col-lg-4 col-md-4">
                    <Table striped>
                        <tbody>
                            {
                                priceList.slice(9, 18).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.countryCode}</td>
                                        <td><FormattedMessage id={"PRICELIST_COUNTRY_NAME_" + data.countryCode} /></td>
                                        {/* {(user.billing.country.countryCode === "HU" && <td>{data.huPrice} HUF</td>) ||
                                            (user.billing.country.countryCode === "CZ" && <td>{data.czPrice} CZK</td>) ||
                                            (user.billing.country.countryCode === "SK" && <td>{data.skPrice} EUR</td>)} */}
                                        {(currency === "HUF" && <td>{data.huPrice} HUF</td>) ||
                                            (currency === "CZK" && <td>{data.czPrice} CZK</td>) ||
                                            (currency === "EUR" && <td>{data.skPrice} EUR</td>)}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="col-lg-4 col-md-4">
                    <Table striped>
                        <tbody>
                            {
                                priceList.slice(18, 27).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.countryCode}</td>
                                        <td><FormattedMessage id={"PRICELIST_COUNTRY_NAME_" + data.countryCode} /></td>
                                        {/* {(user.billing.country.countryCode === "HU" && <td>{data.huPrice} HUF</td>) ||
                                            (user.billing.country.countryCode === "CZ" && <td>{data.czPrice} CZK</td>) ||
                                            (user.billing.country.countryCode === "SK" && <td>{data.skPrice} EUR</td>)} */}
                                        {(currency === "HUF" && <td>{data.huPrice} HUF</td>) ||
                                            (currency === "CZK" && <td>{data.czPrice} CZK</td>) ||
                                            (currency === "EUR" && <td>{data.skPrice} EUR</td>)}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <h6 className="ml-3 mb-5"><FormattedMessage id="PRICELIST_COUNTRY_NAME_OTHER" /></h6>
            <div className="row mb-5">
                <div className="col-lg-4 col-md-4">
                    <Table striped>
                        <tbody>
                            {
                                priceList.slice(27, 31).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.countryCode}</td>
                                        <td><FormattedMessage id={"PRICELIST_COUNTRY_NAME_" + data.countryCode} /></td>
                                        {/* {(user.billing.country.countryCode === "HU" && <td>{data.huPrice} HUF</td>) ||
                                            (user.billing.country.countryCode === "CZ" && <td>{data.czPrice} CZK</td>) ||
                                            (user.billing.country.countryCode === "SK" && <td>{data.skPrice} EUR</td>)} */}
                                        {(currency === "HUF" && <td>{data.huPrice} HUF</td>) ||
                                            (currency === "CZK" && <td>{data.czPrice} CZK</td>) ||
                                            (currency === "EUR" && <td>{data.skPrice} EUR</td>)}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="ml-3 mt-5 mb-5" style={{ color: "#FF0000" }}>
                <p className="mb-0"><FormattedMessage id="PRICELIST_NOTE1" /></p>
                <p><FormattedMessage id="PRICELIST_NOTE2" />
                    {/* {(user.billing.country.countryCode === "HU" && <span>24 HUF</span>) ||
                        (user.billing.country.countryCode === "CZ" && <span>1,60 CZK</span>) ||
                        (user.billing.country.countryCode === "SK" && <span>0,061 EUR</span>)} */}
                    {(currency === "HUF" && <span>24 HUF</span>) ||
                        (currency === "CZK" && <span>1,60 CZK</span>) ||
                        (currency === "EUR" && <span>0,061 EUR</span>)}
                </p>
            </div>
            <div>
                <h6 className="mb-5"><FormattedMessage id="HOW_WE_CHARGE_SMS" /></h6>
                <p><FormattedMessage id="HOW_WE_CHARGE_SMS_INFO" /></p>
                {/* <p className="mt-5">
                    <FormattedMessage id="HOW_WE_CHARGE_SMS_INFO2" /></p> */}
            </div>
        </div >
    );
}
export default PriceList;