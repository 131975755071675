import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isEmpty } from 'lodash'
import { getData, useIsMountedRef } from '../../../utils/helpers';
import { updateUnseenPayment, setInvoicesLink, setNotificationsLink } from "../../../redux/actions/userActions";

function ResultOK() {

    const user = useSelector(state => state.user.user);
    const credit = useSelector(state => state.user.credit);
    const currency = useSelector(state => state.user.currency);
    const [unseenPaymentLast, setUnseenPaymentLast] = useState({});
    const seenPayment = useSelector(state => state.user.seenPayment);
    const [paymentSeenPosted, setPaymentSeenPosted] = useState(false);

    // const unseenPaymentLast = {
    //     amount: 20,
    //     currency: "czk"
    // }

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();


    useEffect(() => {
        if (isMountedRef.current) {
            if (!isEmpty(user)) {
                setUnseenPaymentLast(user.unseen_payments[user.unseen_payments.length - 1]);
            }
            if (seenPayment) {
                setPaymentSeenPosted(true);
            }
        }
    }, [user]);

    useEffect(() => {
        (async () => {
            if (!isEmpty(user) && paymentSeenPosted) {
                const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
                if (!isEmpty(resData.data.eshop)) {
                    dispatch(updateUnseenPayment(resData.data.eshop.unseen_payments));
                }
            }
        })();
    }, [paymentSeenPosted]);

    return (
        <div className="row result-page">
            {console.log("result ok")}
            <div className="col-lg-4">
                <div className="result-info success mb-8 d-flex align-items-center">
                    <h4 className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_CLAIM" /></h4>
                </div>
                <p><FormattedMessage id="PAYMENT_CARD_RESULT_OK_LEFT_TEXT1" /></p>
                <p className="mt-5"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_LEFT_TEXT2" /> <a className="blue-link" onClick={() => dispatch(setInvoicesLink(true))}><FormattedMessage id="PAYMENT_CARD_RESULT_OK_LEFT_TEXT2_LINK" /></a></p>
                <p className="mt-5"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_LEFT_TEXT3" /> {unseenPaymentLast.amount} {unseenPaymentLast.currency}</p>
                <p className="mt-5"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_LEFT_TEXT4" /> <a className="blue-link" onClick={() => dispatch(setNotificationsLink(true))}><FormattedMessage id="PAYMENT_CARD_RESULT_OK_LEFT_TEXT4_LINK" /></a></p>
                <div className="amount-label d-flex align-items-center mt-5"><FormattedMessage id="REMAINING_CREDIT" /> <span className="amount ml-2">{credit} {currency}</span></div>
            </div>
            <div className="col-lg-8">
                <div className="payment-details pt-5 pb-10 px-10">
                    <div className="d-flex justify-content-between">
                        <h6 className="font-weight-bold"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_NAME" /></h6>
                        <a className="blue-link" onClick={() => dispatch(setInvoicesLink(true))}><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_LINK" /></a>
                    </div>
                    <div className="mt-5">
                        <p className="mb-0"><strong><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_SELLER" /></strong></p>
                        <div className="ml-5">
                            <p><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_SELLER_INFO" /> <br />
                                <FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_SELLER_INFO2A" /> <br />
                                <FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_SELLER_INFO3" /></p>
                            <p className="mt-5"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_SELLER_INFO2" /></p>
                        </div>
                        <div className="ml-5 mt-8">
                            <div className="d-flex justify-content-between align-items-end sms-credit-title">
                                <span className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_SELLER_INVOICED_ITEM" /></span>
                                {/* <span>{unseenPaymentLast.amount} {unseenPaymentLast.currency} {user.billing.country.countryCode === "CZ" ? "vč DPH" : ""}</span> */}
                                <span>{unseenPaymentLast.amount} {unseenPaymentLast.currency} {currency === "CZK" ? "vč DPH" : ""}</span>
                            </div>
                            <div className="mt-5">
                                <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_ORDERNUMBER" /> 0219731</p>
                                <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_TRANSACTIONDATE" /> 12.12.2020</p>
                                <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_AUTHCODE" /> 08731401</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-8">
                                <p className="mb-0"><strong><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_BUYER" /></strong></p>
                                <div className="ml-5">
                                    <p className="mb-0"><strong>{user.name}</strong></p>
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_BUYER_ADDRESS" /> {user.billing.address.streetAddress}, {user.billing.address.zip}, {user.billing.address.city} {user.billing.country.countryName}</p>
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_BUYER_PHONE" /> {user.phone}</p>
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_BUYER_E-MAIL" /> {user.email}</p>
                                </div>
                                <p className="mt-5 mb-0"><strong><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_NOTE" /></strong></p>
                                <div className="ml-5">
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_NOTE_SENTENCE1" /></p>
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_OK_RIGHT_NOTE_SENTENCE2" /></p>
                                </div>
                            </div>
                            <div className="col-lg-4 axima-logo-wrapper">
                                <div className="axima-logo green"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResultOK;