import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from "react-intl";
import SendSmsAutomatedSms from './SendSmsAutomatedSms';
import SendSmsCampaigns from './SendSmsCampaigns';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function SendSms() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="row px-0">
            {/* <div className="col-lg-8"> */}
            <div className="col-lg-12">
                <AppBar position="static" color="primary" className="sub-tab">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                        <Tab label={<FormattedMessage id="SUBMENU_SMS_SIMPLE_SMS" />} />
                        <Tab label={<FormattedMessage id="SUBMENU_SMS_MARKETING_SMS" />} />
                    </Tabs>
                </AppBar>
            </div>
            {/* <div className="col-lg-4">
            </div> */}
            <div className="col-lg-12">
                <TabPanel value={value} index={0}>
                    <SendSmsAutomatedSms />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SendSmsCampaigns />
                </TabPanel>
            </div>
        </div>
    );
}
export default SendSms;