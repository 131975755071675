import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import PeopleIcon from '@material-ui/icons/People';
import SmsTextarea from './SmsTextarea';
import { getData, postData, responseCode, useIsMountedRef } from '../../utils/helpers';
import Button from '@material-ui/core/Button';
import { setSendSmsCampaignLink, setTopupCreditLink, updateUserCredit } from "../../redux/actions/userActions";
import Switch from '@material-ui/core/Switch';
import Form from 'react-bootstrap/Form'
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Dropdown from 'react-bootstrap/Dropdown'
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty } from 'lodash'
import { injectIntl } from 'react-intl'
import { ComponentConst } from '../../utils/definitions';
import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { useLang } from "../../../src/_metronic/i18n";

function SendSmsCampaignsSendingSms({ intl: { formatMessage } }) {

    const user = useSelector(state => state.user.user);

    // const user = {
    //     billing: {
    //         country: {
    //             countryCode: "HU"
    //         }
    //     }
    // }

    const credit = useSelector(s => s.user.credit);
    const currency = useSelector(state => state.user.currency);

    // const credit = 3;

    const dispatch = useDispatch();

    const isMountedRef = useIsMountedRef();

    const filteredPhoneNumbers = useSelector(state => state.user.filteredPhoneNumbers);
    const campaignName = useSelector(state => state.user.campaignName);
    const filteredObject = useSelector(state => state.user.filteredObject);

    const phoneNumberRef = createRef();
    const [textValue, setTextValue] = useState('');
    const [textValueLength, setTextValueLength] = useState('');
    const [smsCount, setSmsCount] = useState(1);
    const [textChange, setTextChange] = useState(false);
    const [smsInfoData, setSmsInfoData] = useState({});
    const [smsPriceData, setSmsPriceData] = useState({});
    const [isSetTime, setIsSetTime] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [disabledPickers, setDisabledPickers] = useState(true);

    const [switchA, setSwitchA] = useState(true);
    const [switchB, setSwitchB] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    const [isErred, setErred] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isSendSaved, setIsSendSaved] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [modalIsErred, setModalErred] = useState(false);
    const [modalIsSaved, setModalIsSaved] = useState(false);
    const [modalResponseMessage, setModalResponseMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonControlDisabled, setButtonControlDisabled] = useState(true);
    const [senderData, setSenderData] = React.useState([]);

    const [switchState, setSwitchState] = useState(false);
    const [apiCode, setApiCode] = useState(null);
    const [apiNumber, setApiNumber] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
    const [testSmsButtonDisabled, setTestSmsButtonDisabled] = useState(true);

    const childProps = {
        textValue,
        textValueLength,
        disabled: false,
        setTextValue,
        setTextValueLength,
        sendSms: true,
        sendCampaignSms: true,
        textChange,
        setTextChange,
        smsCount,
        setSmsCount,
        hasDropdown: false,
        switchState,
        setSwitchState,
        apiCode,
        setApiCode,
        apiNumber,
        setApiNumber,
        onChange: (e) => onChangeSmsTextArea(e),
        onFocus: (e) => onFocusSmsTextArea(e),
        onChangeSwitch: (e) => onChangeSwitchTextArea(e)
    };

    useEffect(() => {
        if (isErred || isSaved || modalIsErred || modalIsSaved || isPhoneNumberError) {
            let timeOut = setTimeout(() => {
                setErred(false);
                setIsSaved(false);
                setModalErred(false);
                setModalIsSaved(false);
                setPhoneNumberError(false);
            }, 10000);
            return () => clearTimeout(timeOut);
        }
    }, [isErred, isSaved, modalIsErred, modalIsSaved, isPhoneNumberError]);

    useEffect(() => {
        if (isSendSaved) {
            let timeOut = setTimeout(() => {
                setIsSendSaved(false);
                dispatch(setSendSmsCampaignLink(true));
            }, 10000);
            return () => clearTimeout(timeOut);
        }
    }, [isSendSaved]);

    useEffect(() => {
        smsTextInfo();
        if (textValue !== "") {
            setButtonControlDisabled(false);
            setTestSmsButtonDisabled(false);
            let timeOut = setTimeout(() => {
                smsTextInfo();
            }, 2000);
            return () => clearTimeout(timeOut);
        } else {
            setSmsCount(1);
            setButtonControlDisabled(true);
            setTestSmsButtonDisabled(true);
        }
    }, [textValue]);

    useEffect(() => {
        if (switchA) {
            setDisabledPickers(true);
        } else {
            setDisabledPickers(false);
        }
    }, [switchA]);

    useEffect(() => {
        if (textValue !== "" && switchA) {
            setSelectedDate(null);
            setSelectedTime(null);
            if ((smsPriceData.cz || smsPriceData.hu || smsPriceData.sk) > credit) {
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        } else if (textValue !== "" && switchB) {
            if (selectedDate !== null && selectedTime !== null) {
                if ((smsPriceData.cz || smsPriceData.hu || smsPriceData.sk) > credit) {
                    setButtonDisabled(true);
                } else {
                    setButtonDisabled(false);
                }
            } else {
                setButtonDisabled(true);
            }
        } else {
            setButtonDisabled(true);
        }
        // console.log({ textValue });
        // console.log({ switchA });
        // console.log({ switchB });
        // console.log({ selectedDate });
        // console.log({ selectedTime });
    }, [textValue, switchA, switchB, selectedDate, selectedTime]);

    useEffect(() => {
        (async () => {
            const senderResData = await getData('https://apishoptet.axima-sms.cz/eshop/settings/sender/list');
            if (isMountedRef.current) {
                if (!isEmpty(senderResData.data)) {
                    const defaultSenders = senderResData.data.default;
                    const registeredSenders = senderResData.data.registered;
                    const mergedSenders = defaultSenders.concat(registeredSenders)
                    setSenderData(mergedSenders);
                    console.log({ mergedSenders });
                    const selectedSender = senderResData.data.selected;
                    if (selectedSender && selectedSender !== "") {
                        console.log({ selectedSender })
                        setSelectedValue(selectedSender);
                    } else {
                        setSelectedValue(mergedSenders[2]);
                    }
                } else {
                    console.error(senderResData.error.error);
                }
            }
        })();
    }, []);

    const onChangeSmsTextArea = (e) => {
        setTextValue(e.target.value);
    }

    const lang = useLang();

    const onChangeSwitchTextArea = (e) => {
        setSwitchState(e.target.value);
        if (e.target.checked === true) {
            (async () => {
                const resData = await getData('https://apishoptet.axima-sms.cz/sms/bulk/code');
                if (isMountedRef.current) {
                    if (!isEmpty(resData.data)) {
                        setApiCode(resData.data.code);
                        console.log(resData.data.code);
                        console.log({lang: lang});
                        if (lang === "cs") {
                            setApiNumber(ComponentConst.SendSmsCampaignsSendingSms.apiNumberCZ);
                        } else if (lang === "sk") {
                            setApiNumber(ComponentConst.SendSmsCampaignsSendingSms.apiNumberSK)
                        } else if (lang === "hu") {
                            setApiNumber(ComponentConst.SendSmsCampaignsSendingSms.apiNumberHU)
                        } else if (lang === "en") {
                            setApiNumber(ComponentConst.SendSmsCampaignsSendingSms.apiNumberEN)
                        }
                    }
                }
            })();
        } else {
            setApiCode(null);
            setApiNumber(null);
        }
    }

    const smsTextInfo = async () => {
        const resData = await getData(`https://apishoptet.axima-sms.cz/sms/bulk/filter/textinfo?text=${(encodeURIComponent(textValue))}&filter=${encodeURIComponent(JSON.stringify(filteredObject))}`);
        if (!isEmpty(resData.data)) {
            setSmsInfoData(resData.data.textinfo);
            setSmsPriceData(resData.data.price);
            setSmsCount(resData.data.textinfo.parts.length);
        } else {
            console.error(resData.error.error);
        }
    }

    const handleControl = () => {
        if ((smsPriceData.cz || smsPriceData.hu || smsPriceData.sk) > credit) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
        setIsSetTime(true);
    }

    const onFocusSmsTextArea = (e) => {
        console.log("focus");
        if (textValue !== "") {
            // setButtonControlDisabled(true);
            setIsSetTime(false);
        }
    }

    const handleToggle = (switch1, switch2) => {
        setSwitchA(switch1);
        setSwitchB(switch2);

    }

    const momentDates = () => {
        const dateFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm";
        const dateTimeFormat = "YYYY-MM-DD HH:mm";
        let dateMoment = "";
        let timeMoment = "";
        let dateTimeMoment = "";
        if (selectedDate !== null) {
            dateMoment = moment(selectedDate).format(dateFormat);
        }
        if (selectedTime !== null) {
            timeMoment = moment(selectedTime).format(timeFormat);
        }
        if (selectedDate !== null && selectedTime !== null) {
            const someday = moment(dateMoment + ' ' + timeMoment);
            dateTimeMoment = someday.utc().format();
        }
        return { dateMoment, timeMoment, dateTimeMoment }
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    const handleChange = async (e) => {
        setSelectedValue(e);
        console.log({ e });
        const resData = await postData('https://apishoptet.axima-sms.cz/eshop/settings/sender/select', {
            'sender': e
        });

        responseCode(resData, setResponseMessage, setErred);
        if (resData.error.error === "OK") {
            setIsSaved(true);
            setResponseMessage(<FormattedMessage id="SNACKBAR_SAVED_OK" />);
        }
        console.log({ resData });
    }

    const handleClick = async () => {
        const { dateMoment, timeMoment, dateTimeMoment } = momentDates();
        // console.log({ dateMoment });
        // console.log({ timeMoment });
        // console.log({ dateTimeMoment });

        const data = await postData('https://apishoptet.axima-sms.cz/sms/bulk/filter', {
            'filter': filteredObject,
            'text': textValue,
            'caption': campaignName,
            'sender': selectedValue,
            'start': dateTimeMoment,
            'code': apiCode !== null ? apiCode : ""
        });

        if (data.error.error !== "OK") {
            setErred(true);
            setResponseMessage(<FormattedMessage id={data.error.error} />)
        } else {
            setIsSendSaved(true);
            setTextValue('');
            setButtonDisabled(true);
            setSelectedDate(null);
            setSelectedTime(null);
            setResponseMessage(<FormattedMessage id="SMS_CAMPAIGNS_SNACKBAR_CAMPAIGN_PROCESSED" />);
            const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
            if (!isEmpty(resData.data.eshop)) {
                dispatch(updateUserCredit(Number(resData.data.eshop.account["0"].credit).toFixed(2)));
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const handlePhone = (phone) => {
        if (phoneNumber !== phone) setPhoneNumber(phone);

        if (phone === '' || phone === undefined) {
            setPhoneNumberError(true);
            setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
            return;
        }
        // else {
        //     if (phone.length < 13 && phone.length > 4) {
        //         setPhoneNumberError(true);
        //         setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NO_MOBILE_NUMBER" />)
        //         return;
        //     }
        // }

        const phoneLib = parsePhoneNumberFromString(phone);

        if (phoneLib) {
            // if (!phoneLib.isPossible() || !phoneLib.isValid()) {
            if (!phoneLib.isPossible()) {
                setPhoneNumberError(true);
                setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />)
            } else {
                setPhoneNumberError(false);
            }
        }
    }

    const handleSendTestSms = async () => {
        let error = false;
        const phoneLib = parsePhoneNumberFromString(phoneNumberRef.current.value);
        console.log({ phoneLib });
        console.log({ phoneNumber });
        console.log(phoneNumberRef.current.value);
        const phoneValue = phoneNumberRef.current.value.length;
        console.log({ phoneValue });
        console.log(isPossiblePhoneNumber(phoneNumberRef.current.value));

        if (!isPossiblePhoneNumber(phoneNumberRef.current.value)) {
            setPhoneNumberError(true);
            setPhoneNumberMessage(<FormattedMessage id="SNACKBAR_NUMBER_WRONG" />)
            error = true;
        } else {
            setPhoneNumberError(false);
        }

        if (error) {
            return;
        }

        const postSmsData = await postData('https://apishoptet.axima-sms.cz/sms', {
            'text': textValue,
            'from': selectedValue,
            'to': [phoneNumber]
        });

        responseCode(postSmsData, setModalResponseMessage, setModalErred);
        if (postSmsData.error.error === "OK") {
            setModalIsSaved(true);
            setModalResponseMessage(<FormattedMessage id="SIMPLESMS_SMS_SEND_OK" />);
            setShowModal(false);
            setPhoneNumber("");
            const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
            if (!isEmpty(resData.data.eshop)) {
                dispatch(updateUserCredit(Number(resData.data.eshop.account["0"].credit).toFixed(2)));
            } else {
                console.error(resData.error.error);
            }
        }
    }

    const handleSnackbarClose = () => {
        setErred(false);
        setIsSaved(false);
        setIsSendSaved(false);
    }

    const handleSnackbarCloseModal = () => {
        setModalErred(false);
        setModalIsSaved(false);
        setPhoneNumberError(false);
    }


    return (
        <div className="col-lg-12 px-0">
            <div className="row">
                {/* <div className="col-lg-4 col-md-6 col-sm-6"></div>
                <div className="col-lg-8 col-md-6 col-sm-6"> */}
                <div className="col-lg-12">
                    <div className="registrate-breadcrumb">
                        <div className="registrate"><FormattedMessage id="CHOOSE_DATABASE" /></div>
                        <div className="sms-verification active"><FormattedMessage id="SENDING_AN_SMS" /></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 send-sms-campaigns px-0">
                <div className="row mt-10">
                    <div className="col-lg-12">
                        <div className="d-flex"><PeopleIcon fontSize="large" className="mr-3 gray-text" />
                            <span className="d-flex align-items-center font-weight-bold sms-campaign-result">
                                <FormattedMessage id="SMS_CAMPAIGNS_RESULT" /> <span className="amount-label"><span className="amount mx-2">{filteredPhoneNumbers}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-8">
                        <SmsTextarea {...childProps} />
                        <Button onClick={handleControl} variant="contained" color="primary" className="mt-5" disabled={buttonControlDisabled}>
                            <FormattedMessage id="SMS_CAMPAIGNS_CHECK_BUTTON" />
                        </Button>
                        {
                            isSetTime ?
                                <>
                                    <div className="row mt-5">
                                        <div className="col-lg-12">
                                            <div className="font-weight-bold mb-3">

                                                {
                                                    !isEmpty(smsInfoData) && smsInfoData.parts.length > 1 ? <FormattedMessage id="SMS_CAMPAIGNS_SMS_LENGHT_INFO" /> : null
                                                } <FormattedMessage id="SMS_CAMPAIGNS_SMS_PARTS_COUNT1" /> {smsCount} <FormattedMessage id="SMS_CAMPAIGNS_SMS_PARTS_COUNT2" /> </div>
                                            <div className="font-weight-bold mb-3"><FormattedMessage id="SMS_CAMPAIGNS_NUMBERS_COUNT" /> <span className="amount-label"><span className="amount mx-2">{filteredPhoneNumbers}</span></span></div>
                                            <div className="font-weight-bold"><FormattedMessage id="SMS_CAMPAIGNS_APPROX_PRICE" />
                                                {/* {(user.billing.country.countryCode === "HU" && <span> {smsPriceData.hu} Huf. </span>) || 
                                                (user.billing.country.countryCode === "CZ" && <span> {smsPriceData.cz} Kč. </span>) || 
                                                (user.billing.country.countryCode === "SK" && <span> {smsPriceData.sk} Eur. </span>)} */}
                                                {(currency === "HUF" && <span> {smsPriceData.hu} Huf. </span>) ||
                                                    (currency === "CZK" && <span> {smsPriceData.cz} Kč. </span>) ||
                                                    (currency === "EUR" && <span> {smsPriceData.sk} Eur. </span>)}

                                                {
                                                    (smsPriceData.cz || smsPriceData.hu || smsPriceData.sk) > credit ?
                                                        <span className="text-danger"><FormattedMessage id="SMS_CAMPAIGNS_LOW_CREDIT_INFO" /><a className="blue-link" onClick={() => dispatch(setTopupCreditLink(true))}><FormattedMessage id="SMS_CAMPAIGNS_LOW_CREDIT_INFO_LINK" /></a></span>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-10">
                                            <h4 className="gray-text"><FormattedMessage id="SMS_CAMPAIGNS_TIME_FIELD_NAME" /></h4>
                                        </div>
                                        <Form className="col-lg-12 sms-campaigns-time-of-sending" onSubmit={e => { e.preventDefault(); }}>
                                            <Form.Row className="m-0">
                                                <Form.Group className="mb-3 time-switch">
                                                    <FormControlLabel
                                                        checked={switchA}
                                                        control={<Switch color="primary" />}
                                                        onChange={() => handleToggle(true, false)}
                                                        label={<FormattedMessage id="SMS_CAMPAIGNS_SEND_NOW" />}
                                                        labelPlacement="end"
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row className="m-0">
                                                <Form.Group className="mr-15 time-switch">
                                                    <FormControlLabel
                                                        checked={switchB}
                                                        control={<Switch color="primary" />}
                                                        onChange={() => handleToggle(false, true)}
                                                        label={<FormattedMessage id="SMS_CAMPAIGNS_PLAN_SENDOUT" />}
                                                        labelPlacement="end"
                                                    />
                                                    {/* <SingleDatePicker
                                                        date={singleDate} // momentPropTypes.momentObj or null
                                                        onDateChange={singleDate => setSingleDate(singleDate)} // PropTypes.func.isRequired
                                                        numberOfMonths={1}
                                                        focused={focused} // PropTypes.bool
                                                        onFocusChange={focused => setFocused(focused)} // PropTypes.func.isRequired 
                                                        id="your_unique_id" // PropTypes.string.isRequired,
                                                    /> */}
                                                    <div className="date-time-picker">
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DatePicker
                                                                autoOk
                                                                variant="inline"
                                                                value={selectedDate}
                                                                onChange={handleDateChange}
                                                                disabled={disabledPickers}
                                                                disableToolbar
                                                                minDate={new Date()}
                                                                placeholder={formatMessage({ id: 'SMS_CAMPAIGNS_DATEPICKER_DATE' })}
                                                                className="mr-10 datepicker"
                                                            />
                                                            <TimePicker
                                                                // clearable
                                                                autoOk
                                                                variant="inline"
                                                                ampm={false}
                                                                value={selectedTime}
                                                                onChange={handleTimeChange}
                                                                disabled={disabledPickers}
                                                                placeholder={formatMessage({ id: 'SMS_CAMPAIGNS_DATEPICKER_TIME' })}
                                                                className="timepicker"
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </Form.Group>
                                            </Form.Row>
                                            {
                                                (user.billing.country.countryCode === "HU") ? null :
                                                    <Form.Row className="m-0 mt-5">
                                                        <Form.Group className="align-items-center">
                                                            <Form.Label className="mr-5"><FormattedMessage id="SIMPLESMS_CHOOSING_ID" /></Form.Label>
                                                            <Dropdown className="" value={selectedValue} onSelect={(e) => handleChange(e)}>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {selectedValue}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {
                                                                        (senderData
                                                                        ).map((row, index) => (
                                                                            <Dropdown.Item key={index} eventKey={row}>{row}</Dropdown.Item>
                                                                        ))
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Form.Group>
                                                    </Form.Row>
                                            }
                                            <Form.Row className="m-0">
                                                <Form.Group className="button-form-group mt-5">
                                                    <Button onClick={handleClick} variant="contained" color="primary" disabled={buttonDisabled}>
                                                        <FormattedMessage id="SMS_CAMPAIGNS_SEND_BUTTON" />
                                                    </Button>
                                                </Form.Group>
                                                {
                                                    (isErred || isSaved || isSendSaved) && <div className="d-flex justify-content-end w-100">
                                                        <div className="snackbar my-5">
                                                            <div className={`snackbar-content ${isSaved || isSendSaved ? "success" : null}`}>
                                                                {/* <ErrorIcon className="icon" /> */}
                                                                <span>{responseMessage}</span>
                                                                <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                    <div className="col-lg-4 sms-campaing-message-preview-wrapper">
                        <div className="gray-text mb-5"><FormattedMessage id="SMS_CAMPAIGNS_PREVIEWMESSAGE" /></div>
                        <div className="message-preview with-send-test-button">{textValue}
                            <Button onClick={() => setShowModal(true)} variant="contained" color="primary" disabled={testSmsButtonDisabled}>
                                <FormattedMessage id="TEST_SMS_POPUP_OK_BUTTON" />
                            </Button>
                        </div>
                        {
                            (modalIsErred || modalIsSaved) && <div className="d-flex justify-content-end w-100">
                                <div className="snackbar my-5">
                                    <div className={`snackbar-content ${modalIsSaved ? "success" : null}`}>
                                        {/* <ErrorIcon className="icon" /> */}
                                        <span>{modalResponseMessage}</span>
                                        <a className="snackbar-close" onClick={handleSnackbarCloseModal}></a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="md"
                centered
                className="form-modal">
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <FormattedMessage id="TEST_SMS_POPUP_SENTENCE" />
                        <a href="#!" onClick={() => setShowModal(false)} className="close-button"><CloseIcon className="icon text-danger" style={{ fontSize: 40 }} /></a>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControlLabel className="m-0 w-100 align-items-start"
                        control={
                            <PhoneInput
                                ref={phoneNumberRef}
                                // defaultCountry="CZ"
                                // country="CZ"
                                defaultCountry={user.billing.country.countryCode}
                                country={user.billing.country.countryCode}
                                international
                                value={phoneNumber}
                                onChange={handlePhone}
                                className={isPhoneNumberError ? 'error w-100' : 'w-100'}
                                limitMaxLength={true} />}
                        label={<FormattedMessage id="MOBILE_NUMBER" />}
                        labelPlacement="top"
                    />
                    {
                        isPhoneNumberError && <div className="d-flex justify-content-end w-100">
                            <div className="snackbar my-5">
                                <div className="snackbar-content">
                                    {/* <ErrorIcon className="icon" /> */}
                                    <span>{phoneNumberMessage}</span>
                                    <a className="snackbar-close" onClick={handleSnackbarCloseModal}></a>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)} variant="contained" color="primary" className="modal-cancel-button mr-5"><FormattedMessage id="OPTOUT_POPUP_REMOVING_NUMBER_BTN_BACK" /></Button>
                    <Button onClick={handleSendTestSms} variant="contained" color="primary" className="modal-confirm-button"><FormattedMessage id="TEST_SMS_POPUP_OK_BUTTON" /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default injectIntl(SendSmsCampaignsSendingSms);