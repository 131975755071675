import { combineReducers } from "redux"
import userListReducer from "./userListReducer"

// butun reducer lari bir araya getiriyoruz

const rootReducer = combineReducers({
    user: userListReducer
})

export default rootReducer;

// alt yapi hazirken yoksa action type yaziyoruz onun aksiyonunu yaziyoruz reducer ini yaziyoruz ve onu buradaki combinereducer a ekliyoruz