import React, { useEffect, useState, createRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { injectIntl } from 'react-intl';
import { getData, useIsMountedRef } from '../../utils/helpers';
import Form from 'react-bootstrap/Form';
import FormLabel from '@material-ui/core/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import ErrorIcon from '@material-ui/icons/Error';
import PeopleIcon from '@material-ui/icons/People';
import { getFilteredPhoneNumber, getCampaignName, getFilteredObject } from '../../redux/actions/userActions';
import SendSmsCampaignsSendingSms from './SendSmsCampaignsSendingSms';
import { isEmpty } from 'lodash'

function SendSmsCampaignsFilter({ intl: { formatMessage } }) {

    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.user.currency);

    const campaignNameRef = createRef();

    const [data, setData] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [filteredObject, setFilteredObject] = useState({});
    const [orderStatusData, setOrderStatusData] = useState([]);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [filterSnackbar, setFilterSnackbar] = useState(false);
    const [filterSnackbarMessage, setFilterSnackbarMessage] = useState("");
    const [campaingName, setCampaignName] = React.useState("");
    const [sendingCampaignSms, setSendingCampaignSms] = React.useState(false);
    const [campaignNameError, setCampaignNameError] = useState(false);
    const [campaignNameErrorMessage, setCampaignNameErrorMessage] = useState('');

    const [isChecked, setIsChecked] = useState({});
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [focusedInput, setFocusedInput] = React.useState(null);

    const dispatch = useDispatch();

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        (async () => {
            const statusData = await getData('https://apishoptet.axima-sms.cz/order/status');

            // console.log("filter li status data", statusData.data.status.filter(d => d.name !== "Canceled"));
            console.log("filter li status data", statusData.data.status.filter(d => d.id !== -4));
            // const filteredStatusData = statusData.data.status.filter(d => d.name !== "Canceled");
            const filteredStatusData = statusData.data.status.filter(d => d.id !== -4);
            const filteredStatusDataID = filteredStatusData.map(d => d.id);
            const { startDateMoment, endDateMoment } = momentDates();

            let filteredStatusDataObj = {
                "from": startDateMoment,
                "to": endDateMoment,
                "status": filteredStatusDataID
            }
            // const resData = await getData('https://apishoptet.axima-sms.cz/eshop/phone/unique?filter=[]');
            const resData = await getData('https://apishoptet.axima-sms.cz/eshop/phone/unique?filter=' + encodeURIComponent(JSON.stringify(filteredStatusDataObj)));
            if (isMountedRef.current) {
                if (!isEmpty(statusData.data.status) && !isEmpty(resData.data)) {
                    // setData(resData.data.country);
                    setData(resData.data);
                    console.log("resData", resData.data);
                    // setOrderStatusData(statusData.data.status);
                    setOrderStatusData(filteredStatusData);
                    console.log({ filteredStatusData });
                    // initCheckedItems(statusData.data.status);
                    initCheckedItems(filteredStatusData);
                } else {
                    console.error(resData.error.error);
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (filterSnackbar || campaignNameError) {
            let timeOut = setTimeout(() => {
                setFilterSnackbar(false);
                setCampaignNameError(false);
            }, 5000);
            return () => clearTimeout(timeOut);
        }
    }, [filterSnackbar, campaignNameError]);

    const initCheckedItems = (data) => {
        let obj = {}
        data.map((item) => (
            obj = { ...obj, [item.id]: true }
        ));
        setIsChecked(obj);
    };

    const handleCheck = (event) => {
        setIsChecked({ ...isChecked, [event.target.name]: event.target.checked });
    };

    const momentDates = () => {
        const format = "YYYY-MM-DD HH:mm:ss";
        let startDateMoment = "";
        let endDateMoment = "";
        if (startDate !== null) {
            startDateMoment = moment(startDate).startOf("day").format(format);
        }
        if (endDate !== null) {
            endDateMoment = moment(endDate).endOf("day").format(format);
        }
        return { startDateMoment, endDateMoment }
    }

    const handleFilter = async () => {

        const { startDateMoment, endDateMoment } = momentDates();
        const orderStatusArray = Object.keys(isChecked).filter(id => isChecked[id]);

        console.log({ orderStatusArray });

        let filtered = {
            "from": startDateMoment,
            "to": endDateMoment,
            "status": orderStatusArray
        }
        // console.log(Object.keys(isChecked).filter(id => isChecked[id]));
        const resData = await getData('https://apishoptet.axima-sms.cz/eshop/phone/unique?filter=' + encodeURIComponent(JSON.stringify(filtered)));
        if (!isEmpty(resData.data)) {
            setFilteredData(resData.data.count);
            setFilteredObject(filtered);
            dispatch(getFilteredPhoneNumber(resData.data.count));
            if ((startDateMoment === "") && (endDateMoment === "") && (orderStatusArray.length === 0)) {
                setShowFilteredData(false);
                setFilterSnackbar(true);
                setFilterSnackbarMessage(<FormattedMessage id="SNACKBAR_NO_FILTER_CRITERIA" />);
            } else {
                setShowFilteredData(true);
                setFilterSnackbar(false);
            }
        } else {
            console.error(resData.error.error);
        }
    }

    const handleClick = () => {
        if (campaignNameRef.current.value === '') {
            setCampaignNameError(true);
            setCampaignNameErrorMessage(<FormattedMessage id="SNACKBAR_NO_CAMPAIGN_NAME" />);
        } else {
            dispatch(getCampaignName(campaingName));
            dispatch(getFilteredObject(filteredObject));
            setSendingCampaignSms(true);
            // console.log({ campaingName });
            // console.log({filteredObject});
        }
    }

    const handleChange = (event) => {
        setCampaignName(event.target.value);
    };

    const handleSnackbarClose = () => {
        setFilterSnackbar(false);
    }

    const handleSnackbarCloseCampaignError = () => {
        setCampaignNameError(false);
    }

    const Template = () => {
        let template;
        if (sendingCampaignSms) {
            template = <SendSmsCampaignsSendingSms />
        } else {
            template = <>
                <div className="col-lg-12">
                    <div className="row">
                        {/* <div className="col-lg-4 col-md-6 col-sm-6"></div>
                        <div className="col-lg-8 col-md-6 col-sm-6"> */}
                        <div className="col-lg-12 px-0">
                            <div className="registrate-breadcrumb">
                                <div className="registrate active"><FormattedMessage id="CHOOSE_DATABASE" /></div>
                                <div className="sms-verification"><FormattedMessage id="SENDING_AN_SMS" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-10 mb-40 send-sms-campaigns">
                        <div className="col-lg-12 px-0">
                            {(currency === "CZK") &&
                                <p>
                                    <FormattedMessage id="SMS_CAMPAIGNS_INFO_FIRST" />
                                    <a href="https://www.uoou.cz/assets/File.ashx?id_org=200144&id_dokumenty=32710" target="_blank"> <FormattedMessage id="SMS_CAMPAIGNS_INFO_FIRST_LINK" /></a>
                                </p>
                            }
                            <p>
                                <FormattedMessage id="SMS_CAMPAIGNS_INFO" />
                            </p>
                            <p className="mt-5 mb-0 amount-label d-flex align-items-center unique-numbers">
                                <FormattedMessage id="SMS_CAMPAIGNS_DATABASE_INFO" /> <span className="amount text-error font-weight-bold ml-2">{data.count}</span>
                            </p>
                            <p className="amount-label">
                                <FormattedMessage id="SMS_CAMPAIGNS_INSTALATION_INFO" /> {user.installed}
                            </p>
                        </div>
                        <div className="col-lg-12 mt-5 px-0">
                            <h4 className="gray-text"><FormattedMessage id="CHOOSE_DATABASE" /></h4>
                        </div>
                        <div className="col-lg-12 px-0">
                            <Form className="col-lg-12 px-0" onSubmit={e => { e.preventDefault(); }}>
                                <Form.Row className="m-0 mt-5">
                                    <Form.Group className="mr-5 align-items-center">
                                        <FormLabel className="font-weight-bold mb-0"><FormattedMessage id="SMS_CAMPAIGNS_FILTER_CRITERIA" /></FormLabel>
                                    </Form.Group>
                                    {

                                        orderStatusData.map((item, index) => (
                                            < Form.Group key={index} controlId={index} style={{ background: item.backgroundColor }} className="mr-5 order-status">
                                                <FormCheck>
                                                    <FormCheck.Input type="checkbox" checked={isChecked[item.id] === undefined ? true : isChecked[item.id]} onChange={handleCheck} name={item.id} />
                                                    <FormCheck.Label className="ml-2"><span style={{ color: item.color }}>{item.name}</span></FormCheck.Label>
                                                </FormCheck>
                                            </Form.Group>
                                        ))}
                                </Form.Row>
                                <Form.Row className="m-0 mt-5">
                                    <Form.Group className="mr-5 d-flex align-items-center">
                                        <FormLabel className="font-weight-bold mr-10 mb-0"><FormattedMessage id="SMS_CAMPAIGNS_DATE_SELECTION" /></FormLabel>
                                    </Form.Group>
                                    <Form.Group className="button-form-group">
                                        <DateRangePicker
                                            startDate={startDate} // momentPropTypes.momentObj or null,
                                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                            endDate={endDate} // momentPropTypes.momentObj or null,
                                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                            onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate) }} // PropTypes.func.isRequired,
                                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                            onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                                            isOutsideRange={() => false}
                                            showClearDates={true}
                                            reopenPickerOnClearDates={true}
                                            startDatePlaceholderText={formatMessage({ id: 'DATEPICKER_START_DATE' })}
                                            endDatePlaceholderText={formatMessage({ id: 'DATEPICKER_END_DATE' })}

                                        />
                                        <Button onClick={handleFilter} variant="contained" color="primary" className="ml-10">
                                            <FormattedMessage id="SMS_CAMPAIGNS_BUTTON" />
                                        </Button>
                                    </Form.Group>
                                    {
                                        filterSnackbar && <div className="d-flex justify-content-end w-100">
                                            <div className="snackbar my-5">
                                                <div className="snackbar-content">
                                                    {/* <ErrorIcon className="icon" /> */}
                                                    <span>{filterSnackbarMessage}</span>
                                                    <a className="snackbar-close" onClick={handleSnackbarClose}></a>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Form.Row>
                                {
                                    showFilteredData &&
                                    <div className="mt-5">
                                        {filteredData > 0 ?
                                            <>
                                                <div className="d-flex"><PeopleIcon fontSize="large" className="mr-3 gray-text" />
                                                    <span className="d-flex align-items-center font-weight-bold sms-campaign-result">
                                                        <FormattedMessage id="SMS_CAMPAIGNS_RESULT" /> <span className="amount-label"><span className="amount mx-2">{filteredData}</span></span>
                                                    </span>
                                                </div>
                                                <Form.Row className="m-0 mt-5">
                                                    <Form.Group>
                                                        <FormControlLabel className="m-0"
                                                            control={
                                                                <Form.Control ref={campaignNameRef} onChange={handleChange} className="campaign-name-input" />}
                                                            label={<span className="font-weight-bold mr-5"><FormattedMessage id="SMS_CAMPAIGNS_CAMPAIGN_NAME" /></span>}
                                                            labelPlacement="start"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Button onClick={handleClick} variant="contained" color="primary" className="ml-10">
                                                            <FormattedMessage id="SMS_CAMPAIGNS_WRITESMS" />
                                                        </Button>
                                                    </Form.Group>
                                                    {
                                                        campaignNameError && <div className="d-flex justify-content-end w-100">
                                                            <div className="snackbar my-5">
                                                                <div className="snackbar-content">
                                                                    {/* <ErrorIcon className="icon" /> */}
                                                                    <span>{campaignNameErrorMessage}</span>
                                                                    <a className="snackbar-close" onClick={handleSnackbarCloseCampaignError}></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </Form.Row>
                                            </>
                                            :
                                            <div className="text-error d-flex"><PeopleIcon fontSize="large" className="mr-3" /> <span className="d-flex align-items-end font-weight-bold"><FormattedMessage id="SMS_CAMPAIGNS_NO_ORDERS" /></span></div>}
                                    </div>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        }
        return template;
    }

    return (
        <div className="col-lg-12 px-0">{Template()}</div>
    );
}
export default injectIntl(SendSmsCampaignsFilter);