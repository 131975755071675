import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isEmpty } from 'lodash'
import { getData, useIsMountedRef } from '../../../utils/helpers';
import { updateUnseenPayment, clearUnseenPayment, setInvoicesLink, setNotificationsLink } from "../../../redux/actions/userActions";

function ResultCanceled() {

    const user = useSelector(state => state.user.user);
    const credit = useSelector(state => state.user.credit);
    const currency = useSelector(state => state.user.currency);
    const [unseenPaymentLast, setUnseenPaymentLast] = useState({});
    const seenPayment = useSelector(state => state.user.seenPayment);
    const [paymentSeenPosted, setPaymentSeenPosted] = useState(false);

    const isMountedRef = useIsMountedRef();

    const dispatch = useDispatch();


    useEffect(() => {
        if (isMountedRef.current) {
            if (!isEmpty(user)) {
                setUnseenPaymentLast(user.unseen_payments[user.unseen_payments.length - 1]);
            }
        }
    }, [user]);

    useEffect(() => {
        (async () => {
            if (!isEmpty(user) && paymentSeenPosted) {
                const resData = await getData('https://apishoptet.axima-sms.cz/eshop');
                if (!isEmpty(resData.data.eshop)) {
                    dispatch(updateUnseenPayment(resData.data.eshop.unseen_payments));
                }
            }
        })();
    }, [paymentSeenPosted]);

    return (
        <div className="row result-page">
            {console.log("result canceled")}
            <div className="col-lg-4">
                <div className="result-info mb-8 d-flex align-items-center">
                    <h4 className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_CLAIM" /></h4>
                </div>
                <p className="mt-5 text-danger"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_LEFT_TEXT1" /></p>
                <p className="mt-5"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_LEFT_TEXT2" /> <a className="blue-link" onClick={() => dispatch(setNotificationsLink(true))}><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_LEFT_TEXT2_LINK" /></a></p>
                <div className="amount-label d-flex align-items-center mt-10"><FormattedMessage id="REMAINING_CREDIT" /> <span className="amount ml-2">{credit} {currency}</span></div>
            </div>
            <div className="col-lg-8">
                <div className="payment-details pt-5 pb-10 px-10">
                    <div className="d-flex justify-content-between">
                        <h6 className="font-weight-bold"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_NAME" /></h6>
                        <a className="blue-link" onClick={() => dispatch(setInvoicesLink(true))}><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_LINK" /></a>
                    </div>
                    <div className="mt-5">
                        <p className="mb-0"><strong><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_RESULT" /> <span className="text-danger"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_RESULT2" /></span></strong></p>
                        <div className="row mt-10">
                            <div className="col-lg-8">
                                <div>
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_TEXT1" /></p>
                                    <p className="mb-0"><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_TEXT2" /> <a className="blue-link" onClick={() => dispatch(clearUnseenPayment())}><FormattedMessage id="PAYMENT_CARD_RESULT_CANCELED_RIGHT_LINK2" /></a></p>
                                </div>
                            </div>
                            <div className="col-lg-4 axima-logo-wrapper">
                                <div className="axima-logo red"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResultCanceled;